import React from 'react';
import { useSelector } from 'react-redux';

import { getLoginData } from 'store/ducks';

import {
  Page, Container, PageHeader, Title,
} from 'ui/components/Page';
import ProfileMenu from 'ui/components/ProfileMenu';

export default function Profile(): React.ReactElement {
  const profile = useSelector(getLoginData);

  return (
    <Page>
      <Container modifiers="Small">
        <PageHeader modifiers="Center">
          <Title>
            Olá,
            {' '}
            {profile?.profile.first_name}
            !
          </Title>
        </PageHeader>
        <ProfileMenu />
      </Container>
    </Page>
  );
}
