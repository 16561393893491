import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

const ModalModifiers = {
  Active: () => `
    color: #06C167 !important;
    font-weight: bold;
  `,
};

export const PaymentContainer = styled.div`
`;

export const ListTags = styled.div`
  margin-bottom: 1rem;
`;

export const Tag = styled.a.attrs({
})<utils.WithModifiers>`
  background-color: white;
  cursor: pointer;
  ${applyStyleModifiers(ModalModifiers)};
`;

export const Input = styled.input.attrs({
  className: 'form-control',
})`
`;
