import { Home, ShoppingBag, User } from '@styled-icons/feather';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';

import SettingsContext, { REGULAR } from 'context/SettingsContext';

import { getCart, getCartSubTotal } from 'store/ducks';
import { getPendingOrdersQty } from 'store/ducks/orders';

import { numberToMoney } from 'utils/monetary';

import { hasCartNotifications } from '../../../store/ducks/cart';
import {
  AppNavTabsContainer,
  NavInner,
  Container,
  NavBar,
  NavList,
  Item,
  ItemLink,
  ButtonCartContainer,
  ButtonCart,
  ButtonCartCount,
  ButtonLabel,
  ButtonAmount,
  CartCount,
} from './AppNavTabs.styled';

export default function AppNavTabs(): React.ReactElement {
  const { navType } = useContext(SettingsContext);

  const match = useRouteMatch('/');
  const { pathname } = useLocation();

  const ordersQty = useSelector(getPendingOrdersQty);

  const tabList = useMemo(() => ([
    {
      to: '/',
      label: 'Home',
      icon: (<Home />),
      active: !!match?.isExact || pathname.startsWith('/store'),
    },
    {
      to: '/orders',
      label: 'Pedidos',
      icon: (<ShoppingBag />),
      active: pathname.startsWith('/orders'),
      inProgress: (ordersQty > 0 && <CartCount>{ordersQty}</CartCount>),
    },
    {
      to: '/profile',
      label: 'Perfil',
      icon: (<User />),
      active: pathname.startsWith('/profile'),
    },
  ]), [pathname, match]);

  const { openModal } = useContext(SettingsContext);

  const cart = useSelector(getCart);
  const alert = useSelector(hasCartNotifications);
  const subTotal = useSelector(getCartSubTotal);

  return (
    <AppNavTabsContainer>
      <NavInner>
        <Container>
          <NavBar>
            <NavList>
              {
                tabList.map(({
                  to, label, icon, active, inProgress,
                }) => (
                  <Item key={to} modifiers={active ? ['Active'] : []}>
                    <ItemLink to={to}>
                      {inProgress}
                      {icon}
                      {label}
                    </ItemLink>
                  </Item>
                ))
              }
            </NavList>
          </NavBar>
        </Container>
        {
          !!cart?.items.length && navType === REGULAR && (
            <ButtonCartContainer modifiers="Show">
              <ButtonCart onClick={(): void => openModal && openModal('CART')}>
                <ButtonCartCount modifiers={alert ? ['Alert'] : []}>{cart.items.length}</ButtonCartCount>
                <ButtonLabel>Minha sacola</ButtonLabel>
                <ButtonAmount>
                  {numberToMoney(subTotal)}
                </ButtonAmount>
              </ButtonCart>
            </ButtonCartContainer>
          )
        }
      </NavInner>
    </AppNavTabsContainer>
  );
}
