import { all } from 'redux-saga/effects';

import watchBaseActions from './base';
import watchCartActions from './cart';
import watchCategoriesActions from './categories';
import watchFeaturedCategoriesActions from './featured_categories';
import watchFiltersProductActions from './filters_product';
import watchFrontViewCategoriesActions from './front_view_categories';
import watchOrdersActions from './orders';
import watchProductsActions from './products';
import watchSideMenuCategoriesActions from './side_menu_categories';
import watchStoresActions from './stores';
import watchAuthActions from './user';

export default function* root(): Generator {
  yield all([
    watchAuthActions(),
    watchStoresActions(),
    watchProductsActions(),
    watchCartActions(),
    watchCategoriesActions(),
    watchFeaturedCategoriesActions(),
    watchSideMenuCategoriesActions(),
    watchFrontViewCategoriesActions(),
    watchOrdersActions(),
    watchBaseActions(),
    watchFiltersProductActions(),
  ]);
}
