import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, ActionFunctionAny } from 'redux-actions';

import { hasError, isLoading, isSuccess } from 'store/ducks';

const useCheckFetch = (fetchName: symbol, fetchCleaner?: ActionFunctionAny<Action<unknown>>):
  [boolean, boolean, boolean] => {
  const loading = useSelector(isLoading(fetchName));
  const error = useSelector(hasError(fetchName));
  const success = useSelector(isSuccess(fetchName));

  const dispatch = useDispatch();

  useEffect(() => (): void => {
    if (fetchCleaner) {
      dispatch(fetchCleaner());
    }
  }, [fetchCleaner, dispatch]);

  return [!!loading, !!success, !!error];
};

export default useCheckFetch;
