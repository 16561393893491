import produce from 'immer';
import { createActions, handleActions } from 'redux-actions';

import { Category } from 'typing/models';
import {
  AbstractPayload, BaseState, CategoriesState,
} from 'typing/store';

import { LIST_FEATURED_CATEGORIES, LIST_SIDE_MENU_CATEGORIES } from 'utils/fetchs';

import { fetchStateGenerator } from './checkFetchReducerCreator';

export const {
  listSideMenuCategoriesRequest,
  listSideMenuCategoriesSuccess,
  listSideMenuCategoriesFailure,
  listSideMenuCategoriesClean,
} = createActions(
  {},
  ...fetchStateGenerator(LIST_SIDE_MENU_CATEGORIES),
  { prefix: 'SIDE_MENU_CATEGORIES' },
);

const INITIAL_STATE = {
  list: {
    data: [],
  },
  detail: null,
};

export const getSideMenuCategories = (storeSlug?: string) => (state: BaseState):
  Category[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return state.side_menu_categories.list.data;
  }

  return [];
};

export default handleActions<CategoriesState, AbstractPayload>({
  [listSideMenuCategoriesSuccess.toString()]: produce(
    (draft: CategoriesState, { payload }) => {
      draft.list.data = payload;
      draft.list.filter = undefined;
    },
  ),
}, INITIAL_STATE);
