import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/css/bootstrap.min.css';
import 'assets/css/style.css';
import 'assets/css/responsive.css';
import 'assets/css/style-custom.css';
import 'moment/min/locales';

import { reducers } from 'store/ducks';

import Marketplace from 'ui/components/Marketplace';

import * as serviceWorker from './serviceWorker';
import { sagas } from './store';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
);

moment.locale('pt-BR');

const store = createStore(reducers, enhancer);
sagaMiddleware.run(sagas);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Marketplace />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register({ scope: 'firebase-cloud-messaging-push-scope' });
