import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const SidebarCategoriesContainer = styled.section.attrs({
  className: 'widget widget-categories',
})`
  display: none;
  @media ${media.tabletMax} {
    background-color: #f7f7f7;
    border-radius: 16px;
    display: block;
  }
`;

export const SidebarCategoriesInner = styled.div`
`;

export const Heading = styled.div.attrs({
  className: 'heading_s2',
})`
  margin-top: 0;
  margin-bottom: 2rem;
  text-align: center;
  @media ${media.tabletMax} {
    display: none;
  }
`;

export const Title = styled.h2`
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  .nav-link {
    position: relative;
    text-transform: uppercase;
    &:hover {
      color: ${(props) => props.theme.primary.main};
    }
  }

  .dropdown {
    .nav_item {
      position: relative;
      &:after {
        @media ${media.tabletMax} {
          content: '>';
          font-weight: normal;
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
        }
      }
    }
  }

`;

export const DropdownMenu = styled.div`
  padding: 0 0 1rem 1rem;
  @media ${media.tabletMax} {
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    border-radius: 8px;
    display: none;
    min-width: ${rem('575px')};
    padding: 1rem 2rem;
    position: absolute;
    top: 0;
    left: ${rem('210px')};
    z-index: 10;
  }
  @media ${media.desktopMin} {
    left: ${rem('255px')};
  }
  ${List} {
    column-count: 2;
    display: block !important;
  }
`;

export const ItemList = styled.li`
  background-color: #f7f7f7;
  border-radius: 16px;
  margin-bottom: 1rem;
  @media ${media.tabletMax} {
    margin-bottom: 0;
  }
  > a {
    color: black;
    font-size: ${rem('16px')};
    font-weight: bold;
    padding: .75rem 1rem;
    @media ${media.tabletMax} {
      font-weight: normal;
    }
    > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 1rem);
      white-space: nowrap;
    }
  }
  &:hover {
    @media ${media.tabletMax} {
      ${DropdownMenu} {
        display: block;
      }
    }
  }

  &.dropdown-relative {
    @media ${media.tabletMax} {
      .nav-link {
        &:after {
          display: none;
        }
      }
      ${DropdownMenu} {
        background: none;
        box-shadow: none;
        display: block;
        left: 0;
        margin-top: -${rem('10px')};
        padding: 0 0 0 1.5rem;
        position: relative;
        min-width: 0;
        ul {
          column-count: 1;
        }
      }
    }
  }

`;

export const ItemListChild = styled.li`
  a {
    display: block;
    padding: 5px 0;
    @media ${media.tabletMax} {
      font-size: ${rem('14px')};
    }
    &:before {
      content: ">";
      margin-right: 0.25rem;
    }
  }
`;
