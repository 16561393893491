import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import { clearCart } from 'store/ducks';

import { ModalActions } from './InvalidStore.styled';

export default function InvalidStore(): React.ReactElement {
  const { warning: { inactiveWarning } } = useContext(SettingsContext);

  const dispatch = useDispatch();

  const startNewCart = (): void => {
    dispatch(clearCart());
    inactiveWarning && inactiveWarning();
  };

  return (
    <div>
      <div className="heading_s1">
        <h2>Você possui uma compra em andamento em outra loja</h2>
      </div>
      <p>No momento, você só pode realizar uma compra por vez. Escolha entre voltar para a loja anterior ou iniciar uma nova compra.</p>
      <ModalActions>
        <a onClick={inactiveWarning} className="btn btn-fill-out">Voltar</a>
        <a onClick={startNewCart} className="btn btn-fill-out">Iniciar nova compra</a>
      </ModalActions>
    </div>
  );
}
