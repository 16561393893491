import React, { createContext, useState } from 'react';

import {
  NavType, Props, SettingsValues, WarnType,
} from 'typing/context';

export const REGULAR: NavType = 'REGULAR';
export const AUTH: NavType = 'AUTH';
export const REGISTER: NavType = 'REGISTER';
export const CHECKOUT: NavType = 'CHECKOUT';

const defaultSettings = {
  activeModal: null,
  navType: REGULAR,
  warning: {
    type: null,
    active: false,
    args: null,
  },
};

const SettingsContext = createContext<SettingsValues>(defaultSettings);

export function SettingsProvider({ children }: Props): React.ReactElement {
  const [settingsValue, setSettingsValues] = useState<SettingsValues>(defaultSettings);

  const closeModal = (): void => setSettingsValues({ ...settingsValue, activeModal: null });
  const openModal = (modalName: string):
    void => setSettingsValues({ ...settingsValue, activeModal: modalName });
  const isVisibleModal = (modalName: string): boolean => settingsValue.activeModal === modalName;

  settingsValue.warning.activeWarning = (type: WarnType, args: any): void => setSettingsValues({
    ...settingsValue,
    warning: {
      type,
      args,
      active: true,
    },
  });

  settingsValue.warning.inactiveWarning = (): void => setSettingsValues({
    ...settingsValue,
    warning: defaultSettings.warning,
  });

  const setNavType = (type: NavType): void => {
    setSettingsValues({ ...settingsValue, navType: type, activeModal: null });
  };

  return (
    <SettingsContext.Provider value={{
      ...settingsValue, openModal, closeModal, isVisibleModal, setNavType,
    }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
