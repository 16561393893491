import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import {
  takeEvery, put, call, take, select,
} from 'redux-saga/effects';

import { getConfig, getRemoteData } from 'services/api/base';
import { getSavedCart } from 'services/api/cart';
import { addMarketplaceCodeConfig } from 'services/api/http';

import {
  startAppRequest, startAppFailure, startAppSuccess, loadRemoteData,
} from 'store/ducks/base';

import { Configuration } from 'typing/models';

import {
  getStoreBySlug,
  getStores, isLogged,
  listOrdersRequest,
  listStoresRequest,
  listStoresSuccess,
  loadAuthDataRequest,
  loadAuthDataSuccess, loadCartRequest,
  loadCartSuccess,
} from '../ducks';

export function* loadRemoteDataAsync({ payload }: Action<{ store: string, cartReferCode: string }>):
  SagaIterator {
  const { cartReferCode, store } = payload;

  const { cart_id: cartId, access, refresh } = yield call(getRemoteData, store, cartReferCode);

  localStorage.removeItem('@bodega-mix/cart');

  if (access && refresh) {
    localStorage.setItem('@bodega-mix/authData', JSON.stringify({ access, refresh }));
    yield put(loadAuthDataRequest());
    yield take(loadAuthDataSuccess.toString());
  } else {
    yield put(loadAuthDataRequest());
  }

  const stores = yield select(getStores);

  if (!stores.length) {
    yield take(listStoresSuccess.toString());
  }
  const cart = yield call(getSavedCart, store, cartId);

  const cartStore = yield select(getStoreBySlug(cart.store));

  const { items, ...cartInfo } = cart;
  delete cartInfo.response_credit_cards;

  const cardData = {
    data: {
      ...cartInfo,
      store: cartStore,
      items: items.map(
        (item: any):
        any => ({
          product: item.product,
          quantity: parseFloat(item.quantity),
          location_code: item.location_code,
        }),
      ),
    },
  };

  localStorage.setItem('@bodega-mix/cart', JSON.stringify(cardData));
  yield put(loadCartSuccess(cardData));
}

export function* startApp(): SagaIterator {
  try {
    const data: Configuration = yield call(getConfig);

    if (data.default_code) {
      addMarketplaceCodeConfig(data.default_code);
    }

    const params = (new URL(window.location.href)).searchParams;
    const cartReferCode = params.get('refer_code');
    const store = params.get('store');

    if (cartReferCode && store) {
      yield put(loadRemoteData({ cartReferCode, store }));
    } else {
      yield put(loadAuthDataRequest());
    }

    yield take(loadAuthDataSuccess.toString());

    const authenticated = yield select(isLogged);

    if (authenticated) {
      yield put(listOrdersRequest());
      yield put(loadCartRequest());
    }

    yield put(listStoresRequest());
    yield put(startAppSuccess(data));
  } catch (e) {
    yield put(startAppFailure(e));
  }
}

export default function* watchBaseActions(): SagaIterator {
  yield takeEvery(startAppRequest.toString(), startApp);
  yield takeEvery(loadRemoteData.toString(), loadRemoteDataAsync);
}
