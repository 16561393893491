import { Check, MoreVertical } from '@styled-icons/feather';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import { deleteAddressRequest } from 'store/ducks/user';

import { Address } from 'typing/models';

import {
  CardAddressContainer,
  CardSelect,
  DropdownAction,
  DropdownButton,
  DropdownContent,
} from './CardAddress.styled';

interface CardAddressProps {
  modifiers: string;
  unique?: boolean;
  address?: Address;
  selectAddress?: () => void;
}

export default function CardAddress(
  {
    unique, modifiers, address, selectAddress,
  }: CardAddressProps,
): React.ReactElement {
  const { openModal } = useContext(SettingsContext);
  const [isRemoving, setIsRemoving] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      {
        address && (
          <CardAddressContainer>
            <CardSelect
              onClick={(): void => selectAddress && selectAddress()}
              modifiers={[modifiers, isRemoving ? 'Remove' : '']}
            >
              <Check />
              <span>{`${address?.street}, ${address?.street_number}; ${address?.district}, ${address?.postal_code}`}</span>
            </CardSelect>
            <DropdownAction>
              <DropdownButton>
                <MoreVertical />
              </DropdownButton>
              <DropdownContent>
                {/* <li> */}
                {/*  <a href="#">Editar</a> */}
                {/* </li> */}
                <li>
                  <a onClick={(): void => {
                    setIsRemoving(true); dispatch(deleteAddressRequest(address));
                  }}
                  >
                    Remover
                  </a>
                </li>
              </DropdownContent>
            </DropdownAction>
          </CardAddressContainer>
        )
      }

      {
        unique && (
        <a
          style={{
            textDecoration: 'underline',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={(): void => { openModal && openModal('ADDRESS'); }}
        >
          Adicionar Endereço
        </a>
        )
      }
    </>
  );
}
