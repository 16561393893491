import styled from 'styled-components';

export const SpinnerLoadingContainer = styled.div.attrs({
  className: 'spinner-loading',
})`
border: 4px solid #f3f3f3;
border-radius: 50%;
border-top: 4px solid ${(props) => props.theme.primary.main};
width: 40px;
height: 40px;
animation: spin 2s linear infinite;
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;
