import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import Form from 'react-formal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { AUTH } from 'context/SettingsContext';

import { useCheckFetch } from 'hooks';
import useNavControl from 'hooks/useNavControl';
import useSearchParams from 'hooks/useSearchParams';

import { getConfig } from 'store/ducks/base';
import { getGenericError, loginClean, loginRequest } from 'store/ducks/user';

import FeedbackMessage from 'ui/components/FeedbackMessage';
import { Page, Container } from 'ui/components/Page';

import { LOGIN_FORM_LABEL } from 'utils/contants';
import { LOGIN } from 'utils/fetchs';
import { cpfMask, cnpjMask, dynamicUsernameMask } from 'utils/masks';

import {
  LoginWrapper,
  LoginInner,
  Heading,
  FormGroup,
  FormNote,
  LoginFooter,
  CheckForm,
  Checkbox,
  ForgotPassword,
} from './Login.styled';

export default function Login(): React.ReactElement {
  useNavControl(AUTH);
  const dispatch = useDispatch();
  const [next, auth] = useSearchParams(['next', 'auth']);

  const genericError = useSelector(getGenericError);
  const directLogin = auth && JSON.parse(atob(auth));
  let { username = '', password = '' } = directLogin || {};
  let rememberLogin = false;

  if (!username) {
    const rememberData = JSON.parse(localStorage.getItem('@bodega-mix/rememberLogin') || 'null');
    if (rememberData) {
      if (rememberData.username) {
        username = dynamicUsernameMask(rememberData.username);
        rememberLogin = true;
      }

      if (rememberData.password) {
        password = rememberData.password;
      }
    }
  }

  const authSchema = yup.object({
    username: yup.string()
      .min(14, 'O campo de CPF/CNPJ deve ter 14 ou 18 caracteres').max(18, 'O campo de CPF/CNPJ deve ter 14 ou 18 caracteres')
      .required('O campo de identificação é obrigatório e deve ser um CPF ou CNPJ'),
    password: yup.string().required('Campo de senha é obrigatório'),
    rememberLogin: yup.bool(),
  });

  const [loading, success, error] = useCheckFetch(LOGIN);

  type AuthSchemaType = yup.InferType<typeof authSchema>;

  const initialState = { username, password, rememberLogin };

  const [authData, setAuthData] = useState<AuthSchemaType | undefined>(initialState);

  const [rememberPassword, setRememberPassword] = useState<boolean>(rememberLogin);

  const { login_form_label = LOGIN_FORM_LABEL } = get(useSelector(getConfig), 'config.labels');

  const login = (formValue: AuthSchemaType): void => {
    dispatch(loginRequest({ ...formValue, rememberPassword }));
  };

  useEffect(() => {
    if (directLogin) {
      setRememberPassword(true);
      login(directLogin);
    }
  }, []);

  const getGenericErrorMessage = (): string => {
    switch (genericError) {
      case 'unapproved_client':
        return 'Ferramenta de uso exclusivo para revendedores Colmeia. Entre em contato conosco.';
      default:
        return 'Algo deu errado, tente novamente!';
    }
  };

  return (

    <Page>
      <Container modifiers="XSmall">
        <LoginWrapper>
          <LoginInner>
            <Heading>
              <h2>{login_form_label}</h2>
            </Heading>
            <Form
              autoComplete="off"
              schema={authSchema}
              value={authData}
              defaultValue={authSchema.default()}
              onChange={(model: any): void => {
                if (model?.username) {
                  setAuthData({
                    ...model,
                    username: dynamicUsernameMask(model.username),
                  });
                  dispatch(loginClean());
                } else {
                  setAuthData(model);
                }
              }}
              onSubmit={login}
            >
              <fieldset>
                <FeedbackMessage modifiers={error ? ['Active', 'Danger'] : []}>
                  Usuário ou senha Incorretos. Verifique-as e tente novamente.
                </FeedbackMessage>
                {genericError
                && (
                <FeedbackMessage modifiers={['Active', 'Danger']}>
                  {getGenericErrorMessage()}
                </FeedbackMessage>
                )}

                <FormGroup>
                  <Form.Field name="username" placeholder="CPF ou CNPJ" className="form-control" />
                  <Form.Message for="username" />
                </FormGroup>
                <FormGroup>
                  <Form.Field name="password" placeholder="Senha" type="password" className="form-control" />
                  <Form.Message for="password" />
                </FormGroup>
              </fieldset>
              <LoginFooter>
                <CheckForm>
                  <Checkbox>
                    <input
                      id="rememberLogin"
                      checked={rememberPassword}
                      onChange={({ target }):
                        void => { setRememberPassword(target.checked); }}
                      className="form-check-input"
                      type="checkbox"
                      name="rememberLogin"
                    />
                    <label className="form-check-label" htmlFor="rememberLogin"><span>Lembrar senha</span></label>
                  </Checkbox>
                </CheckForm>
              </LoginFooter>
              <FormGroup>
                <Form.Submit
                  type="submit"
                  className={`btn btn-fill-out btn-block mt-lg-4 ${loading ?? 'disabled'}`}
                >
                  {loading ? 'Entrando...' : 'Entrar'}
                </Form.Submit>
              </FormGroup>
              <ForgotPassword>
                <Link to="/profile/reset-password">Esqueceu a senha?</Link>
              </ForgotPassword>
            </Form>
          </LoginInner>
        </LoginWrapper>
        <FormNote>
          Não tem cadastro?
          {' '}
          <Link to={next ? `/profile/register/?next=${next}` : '/profile/register/'}>Crie sua conta!</Link>
        </FormNote>
      </Container>
    </Page>
  );
}
