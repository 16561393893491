import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useUrls from 'hooks/useUrls';

import {
  getProducts, getProductsFilters, isLoading, listProductsRequest,
} from 'store/ducks';
import { isSingleStore } from 'store/ducks/base';
import { selectProduct } from 'store/ducks/products';

import { Category, Product, Store } from 'typing/models';

import ProductCard from 'ui/components/ProductCard';
import SectionHeader from 'ui/components/SectionHeader';
import SliderContainer from 'ui/components/SliderContainer';

import { LIST_PRODUCTS } from 'utils/fetchs';

import ProductDetail from '../ProductDetail';
import { ProductSlider } from './ProductsCategorySlider.styled';

interface ProductsCategorySliderProps {
  store: Store;
  category: Category;
}

export default function ProductsCategorySlider({ store, category }: ProductsCategorySliderProps):
  React.ReactElement {
  const history = useHistory();
  const { getSearchUrl } = useUrls();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const singleStore = useSelector(isSingleStore);
  const products: Product[] = useSelector(getProducts(store?.slug, category.id, 20));
  const productsFilter = useSelector(getProductsFilters(category.id));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!products.length) {
      dispatch(listProductsRequest(category.id, category.id));
    }
  }, [productsFilter?.searchName]);

  const loading = useSelector(isLoading(LIST_PRODUCTS, category.id));

  const selectCurrentProduct = (product: Product) => {
    if (product.has_variants) {
      dispatch(selectProduct(product));
      if (singleStore) {
        history.push(`/product/${product.id}/`);
      } else {
        history.push(`/store/${store?.slug}/product/${product.id}/`);
      }
    } else {
      setSelectedProduct(product);
    }
  };

  return (
    <ProductSlider key={category.id}>
      <SectionHeader
        title={`${category.name}`}
        link={getSearchUrl(category)}
        modifiers="SliderProducts"
      />
      <SliderContainer>
        <div style={{ display: 'flex', overflow: 'auto' }}>
          {
            loading ? (
              <p>Carregando...</p>
            ) : (
              (products || []).map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  selectProduct={(): void => selectCurrentProduct(product)}
                />
              ))
            )
          }
        </div>
      </SliderContainer>

      <ProductDetail
        product={selectedProduct}
        unselectProduct={(): void => setSelectedProduct(null)}
      />
    </ProductSlider>
  );
}
