import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const Main = styled.main.attrs({
  className: 'main_content',
})`
  padding-bottom: 6rem;
`;

export const Section = styled.section.attrs({
  className: 'section',
})`
  @media ${media.desktopMin} {
    padding-top: ${rem('40px')};
  }
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const ColProducts = styled.div.attrs({
  className: 'col-lg-9',
})`
  padding-left: 0;
  padding-right: 0;
`;

export const Sidebar = styled.aside`
`;
