import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import useLoadStore from 'hooks/useLoadStore';
import useNavControl from 'hooks/useNavControl';

import {
  getDefaultAddress, isLoading, isSuccess, listFiltersProductRequest, searchProductsClean, syncCartRequest,
} from 'store/ducks';
import { isSingleStore } from 'store/ducks/base';
import { listCampaignProductsClean, listLinkedProductsClean } from 'store/ducks/products';
import { listCampaignsRequest } from 'store/ducks/stores';

import { Category } from 'typing/models';

import CampaignBanners from 'ui/components/CampaignBanners';
import CarouselCategories from 'ui/components/CarouselCategories';
import ProductsCategorySlider from 'ui/components/ProductsCategorySlider';
import ProductsHome from 'ui/components/ProductsHome/ProductsHome';
import SearchModal from 'ui/components/SearchModal';
import SidebarCategories from 'ui/components/SidebarCategories';
import StorePageHeader from 'ui/components/StorePageHeader';

import { GET_ADDRESS_BY_CEP, LOAD_AUTH_DATA } from 'utils/fetchs';

import {
  Main,
  Section,
  Container,
  Row,
  Col,
  Sidebar,
  ColProducts,
} from './Store.styled';

export default function Store(): React.ReactElement {
  useNavControl();
  const {
    store, categories, featuredCategories, sideMenuCategories, frontViewCategories,
  } = useLoadStore();

  const [reloadCart, setReloadCart] = useState<boolean>(true);
  const [featuredCategoryList, setFeatureCategoryList] = useState<Category[]>([]);
  const [sideMenuCategoryList, setSideMenuCategoryList] = useState<Category[]>([]);
  const [frontViewCategoryList, setFrontViewCategoryList] = useState<Category[]>([]);

  const dispatch = useDispatch();

  const { openModal, isVisibleModal } = useContext(SettingsContext);

  const address = useSelector(getDefaultAddress);
  const singleStore = useSelector(isSingleStore);

  const modalAddress = !!isVisibleModal && isVisibleModal('ADDRESS');
  const modalSearch = !!isVisibleModal && isVisibleModal('SEARCH');
  const loadingAuthData = useSelector(isLoading(LOAD_AUTH_DATA));
  const addressLoaded = useSelector(isSuccess(GET_ADDRESS_BY_CEP));

  useEffect(() => {
    if (
      !singleStore
      && !modalAddress
      && openModal
      && (!address && addressLoaded)
      && loadingAuthData === false
      && store) {
      openModal('ADDRESS');
    }
  }, [singleStore, address, openModal, modalAddress, loadingAuthData, store]);

  useEffect(() => {
    dispatch(searchProductsClean());
    dispatch(listLinkedProductsClean());
    dispatch(listCampaignProductsClean());
  }, []);

  useEffect(() => {
    if (store) {
      dispatch(listCampaignsRequest(store.slug));
      dispatch(listFiltersProductRequest(store.slug));
    }
  }, [store]);

  useEffect(() => {
    if (store && featuredCategories?.length) {
      setFeatureCategoryList(featuredCategories || []);
    }
  }, [store, featuredCategories]);

  useEffect(() => {
    if (store && sideMenuCategories) {
      setSideMenuCategoryList(sideMenuCategories || []);
    }
  }, [store, sideMenuCategories]);

  useEffect(() => {
    if (store && frontViewCategories?.length) {
      setFrontViewCategoryList(frontViewCategories || []);
    }
  }, [store, frontViewCategories]);

  useEffect(() => {
    if (store && reloadCart) {
      dispatch(syncCartRequest('only_download'));
      setReloadCart(false);
    }
  }, [store, reloadCart]);

  return (
    <Main>
      <StorePageHeader store={store} />
      <CarouselCategories />
      <CampaignBanners />
      {singleStore
        ? (
          <Container>
            <ProductsHome />
          </Container>
        ) : (
          <Section>
            <Container>
              <Row className="row">
                <ColProducts>
                  {
                store && frontViewCategoryList?.filter(
                    (category) => ((category?.count_products || 0) > 0))
                    .map((category) => (
                      <ProductsCategorySlider key={category.id} store={store} category={category} />
                    ))
              }
                </ColProducts>
                <Col
                  className="col-lg-3 order-lg-first mt-lg-0 pt-lg-0 order-first"
                >
                  <Sidebar>
                    <SidebarCategories />
                  </Sidebar>
                </Col>
              </Row>
            </Container>
          </Section>
        )}
      <SearchModal modifiers={modalSearch ? 'ShowSearchModal' : ''} />
    </Main>
  );
}
