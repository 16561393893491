import { combineReducers } from 'redux';

import Base from './base';
import Cart, {
  addProductToCartRequest,
  addProductToCartSuccess,
  addProductToCartFailure,
  addProductToCartClean,
  removeProductToCartRequest,
  removeProductToCartSuccess,
  removeProductToCartFailure,
  removeProductToCartClean,
  loadCartRequest,
  loadCartSuccess,
  loadCartFailure,
  loadCartClean,
  syncCartRequest,
  syncCartSuccess,
  syncCartFailure,
  syncCartClean,
  downloadCartRequest,
  downloadCartSuccess,
  downloadCartFailure,
  downloadCartClean,
  verifyCartRequest,
  verifyCartSuccess,
  responseCreditCard,
  responseMessage,
  verifyCartFailure,
  verifyCartClean,
  clearCart,
  setCheckoutStatus,
  loadPaymentMethodsRequest,
  loadPaymentMethodsSuccess,
  loadPaymentMethodsFailure,
  loadShippingMethodsRequest,
  loadShippingMethodsSuccess,
  loadShippingMethodsFailure,
  loadSelfShippingMethodsRequest,
  loadSelfShippingMethodsSuccess,
  loadSelfShippingMethodsFailure,
  loadPaymentMethodsClean,
  buyCartRequest,
  buyCartSuccess,
  buyCartFailure,
  buyCartClean,
  setChangeValue,
  getCartSubTotal,
  getCart,
  getCartState,
  getAddedProductInCart,
  productIsAddedInCart,
  getCartStore,
  getCheckoutStatus,
  startCart,
  clearResponseCreditCard,
} from './cart';
import Categories, {
  getCategories,
  getCategoryById,
  getSelectedCategory,
  listCategoriesByIds,
  listCategoriesRequest,
  listCategoriesSuccess,
  listCategoriesFailure,
  listCategoriesClean,

  selectCategory,
  unselectCategory,
} from './categories';
import checkFetchReducerCreator, { fetchState, verifyFetchSituation } from './checkFetchReducerCreator';
import FeaturedCategories, {
  getFeaturedCategories,

  listFeaturedCategoriesRequest,
  listFeaturedCategoriesSuccess,
  listFeaturedCategoriesFailure,
  listFeaturedCategoriesClean,
} from './featured_categories';
import FiltersProduct, {
  getFiltersProduct,

  listFiltersProductSuccess,
  listFiltersProductClean,
  listFiltersProductFailure,
  listFiltersProductRequest,
} from './filters_product';
import FrontViewCategories, {
  getFrontViewCategories,

  listFrontViewCategoriesRequest,
  listFrontViewCategoriesSuccess,
  listFrontViewCategoriesFailure,
  listFrontViewCategoriesClean,
} from './front_view_categories';
import Orders, {
  listOrdersRequest,
  listOrdersSuccess,
  listOrdersFailure,
  listOrdersClean,
  getOrderDetailRequest,
  getOrderDetailFailure,
  getOrderDetailSuccess,
  getOrderDetailClean,
  getOrderById,
  getOrders,
  getCompletedOrders,
  getPendingOrders,
} from './orders';
import Products, {
  getProducts,
  getProductsFilters,
  searchProducts,
  listProductsRequest,
  listProductsSuccess,
  listProductsFailure,
  listProductsClean,
  searchProductsRequest,
  searchProductsSuccess,
  searchProductsFailure,
  searchProductsClean,
} from './products';
import SelectedFiltersProduct, {
  getSelectedFiltersProduct, setSelectedFiltersProduct,
} from './selected_filters_product';
import SideMenuCategories, {
  getSideMenuCategories,

  listSideMenuCategoriesRequest,
  listSideMenuCategoriesSuccess,
  listSideMenuCategoriesFailure,
  listSideMenuCategoriesClean,
} from './side_menu_categories';
import Stores, {
  getStores,
  getStoreBySlug,
  getSelectedStore,
  listStoresFailure,
  listStoresRequest,
  listStoresSuccess,
  listStoresClean,
  getStoreRequest,
  getStoreSuccess,
  getStoreFailure,
  getStoreClean,
  selectStore,
  unselectStore,
} from './stores';
import Auth, {
  loginSuccess,
  loginFailure,
  loginRequest,
  loginClean,
  registerRequest,
  registerSuccess,
  registerFailure,
  registerClean,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  updateUserClean,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  resetPasswordClean,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
  refreshTokenClean,
  getAddressByCepRequest,
  getAddressByCepSuccess,
  getAddressByCepFailure,
  getAddressByCepClean,
  logout,
  getAddressByLocationRequest,
  getAddressByLocationSuccess,
  getAddressByLocationFailure,
  getAddressByLocationClean,
  createAddressRequest,
  createAddressSuccess,
  createAddressFailure,
  createAddressClean,
  loadAuthDataRequest,
  loadAuthDataSuccess,
  loadAuthDataFailure,
  loadAuthDataClean,
  getTemporaryAddress,
  getDefaultAddress,
  isLogged,
  getLoginData,
  getRefreshToken,
  getAccessToken,
  checkNotificationsEnabled,
  registerDeviceToken,
  getNotificationsAccepted,
  setNotificationsSituation,
} from './user';

export const isLoading = verifyFetchSituation('loading');
export const isSuccess = verifyFetchSituation('success');
export const hasError = verifyFetchSituation('error');

export {
  loginSuccess,
  loginFailure,
  loginRequest,
  loginClean,
  getAddressByCepRequest,
  getAddressByCepSuccess,
  getAddressByCepFailure,
  getAddressByCepClean,
  registerRequest,
  registerSuccess,
  registerFailure,
  registerClean,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  updateUserClean,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  resetPasswordClean,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
  refreshTokenClean,
  listStoresFailure,
  listStoresRequest,
  listStoresSuccess,
  listStoresClean,
  getStoreRequest,
  getStoreSuccess,
  getStoreFailure,
  getStoreClean,
  selectStore,
  unselectStore,
  listProductsRequest,
  listProductsSuccess,
  listProductsFailure,
  listProductsClean,
  searchProductsRequest,
  searchProductsSuccess,
  searchProductsFailure,
  searchProductsClean,
  searchProducts,
  selectCategory,
  unselectCategory,
  listCategoriesRequest,
  listCategoriesSuccess,
  listCategoriesFailure,
  listCategoriesClean,
  listFeaturedCategoriesRequest,
  listFeaturedCategoriesSuccess,
  listFeaturedCategoriesFailure,
  listFeaturedCategoriesClean,
  listSideMenuCategoriesRequest,
  listSideMenuCategoriesSuccess,
  listSideMenuCategoriesFailure,
  listSideMenuCategoriesClean,
  listFrontViewCategoriesRequest,
  listFrontViewCategoriesSuccess,
  listFrontViewCategoriesFailure,
  listFrontViewCategoriesClean,
  listFiltersProductClean,
  listFiltersProductFailure,
  listFiltersProductRequest,
  listFiltersProductSuccess,
  addProductToCartRequest,
  addProductToCartSuccess,
  addProductToCartFailure,
  addProductToCartClean,
  removeProductToCartRequest,
  removeProductToCartSuccess,
  removeProductToCartFailure,
  removeProductToCartClean,
  loadCartRequest,
  loadCartSuccess,
  loadCartFailure,
  loadCartClean,
  syncCartRequest,
  syncCartSuccess,
  syncCartFailure,
  syncCartClean,
  downloadCartRequest,
  downloadCartSuccess,
  downloadCartFailure,
  downloadCartClean,
  verifyCartRequest,
  verifyCartSuccess,
  responseCreditCard,
  clearResponseCreditCard,
  responseMessage,
  verifyCartFailure,
  verifyCartClean,
  buyCartRequest,
  buyCartSuccess,
  buyCartFailure,
  buyCartClean,
  loadPaymentMethodsRequest,
  loadPaymentMethodsSuccess,
  loadPaymentMethodsFailure,
  loadShippingMethodsRequest,
  loadShippingMethodsSuccess,
  loadShippingMethodsFailure,
  loadSelfShippingMethodsRequest,
  loadSelfShippingMethodsSuccess,
  loadSelfShippingMethodsFailure,
  loadPaymentMethodsClean,
  clearCart,
  setCheckoutStatus,
  startCart,
  logout,
  checkNotificationsEnabled,
  registerDeviceToken,
  getAddressByLocationRequest,
  getAddressByLocationSuccess,
  getAddressByLocationFailure,
  getAddressByLocationClean,
  createAddressRequest,
  createAddressSuccess,
  createAddressFailure,
  createAddressClean,
  loadAuthDataRequest,
  loadAuthDataSuccess,
  loadAuthDataFailure,
  loadAuthDataClean,
  listOrdersRequest,
  listOrdersSuccess,
  listOrdersFailure,
  listOrdersClean,
  getOrderDetailRequest,
  getOrderDetailSuccess,
  getOrderDetailFailure,
  getOrderDetailClean,
  setChangeValue,
  getProducts,
  getProductsFilters,
  getOrderById,
  getOrders,
  getCompletedOrders,
  getPendingOrders,
  getCategories,
  getFeaturedCategories,
  getSideMenuCategories,
  getFrontViewCategories,
  getFiltersProduct,
  getSelectedFiltersProduct,
  setSelectedFiltersProduct,
  listCategoriesByIds,
  getCategoryById,
  getSelectedCategory,
  getSelectedStore,
  isLogged,
  getLoginData,
  getRefreshToken,
  getAccessToken,
  getTemporaryAddress,
  getDefaultAddress,
  getStores,
  getStoreBySlug,
  getCartSubTotal,
  getCart,
  getAddedProductInCart,
  productIsAddedInCart,
  getCartStore,
  getCartState,
  getCheckoutStatus,
  getNotificationsAccepted,
  setNotificationsSituation,
};

export const reducers = combineReducers({
  loading: checkFetchReducerCreator(fetchState.REQUEST),
  success: checkFetchReducerCreator(fetchState.SUCCESS),
  error: checkFetchReducerCreator(fetchState.FAILURE),
  auth: Auth,
  categories: Categories,
  featured_categories: FeaturedCategories,
  side_menu_categories: SideMenuCategories,
  front_view_categories: FrontViewCategories,
  filters_product: FiltersProduct,
  selected_filters_product: SelectedFiltersProduct,
  stores: Stores,
  products: Products,
  cart: Cart,
  orders: Orders,
  app: Base,
});
