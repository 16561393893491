import imgAmerican from 'assets/images/Flags/american.png';
import imgAura from 'assets/images/Flags/aura.png';
import imgDefault from 'assets/images/Flags/default.png';
import imgDiners from 'assets/images/Flags/diners.png';
import imgDiscover from 'assets/images/Flags/discover.png';
import imgElo from 'assets/images/Flags/elo.png';
import imgHiper from 'assets/images/Flags/hiper.png';
import imgJbc from 'assets/images/Flags/jbc.png';
import imgMastercard from 'assets/images/Flags/mastercard.png';
import imgVisa from 'assets/images/Flags/visa.png';

const visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
const mastercard = /^(5[1-5]\d{4}|677189)\d{10}$/;
const american = /^3[47]\d{13}$/;
const aura = /^(5078\d{2})(\d{2})(\d{11})$/;
const discover = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
const elo = /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/;
const diners = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;
const hipercard = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/;
const jbc = /^(?:2131|1800|35\d{3})\d{11}$/;

const cards = [
  {
    name: 'Visa',
    pattern: visa,
    brandName: 'visa',
    brandImage: imgVisa,
  },
  {
    name: 'Mastercard',
    pattern: mastercard,
    brandName: 'mastercard',
    brandImage: imgMastercard,
  },
  {
    name: 'American Express',
    pattern: american,
    brandName: 'amex',
    brandImage: imgAmerican,
  },
  {
    name: 'Aura',
    pattern: aura,
    brandName: 'aura',
    brandImage: imgAura,
  },
  {
    name: 'ELO',
    pattern: elo,
    brandName: 'elo',
    brandImage: imgElo,
  },
  {
    name: 'Discover',
    pattern: discover,
    brandName: 'discover',
    brandImage: imgDiscover,
  },
  {
    name: 'Diners Club',
    pattern: diners,
    brandName: 'diners',
    brandImage: imgDiners,
  },
  {
    name: 'Hipercard',
    pattern: hipercard,
    brandName: 'hipercard',
    brandImage: imgHiper,
  },
  {
    name: 'JCB',
    pattern: jbc,
    brandName: 'jcb',
    brandImage: imgJbc,
  },
];

export function getBrandFromNumber(number: string) {
  for (let i = 0; i < cards.length; i++) {
    if (cards[i].pattern.test(number)) {
      return cards[i]?.brandName;
    }
  }
  return '';
}

export function imageFromNumber(number: string) {
  for (let i = 0; i < cards.length; i++) {
    if (cards[i].pattern.test(number)) {
      return cards[i]?.brandImage;
    }
  }
  return imgDefault;
}

export function getImageFromBrand(brand: string) {
  for (let i = 0; i < cards.length; i++) {
    if (cards[i]?.brandName === brand) {
      return cards[i]?.brandImage;
    }
  }

  return imgDefault;
}

export function getMaskCard(number: string) {
  return number.slice(number.length - 4, number.length);
}
