import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Qs from 'qs';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const setAuthToken = (token: string): void => {
  http.defaults.headers = { Authorization: `Bearer ${token}` };
};

export const addMarketplaceCodeConfig = (marketplaceCode: string): void => {
  http.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    config.params = config.params || {};
    config.params['Marketplace-Code'] = marketplaceCode;
    return config;
  });
};

http.interceptors.response.use((response: AxiosResponse): any => {
  const { data } = response;
  return data;
});

export default http;
