import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import Form from 'react-formal';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { AddCardSchemaType, addCardSchema } from 'utils/schemas';

import { getCartTotal } from '../../../../store/ducks/cart';
import { Card } from '../../../../typing/models';
import { FormGroup, Label } from '../../../screens/EditProfile/EditProfile.styled';

interface AddCardProps {
  cards?: Card[];
  setup?: object;
  onClickAddCard(card: AddCardSchemaType): void,
  totalCards: number,
}

function expirationDate({ ...props }) {
  return (
    <NumberFormat
      {...props}
      className="form-control"
      format="##/####"
    />
  );
}

function cardNumber({ ...props }) {
  return (
    <NumberFormat
      {...props}
      className="form-control"
      format="################"
    />
  );
}

function identityNumber({ ...props }) {
  return (
    <NumberFormat
      {...props}
      className="form-control"
      format="###.###.###-##"
    />
  );
}

function cvv({ ...props }) {
  return (
    <NumberFormat
      {...props}
      className="form-control"
      format="####"
    />
  );
}

function getInstallments({ ...props }) {
  const options = { ...props };
  const installments = _.get(options, 'options.installments', []);
  const minAmount = _.get(options, 'options.min_amount_for_installment', 0);
  const total = _.get(options, 'total', 0);

  return (
    <select
      {...props}
      className="form-control"
    >
      <option value={0}>Selecione...</option>
      {
        installments.map((installment) => {
          const { value, description } = installment;
          const amountInstallment = (total / value);
          if (value === 1 || minAmount === 0 || (minAmount > 0 && minAmount < amountInstallment)) {
            return (
              <option key={value} value={value}>{description}</option>
            );
          }

          return null;
        })
      }
    </select>
  );
}

function getCurrency({ ...props }) {
  return (
    <NumberFormat
      {...props}
      decimalScale={2}
      allowNegative={false}
      fixedDecimalScale
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      type="tel"
      className="form-control"
    />
  );
}

export default function AddCard({
  cards, setup, onClickAddCard, totalCards,
}: AddCardProps): React.ReactElement {
  const total = useSelector(getCartTotal);
  const card = {
    amount: total - totalCards,
    name: '',
    cvv: '',
    card_number: '',
    expiration_date: '',
    identity_number: '',
    installment: '',
  };

  const [editData, setEditData] = useState<AddCardSchemaType>(card);

  return (
    <Form
      autoComplete="off"
      schema={addCardSchema}
      value={editData}
      onChange={(model: any): void => setEditData(model)}
      onSubmit={() => {
        onClickAddCard(editData);
        // @ts-ignore
        let { amount } = editData;
        if (typeof amount === 'string') {
          // @ts-ignore
          amount = parseFloat(String(editData.amount)
            .replace('R$ ', '')
            .replace('.', '')
            .replace(',', '.'));
        }

        card.amount = total - totalCards - amount;
        setEditData(card);
      }}
    >
      <FormGroup>
        <Label>Valor:</Label>
        <Form.Field
          name="amount"
          as={getCurrency}
        />
        <Form.Message for="amount" />
      </FormGroup>
      <FormGroup>
        <Label>Parcelas:</Label>
        <Form.Field
          options={setup}
          total={total}
          name="installment"
          as={getInstallments}
        />
        <Form.Message for="installment" />
      </FormGroup>

      <FormGroup>
        <Label>Número do cartão:</Label>
        <Form.Field
          name="card_number"
          as={cardNumber}
        />
        <Form.Message for="card_number" />
      </FormGroup>
      <FormGroup>
        <Label>Nome do títular</Label>
        <Form.Field name="name" className="form-control" placeholder="Como escrito no cartão" />
        <Form.Message for="name" />
      </FormGroup>
      <FormGroup>
        <Label>CPF do títular</Label>
        <Form.Field
          name="identity_number"
          as={identityNumber}
        />
        <Form.Message for="identity_number" />
      </FormGroup>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <FormGroup>
          <Label>Validade</Label>
          <Form.Field
            placeholder="MM/AAAA"
            name="expiration_date"
            as={expirationDate}
          />
          <Form.Message for="expiration_date" />
        </FormGroup>
        &nbsp;
        <FormGroup>
          <Label>CVV</Label>
          <Form.Field
            name="cvv"
            as={cvv}
          />
          <Form.Message for="cvv" />
        </FormGroup>
      </div>
      <FormGroup>
        <Form.Submit
          type="submit"
          className={classNames({
            'btn btn-fill-out btn-block': true,
          })}
        >
          { cards?.length ? 'Adicionar outro' : 'Adicionar cartão'}
        </Form.Submit>
      </FormGroup>
    </Form>
  );
}
