import { rem } from 'polished';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

const ModalModifiers = {
  Active: () => `
    svg {
      stroke: #06C167;
    }
    span {
      color: black;
    }
  `,
};

export const CardShippingMethodContainer = styled.a.attrs({
})<utils.WithModifiers>`
  background-color: white;
  border: 1px solid #EEE;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-height: ${rem('80px')};
  position: relative;
  padding: 1rem;
  

  svg {
    height: ${rem('24px')};
    margin-right: 1rem;
    stroke: #EEE;
    stroke-width: 3px;
    width: ${rem('24px')};
  }

  span {
    font-size: ${rem('16px')};
    max-width: 75%;
  }

  ${applyStyleModifiers(ModalModifiers)};
`;
