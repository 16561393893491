import * as yup from 'yup';

import { isValidCNPJ } from './cnpj';
import { isValidCPF } from './cpf';

export const cardFields = {
  amount: yup.string().required('O campo valor é obrigatório'),
  name: yup.string().required('O campo de nome do títular é obrigatório'),
  card_number: yup.string().required('O campo número do cartão é obrigatório'),
  identity_number: yup.string().required('O campo CPF do títular é obrigatório'),
  cvv: yup.string().required('O campo CVV é obrigatório'),
  expiration_date: yup.string().required('O campo validade é obrigatório'),
  installment: yup.string().required('O campo parcela é obrigatório'),
};

export const registerFields = {
  people_type: yup.string().oneOf(['pf', 'pj']).required('O campo tipo de pessoa é obrigatório'),
  first_name: yup.string().required('O campo de nome é obrigatório'),
  last_name: yup.string().when('people_type', {
    is: 'pf',
    then: yup.string().required('O campo sobrenome é obrigatório'),
    otherwise: yup.string().notRequired(),
  }),
  identifier_code: yup.string().when('people_type', {
    is: 'pf',
    then: yup.string().required('O campo CPF é obrigatório').test('isValidCpf', 'CPF Inválido', (value) => value?.length !== 14 || isValidCPF(value))
      .length(14, 'O CPF deve conter 11 dígitos'),
    otherwise: yup.string().required('O campo CNPJ é obrigatório').test('isValidCNPJ', 'CNPJ Inválido', (value) => value?.length !== 18 || isValidCNPJ(value))
      .length(18, 'O CNPJ deve conter 14 dígitos'),
  }),

  email: yup.string().email('Campo do tipo email').required('O campo de email é obrigatório'),
  phone_number: yup.string()
    .test('isValidPhoneNumber', 'Telefone inválido', (value) => value?.length === 15)
    .required('O campo de telefone é obrigatório'),
  password: yup.string()
    .min(6, 'A senha deve conter 6 ou mais caracteres.')
    .required('O campo de senha é obrigatório'),
  password2: yup.string()
    .oneOf([yup.ref('password')], 'Confirmação de senha inválida')
    .required('A confirmação da senha é obrigatória'),
};

const {
  password, password2, ...editFields
} = registerFields;

export const addCardSchema = yup.object(cardFields);
export type AddCardSchemaType = yup.InferType<typeof addCardSchema>;

export const registerSchema = yup.object(registerFields);
export type RegisterSchemaType = yup.InferType<typeof registerSchema>;

export const editProfileSchema = yup.object(editFields);
export type EditProfileSchemaType = yup.InferType<typeof editProfileSchema>;

export const changePasswordSchema = yup.object({ password, password2 });
export type ChangePasswordSchemaType = yup.InferType<typeof changePasswordSchema>;

export const recoverPasswordSchema = yup.object({
  email: yup.string()
    .when(
      'type', {
        is: 'email',
        then: yup.string().email('Campo do tipo email').required('O campo de email é obrigatório'),
      },
    ),
  identifier_code: yup.string()
    .when(
      'type', {
        is: 'cpf',
        then: yup.string()
          .test('isValidCpf', 'CPF Inválido', (value) => value?.length !== 14 || isValidCPF(value))
          .length(14, 'O CPF deve conter 11 dígitos'),
      },
    ),
  type: yup.string(),
});
export type RecoverPasswordSchemaType = yup.InferType<typeof recoverPasswordSchema>;
