import first from 'lodash/first';
import React from 'react';
import { useSelector } from 'react-redux';

import { getBanners } from 'store/ducks/base';

import {
  IntroStoreBannerContainer,
  IntroStoreBannerInner,
} from './IntroStoreBanner.styled';

export default function IntroStoreBanner(): React.ReactElement {
  const banners = useSelector(getBanners('featured-web'));

  return (
    <IntroStoreBannerContainer>
      <IntroStoreBannerInner resource={first(banners)?.banner_image || ''} />
    </IntroStoreBannerContainer>
  );
}
