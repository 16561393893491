import { Check } from '@styled-icons/feather';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getCartState, isLoading, isLogged,
  getOrderDetailRequest,
  getOrderDetailClean,
} from 'store/ducks';
import { getMarketPlaceCode } from 'store/ducks/base';
import { getTotalPrice, getSelectedOrder } from 'store/ducks/orders';

import { Cart, SaleAction } from 'typing/models';

import {
  PageBody,
  PageHeader,
  Title,
  ListProgress,
  ItemProgress,
  PanelHeader,
  PanelBody,
  ButtonBack,
} from 'ui/components/Page';
import SpinnerLoading from 'ui/components/SpinnerLoading';
import Table from 'ui/components/Table';

import { GET_ORDER_DETAIL } from 'utils/fetchs';
import { getProductImageUrl } from 'utils/image';
import { numberToMoney } from 'utils/monetary';

import useUrls from '../../../hooks/useUrls';
import { getImageFromBrand } from '../../../utils/card';
import {
  PageOrder,
  PageOrderContainer,
  OrderConfirmation,
  PanelOrder,
  ItemInfo,
  ProductQty,
  SmallProductImage,
  ProductVariants,
  VariantTag, ItemFooter,
} from './Order.styled';

export default function Order(): React.ReactElement {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { getOrdersUrl } = useUrls();

  const [cart, setCart] = useState<Cart>();

  const authorized = useSelector(isLogged);

  const order = useSelector(getSelectedOrder);

  const marketplace = useSelector(getMarketPlaceCode);

  const loading = useSelector(isLoading(GET_ORDER_DETAIL));

  const { data } = useSelector(getCartState);

  useEffect(() => {
    if (authorized && orderId) {
      dispatch(getOrderDetailRequest(orderId));
    }
    return (): void => {
      dispatch(getOrderDetailClean());
    };
  }, [authorized]);

  useEffect(() => {
    if (data.id) {
      setCart(data);
    }
  }, [data.id]);

  const actionLabel = {
    delivered: 'O pedido foi recebido',
    confirmed: 'O pedido confirmado',
    in_delivery: 'Pedido saiu para entrega',
    waiting: 'Aguardando aprovação',
    separating_products: 'Produtos em separação',
    cancel: 'Pedido cancelado',
    invoiced: 'Pedido faturado',
    partially_invoice: 'Faturado parcialmente',
    in_progress: 'Pedido em andamento',
  };

  const defaultAction: SaleAction = {
    id: 0,
    action_type: 'waiting',
    action_date: moment(order?.purchase_date).toDate(),
    sale: order?.id || 'null',
  };

  const getProductVariants = (item: any): {
    name: string;
    value: string;
  }[] => {
    if (item.product_child && item.product_child.child_attribute_values !== undefined) {
      return item.product_child.child_attribute_values.map((attribute: any) => ({
        name: attribute.attribute_code,
        value: attribute.value,
      }));
    }
    return [];
  };

  return (
    <PageOrder>
      <PageOrderContainer>
        <PageHeader>
          <ButtonBack to={getOrdersUrl()}>Voltar</ButtonBack>
          <Title>{cart?.store?.name}</Title>
        </PageHeader>
        <PageBody>
          {
            !order ? (
              <OrderConfirmation>
                {loading ? <SpinnerLoading />
                  : (
                    <>
                      <h2><strong>Enviando pedido...</strong></h2>
                      <p>Isso pode levar alguns minutos</p>
                    </>
                  )}
              </OrderConfirmation>
            ) : (
              <>
                <PanelOrder>
                  <PanelHeader>
                    <h2>Progresso</h2>
                  </PanelHeader>
                  <PanelBody>
                    <ListProgress>
                      {
                        [...order.sale_actions, defaultAction].map((action, index) => (
                          <ItemProgress key={action.id} modifiers={index === 0 ? ['Completed'] : []}>
                            <Check />
                            <strong>{(moment(action.action_date).isValid() ? moment(action.action_date) : moment()).format('HH:mm')}</strong>
                            {'  '}
                            -
                            {' '}
                            {actionLabel[action.action_type]}
                          </ItemProgress>
                        ))
                      }
                    </ListProgress>
                  </PanelBody>
                </PanelOrder>
                <PanelOrder>
                  <PanelHeader>
                    <h2>
                      Pedido #
                      {order.id?.substring(0, 8)}
                    </h2>
                  </PanelHeader>
                  <PanelBody>
                    <ItemInfo>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.Heading>Produto</Table.Heading>
                            <Table.Heading>
                              <div className="text-right">
                                Total
                              </div>
                            </Table.Heading>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {
                            order.cart_items?.map((item, index) => (
                              <Table.Row key={index}>
                                <Table.Item>
                                  <SmallProductImage
                                    src={
                                      getProductImageUrl(
                                        (cart && cart.store ? cart.store?.slug : ''),
                                        item.product_token,
                                        marketplace,
                                      )
                                    }

                                    alt="cart_thumb1"
                                  />
                                  <ProductQty>
                                    {parseFloat(item.quantity)}
                                    x
                                    {' '}
                                  </ProductQty>
                                  {' '}
                                  {item.product_name}
                                  <ItemFooter>
                                    <ProductVariants>
                                      {getProductVariants(item).filter(({ name }) => ['COR', 'TAMANHO'].includes(name)).map((variant) => (
                                        <VariantTag key={variant.name}>
                                          <b>
                                            {variant.name}
                                            :
                                          </b>
                                          {' '}
                                          {variant.value}
                                        </VariantTag>
                                      ))}
                                    </ProductVariants>
                                  </ItemFooter>
                                </Table.Item>
                                <Table.Item>
                                  <div className="text-right">
                                    {numberToMoney(
                                      parseFloat(item.unit_price)
                                      * parseFloat(item.quantity),
                                    )}
                                  </div>
                                </Table.Item>
                              </Table.Row>
                            ))
                          }
                        </Table.Body>
                        <Table.Footer>
                          <Table.Row>
                            <Table.Heading>Sub Total</Table.Heading>
                            <Table.Item>
                              <div className="text-right">
                                {numberToMoney(getTotalPrice(order))}
                              </div>
                            </Table.Item>
                          </Table.Row>
                          <Table.Row>
                            <Table.Heading>
                              Taxa de entrega (
                              {' '}
                              {order.shipping_method?.description}
                              {' '}
                              )
                            </Table.Heading>
                            <Table.Item>
                              <div className="text-right">
                                {order.delivery_fee ? numberToMoney(order.delivery_fee) : 'GRATUITO'}
                              </div>
                            </Table.Item>
                          </Table.Row>

                          {
                            !!order.service_fee && (
                              <Table.Row>
                                <Table.Heading>Taxa de serviço</Table.Heading>
                                <Table.Item>
                                  <div className="text-right">
                                    {numberToMoney(order.service_fee)}
                                  </div>
                                </Table.Item>
                              </Table.Row>
                            )
                          }

                          <Table.Row>
                            <Table.Heading>Total</Table.Heading>
                            <Table.Item className="product-subtotal">
                              <div className="text-right">
                                {numberToMoney(getTotalPrice(order))}
                              </div>
                            </Table.Item>
                          </Table.Row>
                        </Table.Footer>
                      </Table>

                    </ItemInfo>
                  </PanelBody>
                </PanelOrder>
                <PanelOrder>
                  <PanelHeader>
                    <h2>Mais detalhes</h2>
                  </PanelHeader>
                  <PanelBody>
                    <ItemInfo>
                      <h4>Data do pedido</h4>
                      <p>{moment(order.purchase_date).format('DD/MM/YYYY • HH:mm')}</p>
                    </ItemInfo>

                    {
                      cart?.shipping_method?.identifier === 'delivery-my-address' && (
                        <ItemInfo>
                          <h4>Endereço de entrega</h4>
                          <p>{`${order.delivery_address?.street}, ${order.delivery_address?.street_number} - ${order.delivery_address?.city}/${order.delivery_address?.state}`}</p>
                        </ItemInfo>
                      )
                    }

                    <ItemInfo>
                      <h4>Forma da pagamento</h4>
                      <p>
                        {order.payment_type?.name}
                        {order.payment_type?.accept_change
                        && (` • Troco para ${numberToMoney(order.change_for_payment ? parseFloat(order.change_for_payment.toString()) : 0)}`)}
                      </p>
                    </ItemInfo>

                    {
                      _.get(order, 'payment_info.credit_cards', []).length > 0 && (
                        <ItemInfo>
                          <h4>Cartões</h4>

                          <Table>
                            <Table.Header>
                              <Table.Row>
                                <Table.Heading>
                                  Cartão
                                </Table.Heading>
                                <Table.Heading>
                                  <div className="text-right">
                                    Valor
                                  </div>
                                </Table.Heading>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {
                                _.get(order, 'payment_info.credit_cards', []).map((card, index) => {
                                  const final = card.mask;

                                  return (
                                    <Table.Row key={index}>
                                      <Table.Item>
                                        <div style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                        }}
                                        >
                                          <img
                                            src={getImageFromBrand(card.type)}
                                            style={{
                                              width: 40,
                                              height: 35,
                                              marginRight: 16,
                                              alignSelf: 'flex-start',
                                            }}
                                          />
                                          <div>
                                            <span>
                                              Final
                                              {' '}
                                            </span>
                                            <span style={{ fontWeight: 600 }}>{final}</span>
                                          </div>
                                        </div>
                                      </Table.Item>
                                      <Table.Item>
                                        <div className="text-right">
                                          {numberToMoney(card.amount)}
                                        </div>
                                      </Table.Item>
                                    </Table.Row>
                                  );
                                })
                              }
                            </Table.Body>
                          </Table>
                        </ItemInfo>
                      )
                    }
                  </PanelBody>
                </PanelOrder>
              </>
            )
          }
        </PageBody>
      </PageOrderContainer>
    </PageOrder>
  );
}
