import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { rem } from 'polished';

import { media } from 'utils/themeHelper';

import { utils } from 'typing';

const StoreCardModifiers = {
  ContentCenter: () => `
    text-align: center;
    .product_img {
      justify-content: center;
    }
  `,
};

export const StoreCardContainer = styled(Link).attrs({
  className: 'product',
})<utils.WithModifiers>`
${applyStyleModifiers(StoreCardModifiers)};
  background: #f7f7f7;
  box-shadow: none;
  border-radius: 30px;
  display: block;
  @media ${media.desktopMin} {
    background-color: white;
  }
`;

export const Thumbnail = styled.div.attrs({
  className: 'product_img d-flex',
})`
  justify-content: center;
  padding: 1.25rem;
  &:before {
    display: none;
  }
  @media ${media.desktopMin} {
    background-color: #f7f7f7;
    border-radius: 30px;
    margin-bottom: 1rem;
    padding: 2rem 3rem;
  }
`;

export const ThumbnailInner = styled.div.attrs({
  className: '',
})`
  background-color: white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem('150px')};
  overflow: hidden;
`;

export const Image = styled.img.attrs({
  className: '',
})`
  border-radius: 16px;
`;

export const Info = styled.div.attrs({
  className: 'product_info',
})`
  padding-top: 0;
  text-align: center;
`;

export const Title = styled.h6.attrs({
  className: 'product_title',
})`
  font-size: ${rem('18px')} !important;
  font-weight: 800;
`;

export const Text = styled.p.attrs({
  className: '',
})`
`;
