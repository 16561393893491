import styled from 'styled-components';

export const CartReviewContainer = styled.section.attrs({
  className: 'order_review',
})`
  background-color: white;
`;

export const Heading = styled.div.attrs({
  className: 'heading_s1',
})`
`;

export const ProductQty = styled.span.attrs({
  className: 'product-quantity',
})`
  font-weight: bold;
`;

export const Quantity = styled.span.attrs({
  className: 'quantity ml-auto',
})`
`;
