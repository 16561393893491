import React from 'react';

import {
  SnackBarContainer,
  Message,
} from './FeedbackMessage.styled';

export default function SnackBar({ children, modifiers }: any): React.ReactElement {
  return (
    <SnackBarContainer modifiers={modifiers}>
      <Message>{children}</Message>
    </SnackBarContainer>
  );
}
