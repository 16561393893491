import { Repeat } from '@styled-icons/feather';
import { rem, size } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const InfoModifiers = {
  DangerColor: () => `
    color: #06C167;
  `,
  SuccessColor: () => `
    color: #27903B;
    font-weight: bold;
  `,
};

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 12rem);
  @media ${media.tabletMin} {
    min-height: calc(100vh - 24rem);
  }
`;

export const ProductContainer = styled.div`
  margin-bottom: 2rem;
  .cart_list {
    li {
      border: none;
    }
  }
`;

export const Box = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  display: block;
  margin: .75rem auto 0;
  max-width: ${rem('375px')};
  padding: ${rem('4px')} ${rem('16px')};
  width: 100%;
`;

export const StepTag = styled.span`
  background-color: #f7f7f7;
  display: inline-block;
  margin: 0 auto .5rem;
  max-width: ${rem('100px')};
  padding: 6px 12px;
`;

export const InfoChange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Info = styled.p.attrs({
})<utils.WithModifiers>`
  color: black;
  margin: 0 1rem;
  text-align: center;
  ${applyStyleModifiers(InfoModifiers)};
`;

export const ValueChangeIcon = styled(Repeat)`
  ${size('24px')};
`;

export const ButtonBase = styled.a`
  margin-top: auto;
  @media ${media.tabletMin} {
    align-self: center;
    max-width: ${rem('375px')};
  }
`;

export const Button = styled(ButtonBase).attrs({
  className: 'btn btn-fill-out btn-block',
})``;

export const ButtonDisabled = styled(ButtonBase).attrs({
  className: 'btn btn-fill-out btn-block disabled',
})``;
