import flatten from 'lodash/flatten';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import useSearchParams from 'hooks/useSearchParams';
import useUrls from 'hooks/useUrls';

import {
  getCategories, getProducts, getSelectedCategory, getStoreBySlug,
} from 'store/ducks';

import { Category } from 'typing/models';

import {
  NavCategoriesContainer,
  List,
  ItemList,
  Space,
} from './NavCategories.styled';

interface SidebarCategoriesProps {
  fullCategories?: boolean;
  categoryTextSearch?: string;
}

export default function NavCategories({ fullCategories = true, categoryTextSearch = '' }: SidebarCategoriesProps):
  React.ReactElement {
  const { storeSlug } = useParams();
  const [searchValue] = useSearchParams(['value']);
  const { getSearchUrl } = useUrls();
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);

  const categories: Category[] = useSelector(getCategories(storeSlug));
  const selected: Category | null = useSelector(getSelectedCategory);
  const store = useSelector(getStoreBySlug(storeSlug));
  const products = useSelector(getProducts(storeSlug, selected?.id || -1));

  useEffect(() => {
    const productsCategories = flatten(
      products.map((product) => product.category_ids.map((ctg) => ctg.id)),
    );

    if (fullCategories) {
      setCategoriesList(categories);
    } else if (selected && !searchValue) {
      setCategoriesList([selected]);
    } else {
      setCategoriesList(categories.filter((ctg) => productsCategories.includes(ctg.id)));
    }
  }, [selected, categories?.length, products?.length]);

  const resultList = useMemo(() => {
    const normalize = (word: string):
      string => word.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    return categoriesList.filter((category) => {
      const categoryNameToken = normalize(category.name);
      const childrenNamesToken = normalize(category.categories_child_ids.map((child) => child.name).join(' '));

      return `${categoryNameToken} ${childrenNamesToken}`.includes(normalize(categoryTextSearch));
    });
  }, [categoriesList, categoryTextSearch]);

  return (
    <NavCategoriesContainer>
      <List>
        {
          store && resultList?.map((category) => (
            <React.Fragment key={category.id}>
              {
                !!category.categories_child_ids.length && (
                  <>
                    {
                      category.categories_child_ids.map((child) => (
                        <ItemList key={child.id}>
                          <Link to={getSearchUrl(category, child)}>
                            {child.name}
                          </Link>
                        </ItemList>
                      ))
                    }
                    <Space />
                  </>
                )
              }
            </React.Fragment>
          ))
        }
      </List>
    </NavCategoriesContainer>
  );
}
