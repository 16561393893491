import React from 'react';
import { useParams } from 'react-router-dom';

import useLoadStore from 'hooks/useLoadStore';
import useNavControl from 'hooks/useNavControl';
import useSearchParams from 'hooks/useSearchParams';

import CampaignResult from 'ui/components/CampaignResult';
import CategoryResult from 'ui/components/CategoryResult';
import LinkResult from 'ui/components/LinkResult';
import SearchResult from 'ui/components/SearchResult';
import StorePageHeaderCategory from 'ui/components/StorePageHeaderCategory';

import CategoryResultFull from '../../components/CategoryResultFull';
import { Main, Section } from './StoreCategory.styled';

export default function StoreCategory(): React.ReactElement {
  useNavControl();
  const { store } = useLoadStore();
  const [categoryId] = useSearchParams(['categoryId']);
  const { linkId, campaignId } = useParams();

  const Result = (): React.ReactElement => {
    if (categoryId) {
      if (store?.config?.show_mode_for_category_products == 'full') {
        return <CategoryResultFull />;
      }
      return <CategoryResult />;
    }
    if (linkId) {
      return <LinkResult />;
    }
    if (campaignId) {
      return <CampaignResult />;
    }
    return <SearchResult />;
  };

  return (
    <Main>
      <StorePageHeaderCategory store={store} />
      <Section>
        <Result />
      </Section>
    </Main>
  );
}
