import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CardOrderContainer = styled(Link)`
  background-color: #f7f7f7;
  border-radius: 16px;
  padding: 1.25rem 1.25rem .5rem;
  margin-bottom: 1rem;
  min-height: ${rem('175px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;

  h2 {
    font-size: ${rem('20px')};
    font-weight: bold;
    margin: 0;
  }
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      color: black;
      margin: 0;
      &:not(:first-child){
        margin-left: 8px;
      }
    }
  }
  .andamento { 
    color: #F09253;
  }
`;

export const Image = styled.img`
  height: 40px;
  margin-right: 1rem;
  width: auto;
`;

export const CardBody = styled.div`
  ul {
    color: #687188;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  p {
    color: black;
  }
  span {
    color: ${(props) => props.theme.primary.main};
    margin-left: auto;
  }
`;
