import React from 'react';
import { useSelector } from 'react-redux';
import {
  Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';

import { AUTH } from 'context/SettingsContext';

import useNavControl from 'hooks/useNavControl';
import useScrollReset from 'hooks/useScrollReset';

import { isLogged } from 'store/ducks';

import { Orders, Order } from 'ui/screens';

export default function OrdersRouter(): React.ReactElement {
  const { path } = useRouteMatch();
  const authorized = useSelector(isLogged);

  useScrollReset();
  useNavControl(!authorized ? AUTH : undefined);

  return (
    <Switch>
      <Route path={`${path}/:orderId`}>
        { authorized ? <Order /> : <Redirect to="/orders" /> }
      </Route>
      <Route path="/">
        <Orders />
      </Route>
    </Switch>
  );
}
