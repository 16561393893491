import { rem } from 'polished';
import styled from 'styled-components';

import { media } from '../../../utils/themeHelper';

export const ProductSlider = styled.div.attrs({
  className: 'mb-4',
})`
  background-color: #f7f7f7;
  border-radius: 16px;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  @media ${media.tabletMin} {
    overflow: visible !important;
    padding: 2rem 2rem 1rem;
  }

  .heading_tab_header {
    margin-bottom: 0;
  }

  .heading_s2 {
    @media ${media.tabletMin} {
      margin-bottom: .5rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col-6 {
    padding: 0 8px;
    .item {
      margin-left: 0;
    }
  }

`;

export const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  min-height: ${rem('48px')};
  width: 100%;
`;

export const Button = styled.button.attrs({
  className: 'btn btn-fill-out btn-block',
})`
  max-width: ${rem('175px')};
  min-width: ${rem('175px')};
`;

export const ButtonDisabled = styled.span.attrs({
  className: 'btn btn-fill-out btn-block disabled',
})`
  max-width: ${rem('175px')};
  min-width: ${rem('175px')};
`;

export const ButtonFilter = styled.button.attrs({
  className: 'btn text_default',
})`
`;

export const NoResult = styled.h2`
  text-align: center;
  @media ${media.tabletMax} {
    font-weight: bold;
  }
  @media ${media.desktopMin} {
    margin-left: 1rem;
  }
`;
