import { size, rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const ButtonCartCountModifiers = {
  Alert: () => `
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: pulse 1s ease infinite;
    border-radius: 50%;
    border: 4px double red;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    60% {
      transform: scale(1.3);
      opacity: 0.4;
    }
    100% {
      transform: scale(1.4);
      opacity: 0;
    }
  }
  `,
};

export const AppNavHeaderContainer = styled.header.attrs({
  className: 'header_wrap fixed-top header_with_topbar',
})`
  border: none !important;
  z-index: 1040;
`;

export const MainMenu = styled.div.attrs({
  className: 'bottom_header dark_skin main_menu_uppercase',
})`
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const NavBar = styled.nav.attrs({
  className: 'navbar navbar-expand-lg d-flex',
})`
`;

export const NavBrand = styled.div.attrs({
  className: 'navbar-brand mx-auto',
})`
`;

export const Brand = styled.img.attrs({
  className: 'logo_dark',
})`
  max-height: ${rem('48px')};
  max-width: ${rem('210px')};
`;

export const Address = styled.div.attrs({
  className: 'justify-content-end navbar__address',
})`
  cursor: pointer;
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  strong {
    @media ${media.tabletMax} {
      width: 100%;
    } 
  }
  span {
    background-color: transparent !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 50%;
    display: inline-block;
    padding: 0 4px 0 6px !important;
    @media ${media.mobileMax} {
      max-width: none;
      width: auto;
    }
  }
`;

export const Collapse = styled.div.attrs({
  className: 'collapse navbar-collapse justify-content-end',
})`
`;

export const NavBarNav = styled.div.attrs({
  className: 'navbar-nav',
})`
`;

export const NavBarList = styled.div.attrs({
  className: 'navbar-nav attr-nav align-items-center d-none d-lg-flex',
})`
`;

export const Item = styled.li.attrs({
  className: '',
})`
`;

export const ItemDropdown = styled.li.attrs({
  className: 'dropdown',
})`
  > .nav-link {
    pointer-events: none;
  }
  ul li a {
    cursor: pointer;
    display: block;
  }
`;

export const DropdownMenu = styled.div.attrs({
  className: 'dropdown-menu',
})`
  border-radius: 16px !important;
  left: auto;
  padding: 1rem !important;
  right: 0;
  width: ${rem('300px')};
`;

export const NavLink = styled(Link).attrs({
  className: 'nav-link',
})`
  svg {
    stroke: #292b2c;
    ${size('24px')};
  }
  > span {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 6px 12px;
  }
  .cart_count {
    background-color: ${(props) => props.theme.primary.main} !important;
    border-radius: 50px !important;
    padding: 0 5px !important;
    position: absolute;
    top: 12px;
    right: 5px;
    left: auto;
  }
`;

export const AddressButton = styled.a.attrs({
  className: 'nav-link',
})`
  svg {
    stroke: #292b2c;
    ${size('24px')};
  }
  span {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 6px 12px;
  }
`;

export const CartButton = styled.a.attrs({
  className: 'nav-link cart_trigger',
})`
  cursor: pointer;
  svg {
    stroke: #292b2c;
    ${size('24px')};
  }
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: ${rem('20px')};
  svg {
    stroke: black;
    stroke-width: 2px;
    ${size('30px')};
  }
  @media ${media.tabletMax} {
    display: none;
  }
`;

export const CartCount = styled.span.attrs({
  className: 'cart_count',
})<utils.WithModifiers>`
  font-weight: bold;
  ${applyStyleModifiers(ButtonCartCountModifiers)};
`;

export const CartAmount = styled.span.attrs({
  className: 'amount',
})`
  color: black;
`;

export const CurrencySymbol = styled.span.attrs({
  className: 'currency_symbol',
})`
`;
