import { rem } from 'polished';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

const SnackModifiers = {
  Active: () => `
    display: block;
    div {
      opacity: 1;
    }
  `,
  Success: () => `
    div {
      background-color: #4799FC; 
    }
  `,
  Danger: () => `
    div {
      color: #FF324D;
    }
  `,
  SnackBar: () => `
    animation-name: fadeInUp;
    animation-duration: .5s;
    animation-fill-mode: both;

    @keyframes fadeInUp {
      0% {
        opacity: 0;
        top: 0;
      }
      100% {
        opacity: 1;
        top: -50px;
      }
    }

    div {
      background-color: #4799FC;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: ${rem('38px')};
      min-width: ${rem('215px')};
      opacity: 0;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 0;
    }
  `,
  ErrorBar: () => `
    animation-name: fadeInUp;
    animation-duration: .5s;
    animation-fill-mode: both;

    @keyframes fadeInUp {
      0% {
        opacity: 0;
        top: 0;
      }
      100% {
        opacity: 1;
        top: -50px;
      }
    }

    div {
      background-color: #fef1f0;
      color: #FF6767;
      display: flex;
      padding: 10px 12px;
      align-items: center;
      justify-content: center;
      min-height: ${rem('38px')};
      opacity: 0;
    }
  `,
};

export const SnackBarContainer = styled.div.attrs({
  className: 'feedback-message',
})<utils.WithModifiers>`
  display: none;
  position: relative;
  text-transform: none;
  z-index: 100;
  ${applyStyleModifiers(SnackModifiers)};
`;

export const Message = styled.div`
  font-size: ${rem('14px')};
  margin-bottom: 1rem;
  text-align: center;
`;
