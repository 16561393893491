import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CHECKOUT } from 'context/SettingsContext';

import useNavControl from 'hooks/useNavControl';

import { getCart, getDisagreements, removeProductToCartRequest } from 'store/ducks/cart';

import { CartItem, ItemDisagreement } from 'typing/models';

import {
  Page,
  Container,
  PageHeader,
  Title,
  Text,
} from 'ui/components/Page';

import { getProductImageUrl } from 'utils/image';
import { numberToMoney } from 'utils/monetary';

import { getMarketPlaceCode } from '../../../store/ducks/base';
import QuantityControl from '../../components/QuantityControl';
import {
  CartList,
  CartQuantity,
  Item,
  Product,
  ProductActions,
  ProductImage,
} from '../../components/ShoppingCart/ShoppingCart.styled';
import {
  StepItem,
  StepTag,
  InfoChange,
  Info,
  ValueChangeIcon,
  Button,
  Box,
  ProductContainer,
  ButtonDisabled,
} from './FixOrder.styled';

export default function FixOrder(): React.ReactElement {
  useNavControl(CHECKOUT);

  const history = useHistory();
  const dispatch = useDispatch();

  const [localDisagreements, setLocalDisagreements] = useState<ItemDisagreement[]>([]);
  const [selected, setSelected] = useState<ItemDisagreement | undefined>();
  const [index, setIndex] = useState(0);
  const [cartItem, setCartItem] = useState<CartItem | undefined>(undefined);

  const cart = useSelector(getCart);
  const disagreements = useSelector(getDisagreements);
  const marketplace = useSelector(getMarketPlaceCode);

  const getItemByProductId = useCallback((productId: number | string):
    CartItem | undefined => cart.items.find((item) => item.product.id === productId),
  [cart.items]);

  useEffect(() => {
    if (selected) {
      setCartItem(getItemByProductId(selected.item.product.id));
    }
  }, [cart.items]);

  useEffect(() => {
    setLocalDisagreements(disagreements);
  }, [disagreements]);

  const next = useCallback((): void => {
    if (selected?.types.includes('EMPTY_STOCK')) {
      dispatch(removeProductToCartRequest(selected.item.product));
    }

    if (index === disagreements?.length) {
      history.push('/checkout/');
    } else {
      const itemDisagreement = localDisagreements[index];
      setSelected(localDisagreements[index]);
      setCartItem(getItemByProductId(itemDisagreement.item.product.id));
      setIndex(index + 1);
    }
  }, [index, selected, localDisagreements]);

  const messageTitle = disagreements?.length > 1
    ? `${disagreements.length} Produtos em seu carrinho precisam ser atualizados`
    : '1 Produto em seu carrinho precisa ser atualizado';

  return (
    <Page>
      <Container modifiers={['TextCenter', 'Small']}>
        {
          !selected ? (
            <StepItem>
              <PageHeader>
                <Title>{messageTitle}</Title>
              </PageHeader>
              <Text>
                O estoque é atualizado continuamente,
                então a quantidade, disponibilidade e
                preço de alguns produtos podem ser modificados.
              </Text>
              <Button onClick={next}>Atualizar produtos</Button>
            </StepItem>
          ) : (
            <StepItem>
              <StepTag>
                {index}
                {' '}
                de
                {' '}
                {disagreements.length}
              </StepTag>
              {
                [
                  (
                    selected?.types.includes('QUANTITY_NOT_AVAILABLE') && (
                      <PageHeader key="QUANTITY_NOT_AVAILABLE">
                        <Title modifiers="Small">
                          A quantidade de produto selecionado não está disponível
                        </Title>
                        <Box>
                          <InfoChange>
                            <Info modifiers="DangerColor">
                              Adicionado:
                              {' '}
                              {selected.item.quantity}
                            </Info>
                            <ValueChangeIcon />
                            <Info modifiers="SuccessColor">
                              Disponível:
                              {' '}
                              {selected.maxQuantity}
                            </Info>
                          </InfoChange>
                        </Box>
                      </PageHeader>
                    )
                  ),
                  (
                    selected?.types.includes('UPDATED_VALUE') && (
                      <PageHeader key="UPDATED_VALUE">
                        <Title modifiers="Small">O valor do produto foi atualizado</Title>
                        <Box>
                          <InfoChange>
                            <Info>
                              Antes
                              <br />
                              {numberToMoney(selected.item.product.product_child.price)}
                            </Info>
                            <ValueChangeIcon />
                            <Info modifiers="SuccessColor">
                              Agora
                              <br />
                              {numberToMoney(selected?.newPrice || 0)}
                            </Info>
                          </InfoChange>
                        </Box>
                      </PageHeader>
                    )
                  ),
                  (
                    selected?.types.includes('EMPTY_STOCK') && (
                      <PageHeader key="EMPTY_STOCK">
                        <Title modifiers="Small">Nenhum item disponível para esse produto:</Title>
                        <p>Procurar produto de mesma categoria</p>
                      </PageHeader>
                    )
                  ),
                ]
              }
              <ProductContainer>
                <CartList>
                  {
                    cartItem && (
                      <Item
                        key={cartItem.product.id}
                        {...{ modifiers: selected?.types.includes('EMPTY_STOCK') ? ['Disabled'] : [] }}
                      >
                        <Product>
                          <ProductImage
                            src={
                              getProductImageUrl(
                                cart.store?.slug || '',
                                cartItem.product.id || 0,
                                marketplace,
                              )
                            }
                            alt="cart_thumb1"
                          />
                          {cartItem.product.name}
                        </Product>
                        <ProductActions>
                          <CartQuantity>
                            {cartItem.quantity}
                            {' '}
                            x
                            {' '}
                            {numberToMoney(cartItem.product.base_price || 0)}
                          </CartQuantity>
                          <QuantityControl item={cartItem} />
                        </ProductActions>
                      </Item>
                    )
                  }
                </CartList>
              </ProductContainer>

              {
                (selected?.types.includes('EMPTY_STOCK') && (cartItem?.quantity || 0) < (selected.maxQuantity || 0)) ? (
                  <ButtonDisabled onClick={next}>Atualizar produtos</ButtonDisabled>
                ) : (
                  <Button onClick={next}>Atualizar produtos</Button>
                )
              }
            </StepItem>
          )
        }
      </Container>
    </Page>
  );
}
