import { Search } from '@styled-icons/feather';
import { rem, size } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const StoreSearch = styled.div.attrs({
  className: 'product_search_form search_button mx-auto',
})`
  @media ${media.tabletMax} {
    display: none;
  }
`;

export const InputButton = styled.a`
  display: flex;
`;

export const SearchInputGroup = styled.div.attrs({
  className: 'input-group',
})`
  border-radius: 16px;
  overflow: hidden;
`;

export const SearchInput = styled.span.attrs({
  className: 'form-control',
})`
  border: none;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  font-size: ${rem('14px')};
  height: ${rem('50px')};
  text-align: left;
`;

export const Placeholder = styled.span`
  opacity: .5;
  overflow: hidden;
  padding-right: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Button = styled.span.attrs({
  className: 'search_btn',
})`
  background: none;
  display: flex;
  align-items: center;
`;

export const IconSearch = styled(Search)`
  position: relative;
  ${size('24px')};
  top: -2px;
`;
