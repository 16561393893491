import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getCart,
  getCategories,
  getFeaturedCategories,
  getSideMenuCategories,
  getFrontViewCategories,
  getCategoryById,
  getSelectedStore,
  getStoreBySlug,
  getStoreRequest,
  listCategoriesRequest,
  listCategoriesSuccess,
  searchProductsClean,
  searchProductsRequest,
  selectCategory,
  selectStore,
  startCart,
  unselectCategory,
  listFeaturedCategoriesRequest,
  listSideMenuCategoriesRequest,
  listFrontViewCategoriesRequest,
  listFeaturedCategoriesSuccess,
  listSideMenuCategoriesSuccess,
  listFrontViewCategoriesSuccess, setSelectedFiltersProduct,
} from 'store/ducks';

import { Category, Store } from 'typing/models';

import useSearchParams from './useSearchParams';

interface LoadStoreData {
  store?: Store | null;
  categories?: Category[] | null;
  featuredCategories?: Category[] | null;
  sideMenuCategories?: Category[] | null;
  frontViewCategories?: Category[] | null;
}

export default function useLoadStore()
  : LoadStoreData {
  const { storeSlug } = useParams();
  const [categoryId, searchValue] = useSearchParams(['categoryId', 'value']);

  const store = useSelector(getStoreBySlug(storeSlug));
  const selectedStore = useSelector(getSelectedStore);
  const categories = useSelector(getCategories(store?.slug));
  const featuredCategories = useSelector(getFeaturedCategories(store?.slug));
  const sideMenuCategories = useSelector(getSideMenuCategories(store?.slug));
  const frontViewCategories = useSelector(getFrontViewCategories(store?.slug));
  const cart = useSelector(getCart);

  const dispatch = useDispatch();

  const category = useSelector(getCategoryById(categoryId));

  useEffect(() => {
    if (!categoryId && !searchValue) {
      dispatch(searchProductsClean());
    }

    if (!store) {
      dispatch(getStoreRequest(storeSlug));
    } else {
      if (selectedStore?.slug !== store.slug) {
        dispatch(listCategoriesSuccess([]));
        dispatch(listFeaturedCategoriesSuccess([]));
        dispatch(listSideMenuCategoriesSuccess([]));
        dispatch(listFrontViewCategoriesSuccess([]));
      }
      dispatch(selectStore(store));
    }
  }, []);

  useEffect(() => {
    if (store && !categories?.length) {
      dispatch(listCategoriesRequest(store.slug));
    }
    if (store && !featuredCategories?.length) {
      dispatch(listFeaturedCategoriesRequest(store.slug));
    }
    if (store && !sideMenuCategories?.length) {
      dispatch(listSideMenuCategoriesRequest(store.slug));
    }
    if (store && !frontViewCategories?.length) {
      dispatch(listFrontViewCategoriesRequest(store.slug));
    }
  }, [store]);

  useEffect(() => {
    if (store && !cart.items.length) {
      dispatch(startCart(store));
    }
  }, [store, cart]);

  useEffect(() => {
    if (!categoryId) {
      dispatch(unselectCategory());
    }

    if (category) {
      dispatch(setSelectedFiltersProduct({}));
      dispatch(selectCategory(category));
    }
  }, [category]);

  useEffect(() => {
    dispatch(searchProductsRequest({ searchName: searchValue }));
  }, [searchValue]);

  return {
    store, categories, featuredCategories, sideMenuCategories, frontViewCategories,
  };
}
