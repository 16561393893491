import { uniq } from 'lodash';
import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import Img from 'react-cool-img';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';

import { getProductImages } from 'services/api/products';

import { getSelectedStore } from 'store/ducks';
import { getConfig } from 'store/ducks/base';

import { GalleryNavItem } from './ProductGallery.styled';

export default function ProductGallery({ product }): React.ReactElement {
  const { productId } = useParams();

  const [photos, setPhotos] = useState<any>([]);
  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const store = useSelector(getSelectedStore);
  const { image_category_placeholder: placeholder } = useSelector(getConfig);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const setPhotosConsideringStock = useCallback((photos): void => {
    if (product) {
      const availableEans = uniq(product.product_variant_children.map((child) => child.stocker_code));
      setPhotos(photos.filter((photo) => availableEans.includes(photo.ean)));
    }
  }, [product]);

  useEffect(() => {
    async function getPhotos() {
      try {
        if (product && product.images) {
          setPhotosConsideringStock(JSON.parse(product.images));
        } else {
          const response: any = await getProductImages(store?.slug || 'undefined', productId);
          setPhotosConsideringStock(response);
        }
      } catch (e) {
        setPhotosConsideringStock([{ image: {} }]);
      }
    }

    if (productId || product) {
      getPhotos();
    }
  }, [productId, product]);

  return (
    <div>
      <Slider
        asNavFor={nav2}
        ref={slider1}
        speed={200}
        infinite={false}
      >
        {
          photos.map((photo: any) => (
            <div key={photo.image.hash}>
              <Img
                src={photo.image.url}
                {...{
                  placeholder: placeholder ?? undefined,
                  error: placeholder ?? undefined,
                  cache: false,
                  debounce: 0,
                  retry: { count: 0 },
                }}
                alt=""
              />
            </div>
          ))
        }
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={slider2}
        speed={200}
        slidesToShow={4}
        swipeToSlide
        focusOnSelect
        infinite={false}
      >
        {
          photos.map((photo: any) => (
            <GalleryNavItem key={photo.image.hash}>
              <Img
                src={photo.image.url}
                {...{
                  placeholder: placeholder ?? undefined,
                  error: placeholder ?? undefined,
                  cache: false,
                  debounce: 0,
                  retry: { count: 0 },
                }}
                alt=""
              />
            </GalleryNavItem>
          ))
        }
      </Slider>
    </div>
  );
}
