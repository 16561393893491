import React from 'react';

import ImgBanner from 'assets/images/store-banner-header.jpg';

import { Store } from 'typing/models';

import Search from 'ui/components/Search';

import SearchButton from '../SearchButton';
import StoreLabel from '../StoreLabel';
import {
  StorePageHeaderContainer,
  Container,
  Row,
  Col,
  PageTitle,
  PageTitleInner,
  StoreImage,
  StoreBannerHeader,
  StoreBannerInner,
  StoreImageContainer,
} from './StorePageHeader.styled';

interface StorePageHeaderProps {
  store?: Store | null;
}

export default function StorePageHeader({ store }: StorePageHeaderProps): React.ReactElement {
  return (
    <StorePageHeaderContainer>
      <Container className="container">
        <Row className="row align-items-center">
          <Col className="col-md-8">
            <PageTitle>
              <StoreImageContainer>
                <StoreImage src={store?.image_logo || ''} alt="product_img1" />
              </StoreImageContainer>
              <PageTitleInner>
                <h1>{store?.name}</h1>
                <StoreLabel store={store} />
              </PageTitleInner>
            </PageTitle>
          </Col>
          <Col className="col-md-4 text-center text-lg-left">
            <Search />
            <SearchButton />
          </Col>
        </Row>
      </Container>
      {/* <StoreBannerHeader>
        <StoreBannerInner>
          <img src={store?.image_banner || ''} />
        </StoreBannerInner>
      </StoreBannerHeader> */}
    </StorePageHeaderContainer>
  );
}
