import { createGlobalStyle } from 'styled-components';

import { MainTheme } from 'typing/theme';

const GlobalStyle = createGlobalStyle<{ theme: MainTheme }>`

/* Buttons */
.navbar .navbar-nav > li > a.active, .navbar .navbar-nav > li:hover > a, .light_skin.transparent_header.nav-fixed .navbar .navbar-nav > li > a.active, .light_skin.transparent_header.nav-fixed .navbar .navbar-nav > li:hover > a, .transparent_header.nav-fixed .light_skin .navbar .navbar-nav > li > a.active, .transparent_header.nav-fixed .light_skin .navbar .navbar-nav > li:hover > a {
  color: ${(props) => props.theme.primary.main};
}

.dark_skin .navbar .navbar-nav .dropdown-menu li a.active, .dark_skin .navbar .navbar-nav .dropdown-menu li a:hover, .dark_skin .navbar .navbar-nav .dropdown-menu > ul > li:hover > a, .dark_skin .navbar .navbar-nav .dropdown-menu > ul > .mega-menu-col ul > li:hover > a {
  color: ${(props) => props.theme.primary.main};
}

.btn-fill-out:hover {
  color: ${(props) => props.theme.primary.main} !important;
}

.btn-fill-out {
  border-color: ${(props) => props.theme.primary.main};
}

.btn-fill-out::before, .btn-fill-out::after {
  background-color: ${(props) => props.theme.primary.main};
}

.btn-border-fill::before, .btn-border-fill::after {
  background-color: ${(props) => props.theme.primary.main};
}

/* badge */
.cart_count, .wishlist_count {
  background-color: ${(props) => props.theme.primary.main};
}

.btn_cart_mobile {
  background-color: ${(props) => props.theme.primary.main} !important;
}

/* Link */
a:hover {
  color: ${(props) => props.theme.primary.main};
}

/* Text */
.text_default {
  color: ${(props) => props.theme.primary.main} !important;
}

/* Checkbox */
.custome-checkbox input[type="checkbox"]:checked + .form-check-label::before {
  background-color: ${(props) => props.theme.primary.main};
  border-color: ${(props) => props.theme.primary.main};
}

`;

export default GlobalStyle;
