import flatten from 'lodash/flatten';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getProductsFilters, getSelectedStore, isLoading, listCategoriesByIds, listProductsRequest,
} from 'store/ducks';
import { getSearchedProducts } from 'store/ducks/products';

import { SEARCH_PRODUCTS_EMPTY_LIST_NOTICE } from 'utils/contants';
import { LIST_PRODUCTS } from 'utils/fetchs';

import { getConfig } from '../../../store/ducks/base';
import NavCategories from '../NavCategories';
import ProductsSlider from '../ProductsSlider/ProductsSlider';
import SidebarCategories from '../SidebarCategories';
import {
  Col, Container, Row, Sidebar, NoResult, ColProducts,
} from './SearchResult.styled';

export default function SearchResult(): React.ReactElement {
  const dispatch = useDispatch();
  const store = useSelector(getSelectedStore);

  const products = useSelector(getSearchedProducts(store?.slug || 'undefined'));
  const {
    search_products_empty_list_notice = SEARCH_PRODUCTS_EMPTY_LIST_NOTICE,
  } = get(useSelector(getConfig), 'config.labels');
  const categories = useSelector(listCategoriesByIds(store?.slug || 'undefined', flatten(
    products.map((p) => p.category_ids.map((c) => c.id.toString())),
  )));

  const productsFilter = useSelector(getProductsFilters('search'));
  const loading = useSelector(isLoading(LIST_PRODUCTS, productsFilter?.searchName || undefined));

  useEffect(() => {
    dispatch(listProductsRequest([], productsFilter?.searchName));
  }, [productsFilter?.searchName]);

  return (
    <Container>
      {
        loading ? (
          <p>Carregando...</p>
        ) : (
          <Row className="row">
            <NavCategories fullCategories={false} />
            <ColProducts>
              {
                categories.length
                  ? categories.filter(
                    (category) => ((category?.count_products || 0) > 0),
                  )
                    .map((category) => (
                      <ProductsSlider
                        key={category.id}
                        categoryId={category.id}
                        categoryName={category.name}
                      />
                    )) : (
                      <NoResult>
                        {search_products_empty_list_notice}
                      </NoResult>
                  )
              }
            </ColProducts>
            <Col
              className="col-lg-3 order-lg-first mt-lg-0 pt-lg-0 order-first"
              style={{
                position: 'sticky', top: '100px', alignSelf: 'flex-start', zIndex: 10,
              }}
            >
              <Sidebar>
                <SidebarCategories fullCategories={false} />
              </Sidebar>
            </Col>
          </Row>
        )
      }
    </Container>
  );
}
