import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import useUrls from 'hooks/useUrls';

import { getCampaigns } from 'store/ducks/stores';

import SliderContainer from 'ui/components/SliderContainer';

import {
  Section,
  Container,
  SliderBannersContainer,
  SliderBanners,
  Banner,
  Image,
} from './CampaignBanners.styled';

export default function CampaignBanners(): React.ReactElement {
  const campaigns = useSelector(getCampaigns);
  const { getCampaignUrl } = useUrls();

  const customPaging = (i) => (
    <div
      style={{
        width: '30px',
      }}
    >
      {' '}
      -
    </div>
  );

  return (

    <>
      {
        !!campaigns.length && (
          <Section>
            <Container>
              <SliderContainer>
                <SliderBannersContainer>
                  <SliderBanners
                    customPaging={customPaging}
                  >
                    {
                      campaigns.map((campaign) => (
                        <Banner key={campaign.id} to={getCampaignUrl(campaign.id)}>
                          <Image src={campaign.banner_image} />
                        </Banner>
                      ))
                    }
                  </SliderBanners>
                </SliderBannersContainer>
              </SliderContainer>
            </Container>
          </Section>
        )
      }
    </>
  );
}
