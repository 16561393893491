import sumBy from 'lodash/sumBy';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useVerifiedStoreAction from 'hooks/useVerifiedStoreAction';

import {
  addProductToCartRequest, removeProductToCartRequest, hasError,
} from 'store/ducks';

import { CartItem, Stock } from 'typing/models';

import {
  ButtonMinus, ButtonPlus, Quantity, QuantityInner, QuantityNumber,
} from './QuantityControl.styled';

export interface QuantityInputProps {
  item: CartItem;
  selectedStockItem?: Stock | undefined | null
}

export default function QuantityControl({ item, selectedStockItem }: QuantityInputProps): React.ReactElement {
  const dispatch = useDispatch();

  const { storeSlug } = useParams();

  const productId = item.product.id;

  const stockLimit = useMemo(
    () => (selectedStockItem ? selectedStockItem.quantity : sumBy(item.product.product_child.stock, 'quantity')),
    [item.product.product_child],
  );

  const [iptValue, setIptValue] = useState<number | ''>(item.quantity);

  const changeIptValue = (event: any): void => {
    const { value } = event.target;
    if (value) {
      setIptValue(parseFloat(value));
    } else {
      setIptValue('');
    }
  };

  const setValue = (): void => {
    if (iptValue === '' || iptValue === 0) {
      iptValue === 0 && setIptValue(0);
      dispatch(removeProductToCartRequest(item.product));
    } else {
      dispatch(
        addProductToCartRequest(
          {
            ...item,
            quantity: iptValue > stockLimit ? stockLimit : iptValue,
            location_code: selectedStockItem?.location_code,
          },
        ),
      );
    }
  };

  const checkIfEnterPressed = (event: any): void => {
    if (event.key === 'Enter') {
      if (iptValue === '') {
        setIptValue(0);
      } else {
        setValue();
      }
    }
  };

  useEffect(() => {
    if (item.quantity !== iptValue) {
      setIptValue(item.quantity);
    }
  }, [item.quantity]);

  const minusActionVerified = useVerifiedStoreAction(
    storeSlug,
    (): any => (item.quantity === 1
      ? dispatch(removeProductToCartRequest(item.product))
      : dispatch(addProductToCartRequest({
        ...item,
        quantity: item.quantity - 1,
        location_code: selectedStockItem?.location_code || item.location_code,
      }))),
  );

  const plusActionVerified = useVerifiedStoreAction(
    storeSlug,
    (): any => {
      if (item.quantity + 1 <= stockLimit) {
        dispatch(addProductToCartRequest({
          ...item,
          quantity: item.quantity + 1,
          location_code: selectedStockItem?.location_code || item.location_code,
        }));
      }
    },
  );

  return (
    <>
      <Quantity>
        <QuantityInner>
          <ButtonMinus onClick={minusActionVerified} />
          <QuantityNumber
            type="number"
            min={0}
            max={99999}
            onChange={changeIptValue}
            onBlur={setValue}
            name="quantity"
            onKeyPress={checkIfEnterPressed}
            value={iptValue}
            title="Qty"
          />
          <ButtonPlus onClick={plusActionVerified}>+</ButtonPlus>
        </QuantityInner>
      </Quantity>
    </>

  );
}
