import { URLQueryMap } from 'typing/utils';

export const buildURLQuery = (obj: URLQueryMap): string => Object.entries(obj)
  .map((pair) => pair.map(encodeURIComponent).join('='))
  .join('&');

export const getProxySettingsLocation = (): string | undefined => {
  // Get encoded URL from window.location
  let link = '';
  if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
    link = 'https://support.google.com/chrome/answer/3220216';
  } else if (/Firefox/.test(navigator.userAgent) || /Mozilla/.test(navigator.userAgent)) {
    link = 'https://support.mozilla.org/pt-BR/kb/notificacoes-web-push-firefox';
  } else if (/Edg/.test(navigator.userAgent)) {
    link = 'https://support.microsoft.com/pt-br/microsoft-edge/gerenciar-notifica%C3%A7%C3%B5es-de-site-no-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b#:~:text=No%20Edge%2C%20v%C3%A1%20para%20Configura%C3%A7%C3%B5es,est%C3%A3o%20enviando%20notifica%C3%A7%C3%B5es%20no%20momento.';
  }
  return link;
};
