import { rem } from 'polished';
import Slider from 'react-slick';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const Main = styled.main.attrs({
  className: 'main_content',
})`
  padding-bottom: 4rem;
`;

export const Section = styled.section.attrs({
  className: 'section',
})`
  @media ${media.desktopMin} {
    padding-top: ${rem('60px')};
  }
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const Sidebar = styled.aside`
`;

export const ProductSlider = styled.div.attrs({
  className: 'mb-4',
})`
  background-color: #f7f7f7;
  border-radius: 16px;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  @media ${media.tabletMin} {
    overflow: visible !important;
    padding: 2rem 2rem 1rem;
  }

  .heading_s2 {
    @media ${media.tabletMin} {
      margin-bottom: .5rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }

  .slick-arrow {
    display: none !important;
    @media ${media.tabletMax} {
      background-color: white;
      align-items: center;
      border-radius: 100px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      display: flex !important;
      height: 40px !important;
      justify-content: center;
      opacity: 1;
      right: -25px;
      width: 40px;
    }
    &:before {
      color: black !important;
      font-family: 'Poppins';
      font-size: ${rem('40px')} !important;
      content: '›' !important;
      line-height: 0;
      position: relative;
      top: -2px;
    }
    &.slick-prev {
      left: -10px;
      &:before {
        content: '‹' !important;
      }
    }
  }
`;

export const LineSlider = styled(Slider).attrs(() => ({
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 475,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
}))`
  width: 500px;
  @media(min-width: 400px) {
    width: 525px;
  }
  @media ${media.mobileMax} {
    width: auto;
  }
`;
