import { AxiosPromise } from 'axios';

import { Category, Product } from 'typing/models';
import { ProductsFilter } from 'typing/store';

import http from './http';

export const searchProducts = (
  storeSlug: string, categories?: number[], filters?: ProductsFilter, offset = 0, limit = 20,
):
  AxiosPromise<Product[]> => {
  const params: any = {};

  if (filters?.searchName && filters?.searchName.length) {
    params.name = filters.searchName;
  }

  if (filters?.extra_filters && Object.keys(filters?.extra_filters).length) {
    Object.keys(filters.extra_filters).forEach((key) => {
      if (filters.extra_filters) {
        const item = filters.extra_filters[key];
        const value = Array.isArray(item) ? item.join(',') : item;
        if (value) {
          params[key] = value;
        }
      }
    });
  } else if (categories && categories.length) {
    params['category_ids[]'] = categories;
  }

  params.offset = offset;
  params.limit = limit;

  return http.get(`/store/${storeSlug}/client/product/`, { params });
};

export const listLinkedProducts = (storeSlug: string, linkId: string, page = 0):
  AxiosPromise<Product[]> => {
  const params: any = {};
  const totalRecords = 20;
  params.offset = page * totalRecords;
  params.limit = totalRecords;
  return http.get(`/store/${storeSlug}/client/selected-products/${linkId}/`);
};

export const listCampaignProducts = (storeSlug: string, campaignId: string):
  AxiosPromise<Product[]> => http.get(`/store/${storeSlug}/client/campaign/${campaignId}/`);

export const getProduct = (storeSlug: string, productId: number | string):
  AxiosPromise<Product> => http.get(`/store/${storeSlug}/client/product/${productId}/`);

export const getFiltersProduct = (storeSlug: string, category: Category): AxiosPromise<Product> => {
  let params = {};
  if (category) {
    params = {
      category_id: category.id,
    };
  }

  return http.get(`/store/${storeSlug}/client/product/filters/`, { params });
};

export const getProductImages = (storeSlug: string, productId: number | string):
  AxiosPromise<any[]> => http.get(`/store/${storeSlug}/client/product/${productId}/images/`);
