import get from 'lodash/get';
import React, {
  useContext, useState, useEffect, useCallback,
} from 'react';
import Form from 'react-formal';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import Img from 'assets/images/user-location.svg';

import SettingsContext from 'context/SettingsContext';

import {
  getAddressByCepRequest,
  getTemporaryAddress,
  getAddressByLocationRequest,
  createAddressRequest,
  isSuccess,
  createAddressClean,
  isLoading,
  hasError,
  getAddressByCepSuccess,
  getAddressByCepClean,
  isLogged,
} from 'store/ducks';
import { getConfig } from 'store/ducks/base';

import FeedbackMessage from 'ui/components/FeedbackMessage';
import { PageHeader, Title } from 'ui/components/Page';

import { ADDRESS_FORM_LABEL, ZIP_FORM_LABEL } from 'utils/contants';
import { CREATE_ADDRESS, GET_ADDRESS_BY_CEP, GET_ADDRESS_BY_LOCATION } from 'utils/fetchs';
import { cepMask } from 'utils/masks';

import {
  SearchAddress,
  FormGroup,
  Label,
  Input,
  Divider,
  ButtonLocation,
  ImgLocation,
  Button,
  ButtonLocationInner,
  FormControl,
  BoxMessage,
} from './AddressForm.styled';

export default function AddressForm(): React.ReactElement {
  const { closeModal } = useContext(SettingsContext);
  const dispatch = useDispatch();

  const addressDataFormSchema = yup.object({
    street: yup.string().required('O campo logradouro é obrigatório'),
    street2: yup.string(),
    street_number: yup.string().required('O campo número é obrigatório'),
    district: yup.string().required('O campo Bairro é obrigatório'),
    postal_code: yup.string().required('O campo CEP é obrigatório'),
  });

  type AddressDataFormSchemaType = yup.InferType<typeof addressDataFormSchema>;

  const address = useSelector(getTemporaryAddress);
  const {
    zip_form_label = ZIP_FORM_LABEL,
    address_form_label = ADDRESS_FORM_LABEL,
  } = get(useSelector(getConfig), 'config.labels');

  const [postalCode, setPostalCode] = useState('');

  const [
    addressDataForm, setAddressDataForm,
  ] = useState<AddressDataFormSchemaType | undefined>(address || undefined);

  const getPositionStatus = {
    notStarted: 'not_started',
    started: 'started',
    done: 'done',
  };

  const [loadingGetPosition, setLoadingGetPosition] = useState(getPositionStatus.notStarted);

  useEffect(() => {
    if (postalCode.length === 9 && loadingGetPosition !== 'done') {
      dispatch(getAddressByCepRequest(postalCode.replace(/\D/g, '')));
    }
  }, [dispatch, postalCode, loadingGetPosition]);

  useEffect(() => {
    if (address) {
      const dataForm = { ...address };
      if (!dataForm.postal_code) {
        dataForm.postal_code = postalCode;
      }
      setAddressDataForm(dataForm);
    }
  }, [address]);

  const getCurrentLocation = useCallback(() => {
    setLoadingGetPosition(getPositionStatus.started);
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      dispatch(getAddressByLocationRequest({ latitude, longitude }));
      setLoadingGetPosition(getPositionStatus.done);
    }, () => setLoadingGetPosition(getPositionStatus.notStarted), {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
  }, [dispatch]);

  const success = useSelector(isSuccess(CREATE_ADDRESS));
  const authorized = useSelector(isLogged);
  const loadingGetByCep = useSelector(isLoading(GET_ADDRESS_BY_CEP));
  const loadingGetByLocation = useSelector(isLoading(GET_ADDRESS_BY_LOCATION));
  const hasErrorGetByLocation = useSelector(hasError(GET_ADDRESS_BY_LOCATION));
  const errorCreateAddress = useSelector(hasError(CREATE_ADDRESS));

  useEffect(() => {
    if (success) {
      if (authorized) {
        dispatch(getAddressByCepSuccess(null));
      }
      closeModal && closeModal();
      dispatch(createAddressClean());
    }
  }, [success, authorized]);

  return (
    address && !address.default ? (
      <>
        <Form
          schema={addressDataFormSchema}
          value={addressDataForm}
          defaultValue={addressDataFormSchema.default()}
          onChange={(model: any): void => {
            if (model?.postal_code) {
              model.postal_code = cepMask(model.postal_code);

              if (model.postal_code.length === 9) {
                setPostalCode(model.postal_code);
                // dispatch(getAddressByCepRequest(model.postal_code.replace(/\D/g, '')));
              } else {
                setLoadingGetPosition(getPositionStatus.notStarted);
              }
            }

            setAddressDataForm(model);
          }}
          onSubmit={(): void => {
            const defaultAddress = { ...addressDataForm, default: true };
            dispatch(createAddressRequest(defaultAddress));
            window.scrollTo(0, 0);
          }}
        >
          <PageHeader>
            <Title>{address_form_label}</Title>
          </PageHeader>
          {
            errorCreateAddress && (
              <p style={{ color: 'red' }}>Não foi possível adicionar seu endereço. Tente mais tarde, por favor.</p>
            )
          }
          <FormGroup>
            <Label>CEP</Label>
            <Form.Field name="postal_code" className="form-control" />
            <Form.Message for="postal_code" />
          </FormGroup>
          <FormGroup>
            <Label>Bairro</Label>
            <Form.Field name="district" className="form-control" />
            <Form.Message for="district" />
          </FormGroup>
          <FormGroup>
            <Label>Rua</Label>
            <Form.Field name="street" className="form-control" />
            <Form.Message for="street" />
          </FormGroup>
          <FormGroup>
            <Label>Número</Label>
            <Form.Field name="street_number" type="number" className="form-control" />
            <Form.Message for="street_number" />
          </FormGroup>
          <FormGroup>
            <Label>Complemento</Label>
            <Form.Field name="street2" className="form-control" />
            <Form.Message for="street2" />
          </FormGroup>
          <Button>Salvar endereço</Button>
        </Form>
      </>
    ) : (
      <SearchAddress>
        <PageHeader>
          <Title>{zip_form_label}</Title>
        </PageHeader>
        <form>
          <FormGroup>
            <Label>Digite seu CEP</Label>
            <FormControl>
              <Input
                type="text"
                value={postalCode}
                disabled={loadingGetByCep || loadingGetPosition === 'started' || loadingGetByLocation}
                onChange={(e): void => setPostalCode(cepMask(e.target.value))}
              />
              {
                      loadingGetByCep && (
                        <FeedbackMessage modifiers="Active">
                          Procurando...
                        </FeedbackMessage>
                      )
                    }
            </FormControl>
          </FormGroup>
        </form>
        <Divider>ou</Divider>
        <ButtonLocation
          onClick={getCurrentLocation}
          disabled={loadingGetByCep || loadingGetPosition === 'started' || loadingGetByLocation}
        >
          <ImgLocation src={Img} />
          <ButtonLocationInner>
            {
                    (loadingGetPosition === 'started' || loadingGetByLocation) ? (
                      <span>Procurando...</span>
                    ) : (
                      <span>Usar minha localização</span>
                    )
                  }
          </ButtonLocationInner>
        </ButtonLocation>
        <BoxMessage>
          <FeedbackMessage modifiers={hasErrorGetByLocation ? ['Active', 'Danger'] : []}>
            Não foi possível encontrar sua localização.
            Por favor, digite seu CEP ou tente novamente.
          </FeedbackMessage>
        </BoxMessage>
      </SearchAddress>
    )
  );
}
