import { rem, size } from 'polished';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

import { Page, PageHeader } from '../Page';

const FilterProductsModifiers = {
  show: () => `
    display: block;
  `,
};

const CartTotalModifiers = {
  Last: () => `
    color: black !important;
  `,
};

export const ModalCategoriesContainer = styled.aside.attrs({
  className: 'cart_box',
})<utils.WithModifiers>`
  display: none;
  overflow-y: hidden;
  z-index: 1042;
  ${applyStyleModifiers(FilterProductsModifiers)};
`;

export const FilterProductsPage = styled(Page)`
  padding-top: 1rem;
  padding-bottom: 6rem;
`;

export const FilterProductsPageHeader = styled(PageHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FilterTitle = styled.h2.attrs({
  className: 'switch_lable',
})`
  display: block;
  float: none;
  font-weight: bold;
  font-size: ${rem('16px')};
  @media ${media.tabletMin} {
    float: left;
    margin-bottom: 0;
  }
`;

export const CheckForm = styled.div.attrs({
  className: 'chek-form',
})`
`;

export const Checkbox = styled.div.attrs({
  className: 'custome-checkbox',
})`
`;

export const CloseCart = styled.a.attrs({
  className: 'close_cart_box',
})`
  cursor: pointer;
  svg {
    ${size('30px')};
  }
`;

export const Item = styled.li.attrs({
  className: '',
})`
`;

export const Button = styled.a.attrs({
  className: 'btn btn-fill-out checkout',
})`
`;

export const VariantContainer = styled.p`
  margin-bottom: 8px;
`;
