import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSelectedCategory, getSelectedStore, listFiltersProductRequest, setSelectedFiltersProduct,
} from 'store/ducks';

import ProductsFull from '../ProductsFull/ProductsFull';
import {
  Container,
} from './CategoryResultFull.styled';

export default function CategoryResultFull(): React.ReactElement {
  const dispatch = useDispatch();

  const selectedCategory = useSelector(getSelectedCategory);
  const store = useSelector(getSelectedStore);
  const storeSlug = useMemo(() => store?.slug || 'undefined', []);

  useEffect(() => {
    dispatch(listFiltersProductRequest(storeSlug));
  }, []);

  return (
    <Container>
      {
        !!selectedCategory?.id
        && (
        <ProductsFull
          key={selectedCategory?.id}
          categoryId={selectedCategory?.id}
          categoryName={selectedCategory?.name}
        />
        )
      }
    </Container>
  );
}
