import classNames from 'classnames';
import React, { useState } from 'react';
import Form from 'react-formal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { isSuccess, resetPasswordRequest } from 'store/ducks';

import { Page, Container } from 'ui/components/Page';

import { RESET_PASSWORD } from 'utils/fetchs';
import { changePasswordSchema } from 'utils/schemas';

import {
  LoginWrapper, LoginInner, Heading, FormGroup,
} from '../Login/Login.styled';

export default function ChangePassword(): React.ReactElement {
  const [passwords, setPassword] = useState({ password: '', password2: '' });

  const success = useSelector(isSuccess(RESET_PASSWORD));

  const dispatch = useDispatch();

  return (
    <Page>
      <Container modifiers="Small">
        <LoginWrapper>
          <LoginInner>
            {
              success ? (
                <Heading>
                  <h2>Nova senha cadastrada com sucesso!</h2>
                  <br />
                  <p>
                    <Link className="btn btn-border-fill btn-radius" to="/">Clique para continuar</Link>
                  </p>
                </Heading>
              ) : (
                <>
                  <Heading>
                    <h2>Nova senha</h2>
                  </Heading>
                  <Form
                    autoComplete="off"
                    schema={changePasswordSchema}
                    value={passwords}
                    defaultValue={changePasswordSchema.default()}
                    onChange={(model: any): void => setPassword(model)}
                    onSubmit={(): void => { dispatch(resetPasswordRequest(passwords)); }}
                  >
                    <fieldset>
                      <FormGroup>
                        <Form.Field name="password" placeholder="Senha" className="form-control" type="password" />
                        <Form.Message for="password" />
                      </FormGroup>
                      <FormGroup>
                        <Form.Field name="password2" placeholder="Confirmar senha" className="form-control" type="password" />
                        <Form.Message for="password2" />
                      </FormGroup>
                    </fieldset>
                    <FormGroup>
                      <Form.Submit
                        type="submit"
                        className={classNames({
                          'btn btn-fill-out btn-block': true,
                        })}
                      >
                        Cadastrar nova senha
                      </Form.Submit>
                    </FormGroup>
                  </Form>
                </>
              )
            }
          </LoginInner>
        </LoginWrapper>
      </Container>
    </Page>
  );
}
