import React from 'react';

import { SliderContainerStyled } from './SliderContainer.styled';

export default function SliderContainer({ children }: any): React.ReactElement {
  return (
    <SliderContainerStyled>
      {children}
    </SliderContainerStyled>
  );
}
