import first from 'lodash/first';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import useVerifiedStoreAction from 'hooks/useVerifiedStoreAction';

import { addProductToCartRequest, getAddedProductInCart } from 'store/ducks';

import { Product } from 'typing/models';

import Modal from 'ui/components/Modal';

import { numberToMoney } from 'utils/monetary';

import ProductImage from '../ProductImage/ProductImage';
import QuantityControl from '../QuantityControl';
import {
  Row,
  Col,
  ProductImageContainer,
  CartBtn,
} from './ProductDetail.styled';

interface ProductDetailProps {
  product: Product | null,
  unselectProduct(): void,
}

export default function ProductDetail(
  { product, unselectProduct }: ProductDetailProps,
): React.ReactElement {
  const { storeSlug } = useParams();

  const item = useSelector(getAddedProductInCart(product?.id));

  const dispatch = useDispatch();

  const verifiedAction = useVerifiedStoreAction(
    storeSlug,
    (): any => dispatch(addProductToCartRequest({ product, quantity: 1 })),
  );

  return (
    <Modal close={unselectProduct} modifiers={product ? 'ShowModal' : ''}>
      <Row className="row">
        <Col className="col-lg-6 col-md-6 mb-4 mb-md-0">
          <div className="product-image">
            <ProductImageContainer>
              <ProductImage productId={product?.id} />
            </ProductImageContainer>
          </div>
        </Col>
        <Col className="col-lg-6 col-md-6">
          <div className="pr_detail">
            <div className="product_description">
              <h4 className="product_title">{product?.name}</h4>
              <p>{product?.description}</p>
              <div className="product_price">
                <span className="price">
                  {
                    numberToMoney(
                      product?.product_child.promo_price
                        ? product?.product_child.promo_price
                        : product?.base_price,
                    )
                  }
                </span>
                {
                  product?.product_child.promo_price
                  && (<del>{numberToMoney(product?.base_price || 0)}</del>)
                }
                {/* <div className="on_sale"> */}
                {/*  <span>10% Off</span> */}
                {/* </div> */}
              </div>
            </div>
            <hr />
            <div className="cart_extra">
              {
                item ? (
                  <QuantityControl item={item} />
                ) : (
                  <CartBtn>
                    <button
                      className="btn btn-fill-out btn-addtocart btn-add-product"
                      onClick={verifiedAction}
                      type="button"
                    >
                      Adicionar
                    </button>
                  </CartBtn>
                )
              }
            </div>
            <hr />
            <ul className="product-meta">
              <li>
                Código do produto:
                {' '}
                <a>{product?.product_child.ean13}</a>
              </li>
              <li>
                Categoria:
                {' '}
                <Link to={`/store/${storeSlug}/search?categoryId=${first(product?.category_ids)?.parent_id}`}>
                  {product?.category_ids.map((category) => category.name).join(' / ')}
                </Link>
              </li>
            </ul>
            {/* <hr /> */}
            {/* <ProdDescription>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum, facilis? Harum, odio. Aliquam, ducimus in. Perferendis ratione quasi eum, possimus blanditiis accusamus modi tenetur minus rem praesentium perspiciatis animi! Exercitationem.</p>
            </ProdDescription> */}
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
