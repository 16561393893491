export const APP_NAME = 'Budega Mix';
export const EMPTY_CART_TITLE = 'Sua sacola está vazia';
export const EMPTY_CART_SUBTITLE = 'Aproveite e comece a fazer suas compras';
export const ZIP_FORM_LABEL = 'Onde você vai receber suas compras?';
export const ADDRESS_FORM_LABEL = 'Endereço';
export const NEAREST_STORES_LIST_LABEL = 'Próximas de você';
export const NEAREST_STORES_EMPTY_LIST_NOTICE = 'Não encontramos lojas próximas a você';
export const SEARCH_BUTTON_LABEL = 'Encontrar produtos na loja';
export const CATEGORY_SEARCH_BUTTON_LABEL = 'Encontrar produtos na loja';
export const SEARCH_PRODUCTS_EMPTY_LIST_NOTICE = 'Encontrar produtos na loja';
export const LOGIN_FORM_LABEL = 'Entrar';
export const REGISTER_FORM_LABEL = 'Criar conta';

export const PAYMENT_TYPE_ONLINE_CREDIT_CARD = 'online_credit_card';
