import React from 'react';

import { Ellipsis } from './LoaderBubble.styled';

export default function LoaderBubble(): React.ReactElement {
  return (
    <Ellipsis>
      <span />
      <span />
      <span />
    </Ellipsis>

  );
}
