import React from 'react';

import { WithModifiers } from 'typing/utils';

import {
  ModalContainer,
  ModalWrapper,
  ModalInner,
  ModalContent,
  ModalContentInner,
  CloseModal,
} from './Modal.styled';

interface ModalProps extends WithModifiers {
  children?: React.ReactChildren | React.ReactChild;
  close({ ...args }: any): void;
}

export default function Modal({ children, modifiers, close }: ModalProps): React.ReactElement {
  return (
    <ModalContainer modifiers={modifiers}>
      <ModalWrapper tabIndex={-1} style={{ overflow: 'hidden auto' }}>
        <ModalInner>
          <ModalContent>
            <ModalContentInner>
              {children}
              <CloseModal title="Close (Esc)" type="button" onClick={close}>×</CloseModal>
            </ModalContentInner>
          </ModalContent>
        </ModalInner>
      </ModalWrapper>
    </ModalContainer>
  );
}
