import produce from 'immer';
import { createActions, handleActions } from 'redux-actions';

import { Configuration, Banner } from 'typing/models';
import {
  AbstractPayload, BaseState, AppBaseState,
} from 'typing/store';

import { START_APP } from 'utils/fetchs';

import { fetchStateGenerator } from './checkFetchReducerCreator';

export const {
  startAppRequest,
  startAppSuccess,
  startAppFailure,
  startAppClean,
  loadRemoteData,
} = createActions(
  {},
  ...fetchStateGenerator(START_APP),
  'LOAD_REMOTE_DATA',
  { prefix: 'BASE' },
);

const INITIAL_STATE = {
  config: {
    name: null,
    description: null,
    default_code: null,
    image_logo: null,
    image_fav_icon: null,
    image_product_placeholder: null,
    image_category_placeholder: null,
    primary_color: null,
    secondary_color: null,
    title_color: null,
    text_color: null,
    banners: [],
    stores: [],
  },
};

export const getConfig = (state: BaseState): Configuration => state.app.config;
export const isSingleStore = (state: BaseState):
  boolean => !!state.app.config.config?.single_store_marketplace;
export const getHideCartAddress = (state: BaseState):
    boolean => !!state.app.config.config?.hide_cart_address;
export const authenticationRequired = (state: BaseState):
  string => state.app.config.config?.authentication_required || 'no';
export const getMarketPlaceCode = (state: BaseState):
  string | null => state.app.config.default_code;
export const getBanners = (type: string) => (state: BaseState):
  Banner[] | undefined => state.app.config.banners?.filter((banner) => banner.banner_type === type);

export default handleActions<AppBaseState, AbstractPayload>({
  [startAppSuccess.toString()]: produce(
    (draft: AppBaseState, { payload }) => {
      draft.config = payload;
    },
  ),
}, INITIAL_STATE);
