import first from 'lodash/first';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import { getBanners } from 'store/ducks/base';

import {
  IntroStoreSearchContainer,
  IntroStoreSearchInner,
  Container,
  Content,
  Title,
  SearchForm,
  Form,
  Input,
  ButtonSearch,
} from './IntroStoreSearch.styled';

export default function IntroStoreSearch(): React.ReactElement {
  const { openModal } = useContext(SettingsContext);

  const banners = useSelector(getBanners('featured-web'));

  return (
    <IntroStoreSearchContainer>
      <IntroStoreSearchInner {...{ img: first(banners)?.banner_image }}>
        <Container>
          <Content>
            <Title>Suas compras de mercado sem filas ou complicações.</Title>
            <SearchForm>
              <Form onClick={(): void => openModal && openModal('ADDRESS')}>
                <Input>Digite seu CEP</Input>
                <ButtonSearch>
                  Buscar
                </ButtonSearch>
              </Form>
            </SearchForm>
          </Content>
        </Container>
      </IntroStoreSearchInner>
    </IntroStoreSearchContainer>
  );
}
