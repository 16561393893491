import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getConfig } from 'store/ducks/base';

import { main } from 'ui/themes';

type ThemeType = typeof main;

export default function useThemes(): ThemeType {
  const [theme, setTheme] = useState<ThemeType>(main);

  const config = useSelector(getConfig);

  useEffect(() => {
    if (config.primary_color) {
      setTheme({ ...theme, primary: { main: config.primary_color } });
    }
  }, [config.primary_color]);

  return theme;
}
