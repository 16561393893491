import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 6rem;
    max-width: calc(100vw - 4rem);
    min-height: calc(100vh - 12rem);
    row-gap: 2rem;  
    @media ${media.tabletMin} {
        padding-top: 8rem;
    }

    @media ${media.tabletMin} {
        min-height: calc(100vh - 10rem);
    }

`;
