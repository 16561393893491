import { rem } from 'polished';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const ColorsModifiers = {
  Active: () => `
    &:before {
      content: "";
      border: 2px solid #fff;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: -3px;
      border-radius: 100%;
      box-shadow: 0 0 5px rgba(0,0,0,0.5);
    }
  `,
};

const SizeModifiers = {
  Active: () => `
    background-color: black;
    border-color: black !important;
    color: white;
  `,
};

export const Main = styled.main.attrs({
  className: 'main_content',
})`
  padding-bottom: 7rem;
  @media ${media.tabletMin} {
    padding-top: 3rem;
  }
`;

export const Section = styled.section.attrs({
  className: 'section',
})`
  @media ${media.desktopMin} {
    padding-top: ${rem('24px')};
  }
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const Detail = styled.div.attrs({
  className: 'pr_detail',
})`
.feedback-message {
  > div {
    @media ${media.tabletMin} {
      text-align: left;
    }
  }
}
`;

export const Description = styled.div.attrs({
  className: 'product_description',
})`
`;

export const ProductTitle = styled.h1.attrs({
  className: 'product_title',
})`
  font-size: ${rem(('16px'))};
  font-weight: bold;
  @media ${media.tabletMin} {
    font-size: ${rem(('24px'))};
  }
`;

export const ProductPrice = styled.div.attrs({
  className: 'product_price',
})`
`;

export const Price = styled.span.attrs({
  className: 'price',
})`
  font-size: ${rem('24px')};
  margin-right: .5rem;
`;

export const ProductInfo = styled.div.attrs({
  className: 'pr_switch_wrap',
})`
  display: flex;
  margin-bottom: 1rem !important;
  @media ${media.tabletMin} {
    align-items: center;
  }
`;

export const ProductInfoTitle = styled.h2.attrs({
  className: 'switch_lable',
})`
  display: block;
  float: none;
  font-weight: bold;
  font-size: ${rem('16px')};
  @media ${media.tabletMin} {
    float: left;
    margin-bottom: 0;
  }
`;

export const Content = styled.div.attrs({
  className: 'pr_desc',
})`
`;

export const ProductMeta = styled.ul.attrs({
  className: 'product-meta',
})`
`;

export const ProductColorItem = styled.span.attrs({
})<utils.WithModifiers>`
  ${applyStyleModifiers(ColorsModifiers)};
  position: relative;
  &:after {
    background-color: #222;
    color: white;
    content: attr(data-title);
    font-size: ${rem('12px')};
    opacity: 0;
    padding: .15rem .25rem;
    position: absolute;
    left: 50%;
    top: -2rem;
    transform: translateX(-50%);
    pointer-events: none;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

export const ProductColors = styled.div.attrs({
  className: 'product_color_switch',
})`
  ${ProductColorItem} {
    margin-right: 8px;
  }
`;

export const ProductSizeItem = styled.span.attrs({
})<utils.WithModifiers>`
  ${applyStyleModifiers(SizeModifiers)};
`;

export const ProductSizes = styled.div.attrs({
  className: 'product_size_switch',
})`
  ${ProductSizeItem} {
    margin-right: 8px;
  }
`;

export const CartAddContainer = styled.div.attrs({
  className: 'cart_extra',
})`
  align-items: center;
  background: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  @media ${media.tabletMin} {
    justify-content: flex-start;
    margin-top: 3rem;
  }
`;

export const ButtonContainer = styled.div.attrs({
  className: 'cart_btn',
})`
`;

export const Button = styled.button.attrs({
  className: 'btn btn-fill-out btn-addtocart',
})`
`;

export const SelectSize = styled.button`
  background: none;
  border: none;
  box-shadow: none;
`;

export const SelectColors = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  span {
    padding: 0 8px;
    width: auto;
  }
`;

export const Select = styled.select`
  max-width: ${rem('160px')};
  padding: 10px;
  width: 100%;
`;
