import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import SettingsContext from 'context/SettingsContext';

import {
  StoreSearch,
  InputButton,
  SearchInputGroup,
  SearchInput,
  Placeholder,
  Button,
  IconSearch,
} from './SearchButton.styled';

export default function SearchButton(): React.ReactElement {
  const { openModal } = useContext(SettingsContext);

  return (
    <StoreSearch>
      <InputButton onClick={(): void => { openModal && openModal('SEARCH'); }}>
        <SearchInputGroup>
          <SearchInput>
            <Placeholder>Encontrar produtos na loja</Placeholder>
          </SearchInput>
          <Button><IconSearch /></Button>
        </SearchInputGroup>
      </InputButton>
    </StoreSearch>
  );
}
