import { Search } from '@styled-icons/feather';
import { rem, size } from 'polished';
import styled from 'styled-components';

import { flex, media } from 'utils/themeHelper';

export const IntroStoreSearchContainer = styled.div`
  margin-top: -1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: ${rem('1600px')};
  padding-left: 1rem;
  padding-right: 1rem;
  @media ${media.tabletMin} {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media ${media.desktopMin} {
    padding-left: ${rem('40px')};
    padding-right: ${rem('40px')};
  }
`;

export const IntroStoreSearchInner = styled.div`
  background-color: #DDD;
  background-image: url(${(props: any): string => props.img});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 60px;
  ${flex('row', 'center', 'center')};
  min-height: ${rem('385px')};
  overflow: hidden;
  position: relative;
  @media ${media.desktopMax} {
    min-height: ${rem('445px')};
  }
  &:after {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
  max-width: ${rem('700px')};
  position: relative;
  z-index: 10;
`;

export const Content = styled.div.attrs({
  className: 'banner_content',
})`
  ${flex('column', 'center', 'center')};
  text-align: center;
`;

export const Title = styled.h2`
  color: white;
  font-size: ${rem('24px')} !important;
  font-weight: 800;
  line-height: 1.5;
  max-width: ${rem('700px')};
  margin-bottom: 2rem !important;
  @media ${media.tabletMin} {
    font-size: ${rem('42px')} !important;
    margin-top: -1rem;
  }
`;

export const Subtitle = styled.p`
`;

export const SearchForm = styled.div.attrs({
  className: 'product_search_form',
})`
`;

export const Form = styled.button.attrs({
  className: '',
})`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media ${media.tabletMin} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const InputGroup = styled.div.attrs({
  className: 'input-group',
})`
`;

export const Input = styled.div.attrs({
  className: 'form-control',
})`
  border-radius: 16px;
  border: none;
  font-size: ${rem('16px')};
  line-height: 2.2;
  max-width: ${rem('400px')};
  min-height: ${rem('50px')};
  padding-left: ${rem('24px')};
  text-align: left;
  &:hover, &:focus {
    height: ${rem('50px')};
  }
`;

export const ButtonSearch = styled.span.attrs({
  className: 'btn_search',
})`
  background: ${(props) => props.theme.primary.main};
  border: none;
  border-radius: 16px;
  color: white;
  font-size: ${rem('16px')};
  margin-top: 1rem;
  padding: 12px 30px;
  @media ${media.tabletMin} {
    margin-top: 0;
    margin-left: ${rem('10px')};
  }
`;

export const IconSearch = styled(Search)`
  ${size('20px', '20px')};
  stroke: #687188;
`;
