import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';

import useScrollReset from 'hooks/useScrollReset';

import { getCart } from 'store/ducks';

import {
  FixOrder, CartConfirmation, CartPayment,
  CartProcessing,
} from 'ui/screens';

export default function ShoppingCartRouter(): React.ReactElement {
  useScrollReset();
  const { items } = useSelector(getCart);
  const { path } = useRouteMatch();

  const hasItems = useMemo(() => !!items.length, [items]);

  return (
    <Switch>
      <Route path={`${path}`} exact>
        { hasItems ? <CartPayment /> : <Redirect to="/" /> }
      </Route>
      <Route path={`${path}/fix`}>
        { hasItems ? <FixOrder /> : <Redirect to="/" /> }
      </Route>
      <Route path={`${path}/confirmation`}>
        { hasItems ? <CartConfirmation /> : <Redirect to="/" /> }
      </Route>
      <Route path={`${path}/processing`}>
        <CartProcessing />
      </Route>
    </Switch>
  );
}
