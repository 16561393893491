import { AxiosPromise } from 'axios';

import { Cart, CartNotification } from 'typing/models';
import { CartRequestBody } from 'typing/request';

import { parseCartToCartRequestBody } from 'utils/parsers';

import http from './http';

export const retrieveUpdatedCart = (storeSlug: string | undefined, cart: Cart): AxiosPromise<CartRequestBody> => {
  const payload = { ...parseCartToCartRequestBody(cart), cart_items: [] };
  return http.post(`/store/${storeSlug}/client/cart/`, payload);
};

export const createCart = (storeSlug: string | undefined, cart: Cart): AxiosPromise<CartRequestBody> => http.post(`/store/${storeSlug}/client/cart/`, parseCartToCartRequestBody(cart));

export const updateCart = (storeSlug: string | undefined, cart: Cart): AxiosPromise<CartRequestBody> => http.put(`/store/${storeSlug}/client/cart/${cart.id}/`, parseCartToCartRequestBody(cart));

export const getSavedCart = (storeSlug: string | undefined, cartId: string): AxiosPromise<CartRequestBody> => http.get(`/store/${storeSlug}/client/cart/${cartId}/`);

export const buyCart = (
  storeSlug: string | undefined,
  cartId: string,
  creditCards: object,
  token: string,
): AxiosPromise<CartRequestBody> => http.post(`/store/${storeSlug}/client/cart/${cartId}/buy/`, {
  ...creditCards,
  token,
});

export const getCartAlerts = (storeSlug: string | undefined, cartId: string): AxiosPromise<CartNotification[]> => http.get(`/store/${storeSlug}/client/cart/${cartId}/alerts/`);
