import { rem } from 'polished';
import Img from 'react-cool-img';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const ProductModifiers = {
  NoMargin: () => `
    margin: 0;
  `,
};

export const ProductCardContainer = styled.div.attrs({
  className: 'item',
})<utils.WithModifiers>`
  cursor: pointer;
  margin: 0 6px;
  ${applyStyleModifiers(ProductModifiers)};

  min-width: 186px;

  @media ${media.mobileMin} {
    min-width: 175px;
  }
`;

export const CardInner = styled.div.attrs({
  className: 'product no-shadow',
})`
  border-radius: 16px;
`;

export const ImageContainer = styled.a.attrs({
  className: 'product_img',
})`
`;

export const ImageInner = styled.div.attrs({
  className: '',
})`
  padding: 0;
  text-align: center;

  height: 279px;

  @media ${media.mobileMin} {
    height: 270px;
  }
`;

export const Image = styled(Img).attrs(({ placeholder }) => ({
  className: 'product_img',
  lazy: false,
  decode: true,
  cache: true,
  retry: { count: 1 },
  error: placeholder,
  placeholder,
}))`
  max-height: ${rem('280px')};
  margin: 0 auto;
  width: auto;
  max-width: 100%;
  // border-radius: 15px 15px 0 0;
}
`;

export const Info = styled.div.attrs({
  className: 'product_info',
})`
  max-width: 188px;
  margin-top: 1rem;
`;

export const Name = styled.h6.attrs({
  className: 'product_title',
})`
`;

export const NameLink = styled.a.attrs({
  className: '',
})`
`;

export const Actions = styled.div.attrs({
  className: 'list_product_action_box',
})`
  .item_remove {
    margin-top: 1rem;
  }
`;

export const Button = styled.a.attrs({
  className: 'btn btn-block btn-add-product',
})`
  color: #28a745 !important;
  font-weight: 400;
`;

export const Quantity = styled.a.attrs({
  className: 'quantity mx-auto',
})`
`;

export const Minus = styled.input.attrs({
  className: 'minus',
})`
`;

export const Qty = styled.input.attrs({
  className: 'quantity',
})`
`;

export const Plus = styled.input.attrs({
  className: 'plus',
})`
`;
