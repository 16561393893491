import { rem, size } from 'polished';
import Img from 'react-cool-img';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const ShoppingCartModifiers = {
  show: () => `
    display: block;
  `,
};

const CartTotalModifiers = {
  Last: () => `
    color: black !important;
  `,
};

export const ShoppingCartContainer = styled.aside.attrs({
  className: 'cart_box',
})<utils.WithModifiers>`
  display: none;
  ${applyStyleModifiers(ShoppingCartModifiers)};

  .feedback-message {
    div {
      background-color: rgba(255, 50, 77, .1);
      border-radius: 8px;
      font-size: 12px;
      font-weight: normal;
      padding: 4px 8px;
      @media ${media.desktopMin} {
        font-size: 14px;
      }
    }
  }

`;

export const CartInner = styled.div.attrs({
  className: 'cart_box_inner',
})`
`;

export const CardHeader = styled.div.attrs({
  className: 'cart_header',
})`
  h5 {
    font-weight: bold;
  }
`;

export const CloseCart = styled.a.attrs({
  className: 'close_cart_box',
})`
  cursor: pointer;
  svg {
    ${size('30px')};
  }
`;

export const CartList = styled.ul.attrs({
  className: 'cart_list',
})`
`;

export const Item = styled.li.attrs({
  className: '',
})`
`;

export const Quantity = styled.div.attrs({
  className: 'item_remove',
})`
`;

export const QuantityInner = styled.div.attrs({
  className: 'quantity',
})`
`;

export const ButtonMinus = styled.a.attrs({
  className: 'minus',
  children: '-',
})`
`;

export const ButtonPlus = styled.a.attrs({
  className: 'plus',
  children: '+',
})`
`;

export const QuantityNumber = styled.input.attrs({
  className: 'quantity',
})`
`;

export const Product = styled.div.attrs({
  className: '',
})`
`;

export const ProductImage = styled(Img).attrs(({ placeholder }) => ({
  className: '',
  debounce: 200,
  cache: true,
  error: placeholder,
  placeholder,
}))`
  height: auto;
  width: ${rem('80px')};
`;

export const ProductActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CartQuantity = styled.span.attrs({
  className: 'cart_quantity',
})`
  font-weight: normal;
  strong {
    font-weight: bold;
  }
`;

export const CartAmount = styled.span.attrs({
  className: 'cart_amount',
})`
`;

export const PriceSymbole = styled.span.attrs({
  className: 'price_symbole',
})`
`;

export const CartFooter = styled.div.attrs({
  className: 'cart_footer',
})`
`;

export const CartTotal = styled.p.attrs({
  className: 'cart_total',
})<utils.WithModifiers>`
  ${applyStyleModifiers(CartTotalModifiers)};
`;

export const CartPrice = styled.span.attrs({
  className: 'cart_price',
})`
`;

export const CartButtons = styled.p.attrs({
  className: 'cart_buttons',
})`
`;

export const Button = styled.a.attrs({
  className: 'btn btn-fill-out checkout',
})`
`;

export const CartIcon = styled.img`
  height: 24;
  width: 24;
`;

export const EmptyMessage = styled.div.attrs({
  className: 'empty_message',
})`

  h2 {
    font-weight: bold;
  }

  svg {
    margin-bottom: 1rem;
    ${size('56px')};
  }

`;

export const VariantContainer = styled.p`
  margin-bottom: 8px;
`;

export const Variant = styled.span`
  font-weight: normal;
  margin-right: ${rem('8px')};
  @media ${media.tabletMin} {
    margin-right: ${rem('16px')};
  }
`;

export const ProdContent = styled.div`
  display: flex;
`;

export const Thumbnail = styled(Link)`
  img {
    float: none;
  }
`;

export const Caption = styled.div`
  width: 100%;
`;

export const Stock = styled.p`
  font-weight: normal;
  margin: 0;
  text-align: right;
`;
