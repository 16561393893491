import React, { useEffect, useState, useCallback } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import useCookiesEnabled from 'hooks/useCookiesEnabled';
import useThemes from 'hooks/useThemes';

import {
  getLoginData,
  isLoading, listStoresSuccess, selectStore, syncCartRequest,
  checkNotificationsEnabled,
  registerDeviceToken,
  getNotificationsAccepted,
  setNotificationsSituation,
} from 'store/ducks';
import { getConfig, startAppRequest } from 'store/ducks/base';

import Loading from 'ui/components/Loading';
import EnableCookies from 'ui/screens/EnableCookies';

import { START_APP } from 'utils/fetchs';

import { isSupported } from '../../firebase';
import MainRouter from '../routes/MainRouter';
import GlobalStyle from '../themes/globalStyles';
import ModalFirstAskNotification from './ModalFirstAskNotification';
import ModalNotifications from './ModalNotifications';

export default function Marketplace(): React.ReactElement {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [started, setStarted] = useState(false);
  const [firstAsk, setFirstAsk] = useState<boolean | undefined>();

  const appLoading = useSelector(isLoading(START_APP));
  const config = useSelector(getConfig);

  const mainTheme = useThemes();

  const cookiesEnabled = useCookiesEnabled();

  const profile = useSelector(getLoginData);

  const notificationsAccepted = useSelector(getNotificationsAccepted);

  const handleFirstAsk = useCallback(() => {
    try {
      const firstNotificationAsk = localStorage.getItem('@budega-mix/notification-first-ask');
      if (firstNotificationAsk !== 'rejected' && Notification.permission !== 'granted') {
        setFirstAsk(firstNotificationAsk === null || Notification.permission === 'default');
      }
    } catch (err) {
      console.error('Notifications are not supported is this browser');
    }
  }, []);
  useEffect(() => {
    if (profile !== null && profile.profile !== null) {
      handleFirstAsk();
    }
  }, [profile]);

  useEffect(() => {
    dispatch(startAppRequest());
  }, [dispatch]);

  useEffect(() => {
    if (appLoading && !started) {
      setStarted(true);
    }
  }, [appLoading]);

  useEffect(() => {
    if (started && !appLoading) {
      setLoading(false);
    }
  }, [started, appLoading]);

  useEffect(() => {
    if (config.stores.length === 1) {
      dispatch(selectStore(config.stores[0]));
      dispatch(listStoresSuccess(config.stores));
      dispatch(syncCartRequest('only_download'));
    }
  }, [config.stores]);

  useEffect(() => {
    if (isSupported()) {
      if (profile !== null && profile.profile !== null) {
        if (firstAsk === false) {
          dispatch(checkNotificationsEnabled(profile));
        } else {
          dispatch(registerDeviceToken(profile));
        }
      }
    }
  }, [profile, firstAsk]);

  if (loading) {
    return <Loading />;
  }
  if (!cookiesEnabled) {
    return <EnableCookies />;
  }

  const CustomCookiesConsent = (): JSX.Element => (
    <CookieConsent
      location="bottom"
      buttonText="Sim, aceito os cookies"
      cookieName="@budega-mix/cookies-accepted"
      style={{
        background: '#ec3f7c',
        zIndex: 9999,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
      }}
      buttonStyle={{
        color: '#292b2c',
        fontSize: '13px',
        backgroundColor: '#f7f7f7',
        borderRadius: '5px',
      }}
      expires={365}
    >
      <span
        style={{
          color: '#FFFFFF',
          fontSize: '12px',
        }}
      >
        Usaremos
        {' '}
        <b>cookies</b>
        {' '}
        para melhorar sua experiência se você continuar navegando.
        Ao clicar em
        {' '}
        <b>&quot;Sim, aceito os cookies&quot;</b>
        {' '}
        ou continuar navegando, você concorda com o uso de cookies.
      </span>
    </CookieConsent>
  );

  return (
    <ThemeProvider theme={mainTheme}>
      {notificationsAccepted === false && (
        <ModalNotifications
          onClose={(): void => {
            dispatch(setNotificationsSituation(undefined));
          }}
        />
      )}

      {firstAsk === true && (
      <ModalFirstAskNotification
        onReject={(): void => {
          dispatch(setNotificationsSituation(undefined));
          setFirstAsk(undefined);
        }}
        onAccept={(): void => {
          setFirstAsk(false);
        }}
      />
      )}
      <CustomCookiesConsent />
      <GlobalStyle />
      <Helmet>
        <title>{config.name}</title>
        <link rel="icon" type="image/png" href={config.image_fav_icon || ''} sizes="16x16" />
      </Helmet>
      <MainRouter />
    </ThemeProvider>
  );
}
