import { rem } from 'polished';
import Img from 'react-cool-img';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { flex, media } from 'utils/themeHelper';

export const CarouselCategoriesContainer = styled.section.attrs({
  className: 'section small_pb small_pt d-lg-block pb-0 cat_style1',
})`
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const Item = styled.div.attrs({
  className: 'item',
})`
  min-width: ${rem('105px')};
  padding: 0;
  @media ${media.tabletMax} {
    min-width: ${rem('120px')};
  }
`;

export const ItemInner = styled.div.attrs({
  className: 'categories_box',
})`
`;

export const ItemLink = styled(Link)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: ${rem('13px')};
  text-transform: uppercase;
  span {
    word-break: break-word;
  }
`;

export const ItemThumbnail = styled.div`
  background-color: #F7F7F7;
  border-radius: 100px;
  ${flex('row', 'center', 'center')};
  height: ${rem('80px')};
  overflow: hidden;
  width: ${rem('80px')};
  @media ${media.tabletMax} {
    height: ${rem('104px')};
    width: ${rem('104px')};
  }
`;

export const ItemImage = styled(Img).attrs(({ placeholder }) => ({
  className: 'product_img',
  debounce: 200,
  cache: true,
  error: placeholder,
  placeholder,
}))`
  margin: 0 auto;
`;

export const CarouselSlider = styled.div`
  display: flex;
  overflow: auto;
`;
