import React from 'react';
import { useSelector } from 'react-redux';
import {
  Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';

import { AUTH } from 'context/SettingsContext';

import useNavControl from 'hooks/useNavControl';
import useScrollReset from 'hooks/useScrollReset';
import useSearchParams from 'hooks/useSearchParams';

import { isLogged } from 'store/ducks';

import {
  Profile,
  Login,
  UserRegistration,
  EditProfile,
  ResetPassword,
  ChangePassword,
} from 'ui/screens';

export default function ProfileRouter(): React.ReactElement {
  const { path } = useRouteMatch();
  const [next] = useSearchParams(['next']);

  useScrollReset();

  const authorized = useSelector(isLogged);

  return (
    <Switch>
      <Route path={`${path}/login`} exact>
        {authorized ? <Redirect to={next || '/'} /> : <Login />}
      </Route>
      <Route path={`${path}/register`} exact>
        {authorized ? <Redirect to={next || '/profile'} /> : <UserRegistration />}
      </Route>
      <Route path={`${path}/change-password`}>
        {authorized ? <ChangePassword /> : <Redirect to={next || '/profile/login'} />}
      </Route>
      <Route path={`${path}/reset-password`}>
        {authorized ? <Redirect to={next || '/profile'} /> : <ResetPassword />}
      </Route>
      <Route path={`${path}/edit-profile`}>
        {authorized ? <EditProfile /> : <Redirect to={next || '/profile/login'} />}
      </Route>
      <Route path="/">
        {authorized ? <Profile /> : <Redirect to={next || '/profile/login'} />}
      </Route>
    </Switch>
  );
}
