import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import { getCartStore, getStoreBySlug, startCart } from 'store/ducks';

interface DefaultCallable {
  (): any;
}

export default function useVerifiedStoreAction(storeSlug: string, callBack: DefaultCallable)
  : DefaultCallable {
  const { warning: { activeWarning } } = useContext(SettingsContext);
  const dispatch = useDispatch();

  const currentStoreInTheCart = useSelector(getCartStore);
  const store = useSelector(getStoreBySlug(storeSlug));

  return useCallback(() => {
    if (storeSlug && !currentStoreInTheCart && store) {
      dispatch(startCart(store));
      return callBack();
    }

    if (!storeSlug || (currentStoreInTheCart && currentStoreInTheCart.slug === storeSlug)) {
      return callBack();
    }

    return activeWarning && activeWarning('INVALID_STORE', {});
  }, [callBack, activeWarning, currentStoreInTheCart, store]);
}
