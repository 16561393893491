import { ThemeProps } from 'styled-components';

export interface ThemeSpacingProps {
  [key: string]: number | null;
}

export interface AppThemeProps {
  spacing: ThemeSpacingProps;
}

export const getThemeSpacing = (size: string) => (props: ThemeProps<AppThemeProps>):
  number => props.theme.spacing[size] || 0;

export const flex = (direction: string, justify: string, align: string) => (props: ThemeProps<AppThemeProps>) => `
  align-items: ${align};
  display: flex;
  flex-direction: ${direction};
  justify-content: ${justify};
`;

const size = {
  desktopMaxWidth: '1800px',
  desktopMedWidth: '1440px',
  desktopWidth: '1360px',
  desktopMinWidth: '1280px',
  tabletMaxWidth: '992px',
  tabletMinWidth: '768px',
  mobileMaxWidth: '600px',
  mobileMinWidth: '375px',
  othersMobileMinWidth: '333px',
};

export const media = {
  desktopMax: `(min-width: ${size.desktopMaxWidth})`,
  desktopMed: `(min-width: ${size.desktopMedWidth})`,
  desktop: `(min-width: ${size.desktopWidth})`,
  desktopMin: `(min-width: ${size.desktopMinWidth})`,
  tabletMax: `(min-width: ${size.tabletMaxWidth})`,
  tabletMin: `(min-width: ${size.tabletMinWidth})`,
  mobileMax: `(min-width: ${size.mobileMaxWidth})`,
  mobileMin: `(min-width: ${size.mobileMinWidth})`,
  othersMobileMin: `(min-width: ${size.othersMobileMinWidth})`,
};
