import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';

import { getFiltersProduct } from 'services/api/products';

import {
  listFiltersProductRequest,
  listFiltersProductSuccess,
  listFiltersProductFailure, getSelectedStore, getSelectedCategory,
} from 'store/ducks';

function* getFiltersProductAsync({ payload }: Action<string>): SagaIterator {
  try {
    const store = yield select(getSelectedStore);
    const category = yield select(getSelectedCategory);

    const response = yield call(getFiltersProduct, store.slug, category);
    yield put(listFiltersProductSuccess(response));
  } catch (e) {
    yield put(listFiltersProductFailure(e));
  }
}

export default function* watchFiltersProductActions(): SagaIterator {
  yield takeEvery(listFiltersProductRequest.toString(), getFiltersProductAsync);
}
