import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 12rem);
  @media ${media.tabletMin} {
    min-height: calc(100vh - 24rem);
  }

`;

export const StepTag = styled.span`
  background-color: #f7f7f7;
  display: inline-block;
  margin: 0 auto .5rem;
  max-width: ${rem('100px')};
  padding: 6px 12px;
`;

export const InfoChange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Info = styled.p.attrs({
})`
  color: black;
  margin: 0 1rem;
  font-size: ${rem('14px')};
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const ButtonBase = styled.a`
  margin-top: auto;
  @media ${media.tabletMin} {
    align-self: center;
    max-width: ${rem('375px')};
  }
`;

export const Button = styled(ButtonBase).attrs({
  className: 'btn btn-fill-out btn-block',
})``;
