import React from 'react';
import { useParams } from 'react-router-dom';

import useUrls from 'hooks/useUrls';

import { Store } from 'typing/models';

import Breadcrumb from 'ui/components/Breadcrumb';
import Search from 'ui/components/Search';

import StoreLabel from '../StoreLabel';
import {
  StorePageHeaderContainer,
  Container,
  Row,
  Col,
  PageTitle,
  PageTitleInner,
  StoreImage,
  StoreImageContainer,
} from './StorePageHeaderCategory.styled';

interface StorePageHeaderProps {
  store?: Store | null;
}

export default function StorePageHeader({ store }: StorePageHeaderProps): React.ReactElement {
  const { productId } = useParams();
  const { getStoreUrl } = useUrls();

  return (
    <StorePageHeaderContainer>
      <Container className="container">
        <Row className="row align-items-center">
          <Col className="d-none d-lg-flex col-md-12 mb-3">
            <PageTitle>
              <StoreImageContainer to={getStoreUrl()}>
                <StoreImage src={store?.image_logo || ''} alt="product_img1" />
              </StoreImageContainer>
              <PageTitleInner to={getStoreUrl()}>
                <h1>{store?.name}</h1>
                <StoreLabel store={store} />
              </PageTitleInner>
            </PageTitle>
          </Col>
          <Col className="d-flex col-md-7 mt-4">
            <Breadcrumb store={store} />
          </Col>
          {
            !productId && (
              <Col className="col-md-5 text-center text-lg-left">
                <Search />
              </Col>
            )
          }
        </Row>
      </Container>
    </StorePageHeaderContainer>
  );
}
