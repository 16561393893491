import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import {
  takeLatest, call, put, select, take,
} from 'redux-saga/effects';

import {
  listStores, getStore, getCampaigns, getLinks,
} from 'services/api/stores';

import {
  listStoresRequest,
  listStoresFailure,
  listStoresSuccess,
  getStoreSuccess,
  getStoreFailure,
  getStoreRequest,
  listCampaignsRequest,
  listCampaignsSuccess,
  listCampaignsFailure,
  listLinksRequest,
  listLinksSuccess,
  listLinksFailure,
} from 'store/ducks/stores';

import { LOAD_AUTH_DATA } from 'utils/fetchs';

import {
  getDefaultAddress, isLoading, isLogged, isSuccess, loadAuthDataSuccess,
} from '../ducks';

function* getStoreAsync(action: Action<string>): SagaIterator {
  try {
    const loadingAuthData = yield select(isLoading(LOAD_AUTH_DATA));

    if (loadingAuthData) {
      yield take(loadAuthDataSuccess.toString());
    }

    const store = yield call(getStore, action.payload);
    yield put(getStoreSuccess(store));
  } catch (e) {
    yield put(getStoreFailure(e));
  }
}

function* getStoreCampaignsAsync(action: Action<string>): SagaIterator {
  try {
    const data = yield call(getCampaigns, action.payload);
    yield put(listCampaignsSuccess(data));
  } catch (e) {
    yield put(listCampaignsFailure(e));
  }
}

function* getStoreLinksAsync(action: Action<string>): SagaIterator {
  try {
    const data = yield call(getLinks, action.payload);
    yield put(listLinksSuccess(data));
  } catch (e) {
    yield put(listLinksFailure(e));
  }
}

function* listStoresAsync(): SagaIterator {
  try {
    const authorized = yield select(isLogged);
    const success = yield select(isSuccess(LOAD_AUTH_DATA));

    if (!authorized && !success) {
      yield take(loadAuthDataSuccess.toString());
    }

    const address = yield select(getDefaultAddress);

    const stores = yield call(listStores, address);

    yield put(listStoresSuccess(stores));
  } catch (e) {
    yield put(listStoresFailure(e));
  }
}

export default function* watchStoresActions(): SagaIterator {
  yield takeLatest(listStoresRequest.toString(), listStoresAsync);
  yield takeLatest(getStoreRequest.toString(), getStoreAsync);
  yield takeLatest(listCampaignsRequest.toString(), getStoreCampaignsAsync);
  yield takeLatest(listLinksRequest.toString(), getStoreLinksAsync);
}
