import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Img from 'assets/images/logo-colmeia.png';

import useSearchParams from 'hooks/useSearchParams';

import { getLoginData, registerClean } from 'store/ducks';

import Modal from 'ui/components/Modal';
import { PageHeader, Title } from 'ui/components/Page';

import { ModalContent, Button } from './ModalWelcome.styled';

export default function ModalWelcome(): React.ReactElement {
  const dispatch = useDispatch();

  const history = useHistory();

  const [next]: string[] = useSearchParams(['next']);

  const close = (): void => {
    dispatch(registerClean());

    if (next) {
      history.push(next);
    }
  };

  const profile = useSelector(getLoginData);

  return (
    <Modal close={close} modifiers={['ShowModal', 'ModalSmall']}>
      <ModalContent>
        <PageHeader>
          <img src={Img} />
          <Title>
            Bem-vindo!
            {profile?.profile.first_name}
          </Title>
          <p>Comece agora mesmo a realizar suas compras de mercado de qualquer lugar!</p>
        </PageHeader>
        <Button onClick={close}>Ok!</Button>
      </ModalContent>
    </Modal>
  );
}
