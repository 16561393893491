import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { getCategories } from 'services/api/categories';

import {
  listCategoriesRequest,
  listCategoriesSuccess,
  listCategoriesFailure,
} from 'store/ducks';

function* getCategoriesAsync({ payload }: Action<string>): SagaIterator {
  try {
    const response = yield call(getCategories, payload);
    yield put(listCategoriesSuccess(response));
  } catch (e) {
    yield put(listCategoriesFailure(e));
  }
}

export default function* watchCategoriesActions(): SagaIterator {
  yield takeEvery(listCategoriesRequest.toString(), getCategoriesAsync);
}
