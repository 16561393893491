import styled from 'styled-components';

export const FormGroup = styled.div.attrs({
  className: 'form-group',
})`
`;

export const Label = styled.label`
`;

export const Input = styled.input.attrs({
  className: 'form-control',
})`
`;

export const FormFooter = styled.div`
  margin-top: 4rem;
`;
