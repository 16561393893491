import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const BreadcrumbContainer = styled.ol.attrs({
  className: 'breadcrumb',
})`
  font-family: 'poppins';
  margin: 0 auto;
  @media ${media.tabletMin} {
    margin: 0;
  }
`;

export const Item = styled.li.attrs({
  className: 'breadcrumb-item',
})`
  font-size: ${rem('16px')} !important;
  & + &.breadcrumb-item:before {
    content: ">";
    font-family: 'poppins';
  }
  strong {
    color: black;
  }
  .result {
    margin-left: .5rem;
  }
`;

export const ItemLink = styled(Link)`
`;
