import { AxiosPromise } from 'axios';

import { Configuration } from 'typing/models';

import http from './http';

const appDomain = process.env.REACT_APP_DOMAIN || window.location.hostname;

export const getConfig = (): AxiosPromise<Configuration> => http.get(
  '/marketplace/auto-detect/',
  { params: { app_domain: appDomain } },
);

export const getRemoteData = (store: string, cartReferCode: string):
  AxiosPromise<Configuration> => http.get(
  `/store/${store}/backoffice/cart-refer/login/`,
  { params: { refer_code: cartReferCode } },
);
