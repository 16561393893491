import { useState, useEffect } from 'react';

export default function useCookiesEnabled(): boolean {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(navigator.cookieEnabled);
  }, [navigator]);

  return enabled;
}
