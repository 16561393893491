import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const HomeContainer = styled.div`
  padding-top: 6rem;
  padding-bottom: 7rem;
`;

export const Main = styled.main`
`;

export const ActiveOrders = styled(Link)`
  align-items: center;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0, 0.15);
  border-radius: 8px;
  color: black !important;
  display: flex;
  font-size: 16px;
  margin: 0 auto 1.5rem;
  padding: 12px 16px;
  position: relative;
  max-width: 375px;
  @media ${media.tabletMax} {
    display: none;
  }
  
  span {
    background-color: #06C167;
    margin-right: .75rem;
  }

  &:after {
    content: '>';
    display: block;
    position: absolute;
    right: 16px;
    top: 10px;
  }
  
`;

export const Button = styled.button.attrs({
  className: 'btn btn-fill-out btn-block btn-radius',
})`
  background-color: white;
  width: 100%;
  @media ${media.tabletMin} {
    width: auto;
  }
`;
