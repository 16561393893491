import firebase from 'firebase/app';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  logout,
  checkNotificationsEnabled, getLoginData,
} from 'store/ducks';

import { isSupported } from '../../../firebase';
import {
  ProfileMenuContainer,
  ListMenu,
  Item,
} from './ProfileMenu.styled';

export default function ProfileMenu(): React.ReactElement {
  const dispatch = useDispatch();

  const profile = useSelector(getLoginData);

  const isRejected = (): boolean => {
    const rejectionExpiresAt = localStorage.getItem('@budega-mix/rejected-notifications');
    const firstNotificationAsk = localStorage.getItem('@budega-mix/notification-first-ask');
    const rejectedNotifications = rejectionExpiresAt ? new Date(rejectionExpiresAt).getTime() < new Date().getTime() : true;
    return !rejectedNotifications || firstNotificationAsk === 'rejected';
  };

  const handleLogout = async () => {
    dispatch(logout());
    try {
      if (localStorage.getItem('@bodega-mix/fcmToken')) {
        const messaging = firebase.messaging();
        await messaging.deleteToken();
        localStorage.removeItem('@bodega-mix/fcmToken');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProfileMenuContainer>
      <ListMenu>
        <Item>
          <Link to="/profile/edit-profile">Meus dados</Link>
        </Item>
        <Item>
          <Link to="/orders">Pedidos</Link>
        </Item>
        {/* <Item>
          <Link to="/">Endereços</Link>
        </Item> */}
        <Item>
          <Link to="/profile/change-password">Segurança</Link>
        </Item>
        {(isRejected() && isSupported()) && (
        <Item>
          <a onClick={(): void => {
            dispatch(checkNotificationsEnabled({
              profile: profile?.profile,
              clean: true,
            }));
          }}
          >
            Ativar notificações
          </a>
        </Item>
        )}

        <Item>
          <a onClick={handleLogout}>Sair</a>
        </Item>
      </ListMenu>
    </ProfileMenuContainer>
  );
}
