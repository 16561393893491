import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLoadStore from 'hooks/useLoadStore';
import useNavControl from 'hooks/useNavControl';
import useUrls from 'hooks/useUrls';

import { getLinks, listLinksRequest } from 'store/ducks/stores';

import {
  Page,
  PageHeader,
  Container,
  Title,
  Text,
  PageBody,
  ButtonBack,
} from 'ui/components/Page';

import {
  Main,
  CardLink,
  CardHeader,
  CardBody,
  CardFooter,
} from './StoreLinks.styled';

export default function StoreLinks(): React.ReactElement {
  useNavControl();
  const { store } = useLoadStore();
  const dispatch = useDispatch();
  const { getLinkUrl } = useUrls();

  const links = useSelector(getLinks);

  useEffect(() => {
    dispatch(listLinksRequest(store?.slug));
  }, []);

  return (
    <Main>
      <Page>
        <Container modifiers="Small">
          <PageHeader>
            <ButtonBack to="/">Voltar</ButtonBack>
            <Title>Selecionados para você</Title>
            <Text>Aqui estão os links de seleção de produtos que foram enviados para você</Text>
          </PageHeader>
          <PageBody>
            {
              links.map((link) => (
                <CardLink to={getLinkUrl(link.code)}>
                  <CardHeader>
                    <div className="caption">
                      <h2>{link.title}</h2>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>
                        Enviado por:
                        {' '}
                        {`${link.store_operator?.user.first_name} ${link.store_operator?.user.last_name}`}
                        {' '}
                        -
                        {' '}
                        {link.store.name}
                      </li>
                      <li>
                        Data:
                        {' '}
                        {moment(link.expiration_date).format('DD [de] MMMM [de] YYYY')}
                      </li>
                    </ul>
                  </CardBody>
                  <CardFooter>
                    <p>
                      {link.count_items}
                      {' '}
                      produtos
                    </p>
                    <span>Ver mais</span>
                  </CardFooter>
                </CardLink>
              ))
            }
          </PageBody>
        </Container>
      </Page>
    </Main>
  );
}
