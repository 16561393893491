import { Search } from '@styled-icons/feather';
import { rem, size } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const SearchModifiers = {
  ShowCancel: () => `
    .search_btn {
      display: none;
    }
    .btn__cancel {
      display: flex;
    }
  `,
};

export const StoreSearch = styled.div.attrs({
  className: 'product_search_form search_input mx-auto',
})<utils.WithModifiers>`
  display: none;
  @media ${media.tabletMax} {
    display: block;
  }
  ${applyStyleModifiers(SearchModifiers)};
`;

export const Form = styled.form`
  display: flex;
`;

export const SearchInputGroup = styled.div.attrs({
  className: 'input-group',
})`
  border-radius: 16px;
  overflow: hidden;
`;

export const SearchInput = styled.input.attrs({
  className: 'form-control',
})`
  border: none;
  background-color: #f7f7f7;
  &:focus {
    background-color: #f7f7f7;
    height: ${rem('50px')};
  }
`;

export const Button = styled.button.attrs({
  className: 'search_btn',
})`
  background: none;
`;

export const IconSearch = styled(Search)`
  position: relative;
  ${size('24px')};
  top: -2px;
`;

export const BtnCancel = styled.a.attrs({
  className: 'btn__cancel',
})`
  display: none;
  cursor: pointer;
  color: #06C167 !important;
  align-items: center;
  padding-left: 1rem;
  justify-content: center;
  z-index: 5;
  @media ${media.tabletMax} {
    background-color: #f7f7f7;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`;
