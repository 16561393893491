import { Check } from '@styled-icons/feather';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CHECKOUT } from 'context/SettingsContext';

import useNavControl from 'hooks/useNavControl';

import {
  getCart,
  getCheckoutStatus,
  getDefaultAddress, isLoading,
  loadPaymentMethodsRequest, loadSelfShippingMethodsRequest,
  loadShippingMethodsRequest,
  setCheckoutStatus,
  syncCartRequest,
} from 'store/ducks';
import {
  getCartTotal,
  getCurrentPaymentMethod,
  getCurrentShippingMethod, getPaymentMethods,
  getShippingMethods,
  getSelfShippingMethods,
  setAddress,
  setVerifiedCart,
  setPaymentMethod,
} from 'store/ducks/cart';

import CardAddress from 'ui/components/CardAddress';
import CartReview from 'ui/components/CartReview';
import {
  Page,
  Container,
  PageHeader,
  Title,
  Panel,
  PanelHeader,
  PanelBody,
} from 'ui/components/Page';
import Payment from 'ui/components/Payment';

import { Card } from '../../../typing/models';
import { PAYMENT_TYPE_ONLINE_CREDIT_CARD } from '../../../utils/contants';
import {
  LOAD_SELF_SHIPPING_METHODS,
  LOAD_SHIPPING_METHODS,
} from '../../../utils/fetchs';
import { AddCardSchemaType } from '../../../utils/schemas';
import ShippingMethod from '../../components/ShippingMethod';
import {
  StepCheckout,
  StepItem,
  StepCompleted,
  StepContainer,
  StepButton,
  Button, ButtonDisabled,
} from './CartPayment.styled';

export default function CartPayment(): React.ReactElement {
  useNavControl(CHECKOUT);

  const history = useHistory();
  const dispatch = useDispatch();

  const [clicked, setClicked] = useState(false);
  const [clickedFinish, setClickedFinish] = useState(false);
  const total = useSelector(getCartTotal);
  const [cards, setCards] = useState<Card[]>([]);
  const totalCards = _.sumBy(cards, (card) => parseFloat(String(card.amount)));
  const methods = useSelector(getPaymentMethods);
  const currentMethod = useSelector(getCurrentPaymentMethod);
  const currentShippingMethod = useSelector(getCurrentShippingMethod);
  const shippingMethods = useSelector(getShippingMethods);
  const selfShippingMethods = useSelector(getSelfShippingMethods);
  const status = useSelector(getCheckoutStatus);
  const cart = useSelector(getCart);
  const address = useSelector(getDefaultAddress);
  const payment_type = currentMethod?.payment_type;
  const isLoadingShippingMethods:boolean | undefined = useSelector(isLoading(LOAD_SHIPPING_METHODS));
  const isLoadingSelfShippingMethods:boolean | undefined = useSelector(isLoading(LOAD_SELF_SHIPPING_METHODS));

  const onClickAddCard = (card: AddCardSchemaType): void => {
    // @ts-ignore
    const items = [...cards, card].map((cc) => {
      const newcard = {
        amount: 0,
        ...cc,
      };

      let { amount } = newcard;
      if (typeof amount === 'string') {
        amount = parseFloat(String(newcard.amount)
          .replace('R$ ', '')
          .replace('.', '')
          .replace(',', '.'));
      }

      return {
        ...newcard,
        amount,
      };
    });
    // @ts-ignore
    setCards([...items]);
  };

  const onClickRemoveCard = (index: number): void => {
    cards.splice(index, 1);
    setCards([...cards]);
  };

  const goToAddressEdition = ():
    void => { dispatch(setCheckoutStatus('ADDRESS')); };

  const gotToShippingMethod = ():
      void => {
    if (address) {
      dispatch(setAddress(address));
      dispatch(setCheckoutStatus('SHIPPING_METHOD'));
      dispatch(loadShippingMethodsRequest());
      dispatch(loadSelfShippingMethodsRequest());
    }
  };

  const goToCartEdition = ():
    void => {
    const allShippingMethods = [...shippingMethods, ...selfShippingMethods];
    const item = _.find(allShippingMethods, { identifier: currentShippingMethod?.identifier });

    if (item) {
      const options = item?.options || [];
      let canContinue = true;
      options.forEach(({ identifier }) => {
        let value = _.get(currentShippingMethod?.selected_options, identifier, undefined);
        try {
          value = !!parseInt(value);
        } catch (e) {
          console.log('goToCartEdition: Valor não é um número', e);
        }

        if (!value) {
          canContinue = false;
        }
      });
      if (canContinue) {
        dispatch(syncCartRequest());
        dispatch(setCheckoutStatus('CART'));

        if (!currentMethod) {
          dispatch(setPaymentMethod(methods[0]));
        }
      } else {
        setClicked(true);
      }
    }
  };

  const completeCheckout = (): void => {
    setClickedFinish(true);
    if (totalCards.toFixed(2) === total.toFixed(2)
      || payment_type !== PAYMENT_TYPE_ONLINE_CREDIT_CARD) {
      dispatch(setVerifiedCart(cart.id));
      console.log('completeCheckout: ', cards);
      history.push('/checkout/processing', {
        cards,
        orderId: cart.id,
      });
    }
  };

  useEffect(() => {
    const cardsProps = _.get(history.location.state, 'cards', []);
    const screenProps = _.get(history.location.state, 'screen', null);

    if (screenProps) {
      setCards(cardsProps);
      dispatch(setCheckoutStatus('CART'));
    } else {
      dispatch(setCheckoutStatus('ADDRESS'));
    }

    dispatch(loadPaymentMethodsRequest());
  }, []);

  return (
    <Page>
      <Container modifiers="Small">
        <PageHeader>
          <Title>Finalizar pedido</Title>
        </PageHeader>

        <StepCheckout>
          <StepItem>
            {
              status === 'ADDRESS' ? (
                <StepContainer>
                  <Panel>
                    <PanelHeader>
                      <h2>Endereço</h2>
                    </PanelHeader>
                    <PanelBody>
                      <CardAddress unique address={address || undefined} modifiers="Active" />
                    </PanelBody>
                  </Panel>
                  <StepButton onClick={gotToShippingMethod}>
                    Confirmar e continuar
                  </StepButton>
                </StepContainer>
              ) : (
                <StepCompleted onClick={goToAddressEdition}>
                  <Check />
                  <h2>Endereço</h2>
                  <span>Editar</span>
                </StepCompleted>
              )

            }
          </StepItem>

          {
            ['SHIPPING_METHOD', 'CART', 'CHECKOUT_COMPLETED'].includes(status || '') && (
            <StepItem>
              {
                    status === 'SHIPPING_METHOD' ? (
                      <StepContainer>
                        <Panel>
                          <PanelHeader>
                            <h2>Entrega</h2>
                          </PanelHeader>
                          <PanelBody>
                            <ShippingMethod
                              listOptions={selfShippingMethods}
                              clicked={clicked}
                              loading={!!isLoadingSelfShippingMethods}
                            />

                            {
                              !isLoadingSelfShippingMethods && (
                                <>
                                  <p>
                                    {
                                      !!isLoadingShippingMethods && 'Carregando mais informações...'
                                    }
                                  </p>
                                </>
                              )
                            }

                            {
                              !isLoadingShippingMethods && (
                              <ShippingMethod
                                listOptions={shippingMethods}
                                clicked={clicked}
                                loading={false}
                              />
                              )
                            }
                          </PanelBody>
                        </Panel>
                        <StepButton onClick={goToCartEdition}>
                          Confirmar e continuar
                        </StepButton>
                      </StepContainer>
                    ) : (
                      <StepCompleted onClick={gotToShippingMethod}>
                        <Check />
                        <h2>Entrega</h2>
                        <span>Editar</span>
                      </StepCompleted>
                    )
                  }
            </StepItem>
            )
          }

          {
            (cards || []).length > 0 && _.get(cart?.response_credit_cards, 'payment', []).map((payment) => (
              <div className="alert alert-danger" role="alert">
                { payment.message }
              </div>
            ))
          }

          {
            _.get(cart?.response_message, 'message', null) && (
              <div className="alert alert-danger" role="alert">
                { _.get(cart?.response_message, 'message', null) }
              </div>
            )
          }

          {
            ['CART', 'CHECKOUT_COMPLETED'].includes(status || '') && (
            <StepItem>
              {
                    status === 'CART' ? (
                      <StepContainer>
                        <Panel>
                          <PanelHeader>
                            <h2>Resumo do Pedido</h2>
                          </PanelHeader>
                          <PanelBody>
                            <CartReview />
                          </PanelBody>
                        </Panel>

                        <Panel>
                          <PanelHeader>
                            <h2>Forma de pagamento</h2>
                          </PanelHeader>
                          <PanelBody>
                            <Payment
                              cards={cards}
                              totalCards={totalCards}
                              setup={currentMethod?.setup}
                              clickedFinish={clickedFinish}
                              responseCreditCards={cart?.response_credit_cards}
                              onClickAddCard={onClickAddCard}
                              onClickRemoveCard={onClickRemoveCard}
                            />
                          </PanelBody>
                        </Panel>

                        {
                            !currentMethod ? (
                              <ButtonDisabled>
                                Fazer pedido
                              </ButtonDisabled>
                            ) : (
                              <Button
                                onClick={completeCheckout}
                              >
                                Fazer pedido
                              </Button>
                            )
                          }
                      </StepContainer>
                    ) : (
                      <StepCompleted onClick={goToCartEdition}>
                        <Check />
                        <h2>Resumo do Pedido</h2>
                        <span>Editar</span>
                      </StepCompleted>
                    )
                  }
            </StepItem>
            )
          }
        </StepCheckout>
      </Container>
    </Page>
  );
}
