import {
  X as IconClose,
} from '@styled-icons/feather';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Form from 'react-formal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import {
  getFiltersProduct,
  getSelectedCategory,
  getSelectedStore,
  isLoading,
  getSelectedFiltersProduct,
  setSelectedFiltersProduct, listProductsClean,
} from '../../../store/ducks';
import { LIST_FILTERS_PRODUCT } from '../../../utils/fetchs';
import { FormFooter, FormGroup } from '../../screens/EditProfile/EditProfile.styled';
import Loading from '../Loading';
import { Container, PageBody, Title } from '../Page';
import { Col, Row } from '../ProductDetail/ProductDetail.styled';
import {
  FilterProductsContainer,
  Checkbox,
  CheckForm,
  FilterTitle,
  FilterProductsPage,
  FilterProductsPageHeader, CloseCart, FilterProductsPageBody,
} from './FilterProducts.styled';

export default function FilterProducts(): React.ReactElement {
  const { isVisibleModal, openModal, closeModal } = useContext(SettingsContext);
  const dispatch = useDispatch();

  const store = useSelector(getSelectedStore);
  const storeSlug = useMemo(() => store?.slug || 'undefined', []);
  const currentSelectedFiltersProduct = useSelector(getSelectedFiltersProduct);
  const modifiers = (isVisibleModal && isVisibleModal('MODAL_FILTERS_PRODUCT')) ? ['show'] : [];
  const selectedCategory = useSelector(getSelectedCategory);
  const [promotion, setPromotion] = useState<boolean>(false);
  const itemsFilters = useSelector(getFiltersProduct(storeSlug));
  const loadingFilters = useSelector(isLoading(LIST_FILTERS_PRODUCT));
  const [filters, setFilters] = useState<object>(currentSelectedFiltersProduct || {});
  const [searchValue, setSearchValue] = useState(_.get(currentSelectedFiltersProduct, 'name', ''));
  const [order, setOrder] = useState(_.get(currentSelectedFiltersProduct, 'order', '-release_date'));

  const onApplyFilter = () => {
    const params = {
      ...filters,
      promotion,
    };

    const categoryId = selectedCategory?.id;

    if (!filters['category_ids[]']) {
      params['category_ids[]'] = [categoryId];
    }

    dispatch(setSelectedFiltersProduct({ ...params }));

    setTimeout(() => {
      if (closeModal) {
        dispatch(listProductsClean(categoryId));
        closeModal();
      }
    }, 300);
  };

  useEffect(() => {
    if (isVisibleModal && isVisibleModal('MODAL_FILTERS_PRODUCT')) {
      setPromotion(_.get(currentSelectedFiltersProduct, 'promotion', ''));
      const params = currentSelectedFiltersProduct || {};
      setSearchValue(_.get(params, 'name', ''));
      setFilters({ ...params });

      setTimeout(() => {
        // @ts-ignore
        document.getElementById('search_name_filter').value = _.get(params, 'name', '');
      }, 300);
    }
  }, [isVisibleModal]);

  useEffect(() => {
    setFilters({
      ...filters,
      name: searchValue,
    });
  }, [searchValue]);

  useEffect(() => {
    setFilters({
      ...filters,
      order,
    });
  }, [order]);

  const selectFilter = (attribute, item) => {
    if (!filters[attribute]) {
      setFilters({
        ...filters,
        [attribute]: [item.code],
      });

      return;
    }

    const index = filters[attribute].indexOf(item.code);

    if (index === -1) {
      setFilters({
        ...filters,
        [attribute]: [...filters[attribute], item.code],
      });
    } else {
      const values: any = [...filters[attribute]];
      values.splice(index, 1);
      setFilters({
        ...filters,
        [attribute]: [...values],
      });
    }
  };

  const handleChange = (event: any): void => {
    setSearchValue(event.target.value);
  };

  return (
    <FilterProductsContainer modifiers={modifiers}>
      <FilterProductsPage>
        <Container modifiers="XSmall">
          <FilterProductsPageHeader modifiers="Center">
            <div />
            <Title>Filtros</Title>
            <CloseCart onClick={closeModal}><IconClose /></CloseCart>
          </FilterProductsPageHeader>
          <FilterProductsPageBody>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              {
                loadingFilters && !itemsFilters.length && (
                  <Loading />
                )
              }

              <Form autoComplete="off">

                <FormGroup>
                  <Form.Field
                    name="search_name_filter"
                    id="search_name_filter"
                    onChange={handleChange}
                    value={searchValue}
                    className="form-control"
                    placeholder="Pesquisar por nome ou código"
                  />
                  <Form.Message for="search_name_filter" />
                </FormGroup>
                <Row className="row">
                  <Col className="col-12 mb-1 mt-1">
                    <FilterTitle>Ordenar</FilterTitle>
                  </Col>
                </Row>
                <FormGroup>
                  <Form.Field
                    as="select"
                    name="order"
                    id="order"
                    className="form-control"
                    onChange={(e): void => {
                      setOrder(e.target.value);
                    }}
                  >
                    <option value="-release_date">Mais recentes</option>
                    <option value="release_date">Mais antigos</option>
                    <option value="-price">Maior preço</option>
                    <option value="price">Menor preço</option>
                    <option value="-stock">Maior estoque</option>
                    <option value="stock">Menor estoque</option>
                  </Form.Field>
                </FormGroup>
                <Form.Message for="order" />
                {
                  itemsFilters.map((attribute) => (
                    <div key={attribute.code}>
                      <Row className="row">
                        <Col className="col-12">
                          <FilterTitle>{attribute.description}</FilterTitle>
                        </Col>
                      </Row>
                      <Row className="row mb-2">
                        {
                            attribute.values.map((attr) => {
                              const items = _.get(filters, attribute.code, []);
                              const checked = items.indexOf(attr.code) > -1;

                              return (
                                <Col className="col-4" key={attr.code}>
                                  <CheckForm>
                                    <Checkbox>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name={attr.code}
                                        id={attr.code}
                                        checked={checked}
                                        onChange={({ target }):
                                          void => {
                                          selectFilter(attribute.code, attr);
                                        }}
                                      />
                                      <label className="form-check-label" htmlFor={attr.code}>
                                        <span style={{ fontSize: 12 }}>{attr.description}</span>
                                      </label>
                                    </Checkbox>
                                  </CheckForm>
                                </Col>
                              );
                            })
                          }
                      </Row>
                    </div>
                  ))
                }

                <Row className="row">
                  <Col className="col-12 mb-1 mt-1">
                    <FilterTitle>PROMOÇÃO</FilterTitle>
                  </Col>
                </Row>

                <CheckForm>
                  <Checkbox>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="promotion"
                      id="promotion"
                      checked={promotion}
                      onChange={({ target }):
                        void => {
                        setPromotion(!promotion);
                      }}
                    />
                    <label className="form-check-label" htmlFor="promotion">
                      <span>Somente produtos em promoção</span>
                    </label>
                  </Checkbox>
                </CheckForm>
              </Form>
            </PerfectScrollbar>
          </FilterProductsPageBody>
          <FormFooter>
            <FormGroup>
              <Form.Submit
                type="button"
                onClick={onApplyFilter}
                className={classNames({
                  'btn btn-fill-out btn-block': true,
                })}
                style={{
                  left: 0,
                  width: '100%',
                  bottom: 0,
                  position: 'absolute',
                }}
              >
                APLICAR FILTROS
              </Form.Submit>
            </FormGroup>
          </FormFooter>
        </Container>
      </FilterProductsPage>
    </FilterProductsContainer>
  );
}
