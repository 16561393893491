import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedStore } from 'store/ducks';
import { isSingleStore } from 'store/ducks/base';

import { Category, Product } from 'typing/models';

export default function useUrls(): any {
  const singleStore = useSelector(isSingleStore);
  const store = useSelector(getSelectedStore);

  const [prefix, setPrefix] = useState('');

  useEffect(() => {
    setPrefix(singleStore ? '' : `/store/${store?.slug}`);
  }, [store, singleStore]);

  const getSearchUrl = useCallback((category: Category, child?: Category) => {
    if (!child) {
      return `${prefix}/search?categoryId=${category.id}`;
    }

    return `${prefix}/search?categoryId=${category.id}&childId=${child.id}`;
  }, [prefix]);

  const getProductUrl = useCallback((product: Product) => {
    if (singleStore) {
      return `${prefix}/product/${product.id}`;
    }

    return prefix;
  }, [prefix]);

  const getLinksUrl = useCallback(() => {
    if (singleStore) {
      return '/links';
    }

    return `${prefix}/links`;
  }, [prefix]);

  const getOrdersUrl = useCallback(() => {
    if (singleStore) {
      return '/orders';
    }

    return `${prefix}/orders`;
  }, [prefix]);

  const getLinkUrl = useCallback((linkedCode: string) => {
    if (singleStore) {
      return `/link/${linkedCode}`;
    }

    return `${prefix}/link/${linkedCode}`;
  }, [prefix]);

  const getStoreUrl = useCallback(() => prefix, [prefix]);

  const getCampaignUrl = useCallback((campaignId: number) => `${prefix}/campaign/${campaignId}/`, []);

  return {
    getSearchUrl, getCampaignUrl, getProductUrl, getStoreUrl, getLinksUrl, getLinkUrl, getOrdersUrl,
  };
}
