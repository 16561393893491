import { Trash } from '@styled-icons/feather';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { getMaskCard, imageFromNumber } from 'utils/card';

import { getCartTotal } from '../../../../store/ducks/cart';
import { Card } from '../../../../typing/models';
import { numberToMoney } from '../../../../utils/monetary';
import Table from '../../Table';

interface CardListProps {
  cards?: Card[];
  responseCreditCards?: object | null,
  clickedFinish: boolean,
  onClickRemoveCard(index: number): void,
}

export default ({
  cards, onClickRemoveCard, responseCreditCards, clickedFinish,
}: CardListProps): React.ReactElement => {
  const total = useSelector(getCartTotal);
  const totalCards = _.sumBy(cards, (card) => parseFloat(String(card.amount)));
  const creditCards = _.get(responseCreditCards, 'credit_cards', []);

  return (
    <div>
      <h2>Cartões</h2>

      {
        !(cards || []).length && (
          <div className="alert alert-primary" role="alert">
            Nenhum cartão adicionado.
          </div>
        )
      }

      {
        !!(cards || []).length && (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.Heading>
                  Cartão
                </Table.Heading>
                <Table.Heading>
                  <div className="text-right">
                    Valor
                  </div>
                </Table.Heading>
                <Table.Heading>
                  #
                </Table.Heading>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                (cards || []).map((card, index) => {
                  const final = getMaskCard(card.card_number);
                  const info = _.find(creditCards, { mask: final });
                  const error = info && info.status === 'error';

                  return (
                    <>
                      <Table.Row key={index}>
                        <Table.Item>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                          >
                            <img
                              src={imageFromNumber(card.card_number)}
                              style={{
                                width: 40,
                                height: 35,
                                marginRight: 16,
                                alignSelf: 'flex-start',
                              }}
                            />
                            <div>
                              <span>
                                {
                                info && (
                                  <>
                                    <span className={`badge badge-${error ? 'danger' : 'success'}`}>
                                      {error ? 'Não Autorizado' : 'Autorizado'}
                                    </span>
                                    &nbsp;
                                  </>
                                )
                              }
                                Final
                                {' '}
                              </span>
                              <span style={{ fontWeight: 600 }}>{final}</span>
                            </div>
                          </div>
                        </Table.Item>
                        <Table.Item>
                          <div className="text-right">
                            {numberToMoney(card.amount)}
                          </div>
                        </Table.Item>
                        <Table.Item>
                          <Trash
                            style={{
                              width: 20, height: 20, cursor: 'pointer',
                            }}
                            onClick={() => {
                              onClickRemoveCard(index);
                            }}
                          />
                        </Table.Item>
                      </Table.Row>
                      {
                        info && error && (
                          <Table.Row>
                            <td colSpan={3}>
                              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Motivo: </strong>
                                {' '}
                                { info.message }
                              </div>
                            </td>
                          </Table.Row>
                        )
                      }
                    </>
                  );
                })
            }
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Heading>Total pago</Table.Heading>
                <Table.Item>
                  <div className="text-right">
                    {numberToMoney(totalCards)}
                  </div>
                </Table.Item>
                <Table.Item />
              </Table.Row>
              <Table.Row>
                <Table.Heading>Total restante</Table.Heading>
                <Table.Item>
                  <div className="text-right">
                    {numberToMoney(total - totalCards)}
                  </div>
                </Table.Item>
                <Table.Item />
              </Table.Row>
              <Table.Row>
                <Table.Heading>Total do pedido</Table.Heading>
                <Table.Item>
                  <div className="text-right">
                    {numberToMoney(total)}
                  </div>
                </Table.Item>
                <Table.Item />
              </Table.Row>
            </Table.Footer>
          </Table>
        )
      }
      {
        clickedFinish && total > totalCards && (
          <div className="alert alert-warning" role="alert">
            Complete o valor total do pagamento para continuar.
          </div>
        )
      }

      {
        clickedFinish && totalCards > total && (
          <div className="alert alert-warning" role="alert">
            Valor dos cartões ultrapassou o total do pedido. Corrija para continuar.
          </div>
        )
      }
    </div>
  );
};
