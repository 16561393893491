import produce from 'immer';
import { createActions, handleActions } from 'redux-actions';

import { Order } from 'typing/models';
import { AbstractPayload, BaseState, OrdersState } from 'typing/store';

import { LIST_ORDERS, GET_ORDER_DETAIL } from 'utils/fetchs';

import { fetchStateGenerator } from './checkFetchReducerCreator';

export const {
  listOrdersRequest,
  listOrdersSuccess,
  listOrdersFailure,
  listOrdersClean,
  getOrderDetailRequest,
  getOrderDetailSuccess,
  getOrderDetailFailure,
  getOrderDetailClean,
  selectOrder,
} = createActions(
  {},
  ...fetchStateGenerator(LIST_ORDERS),
  ...fetchStateGenerator(GET_ORDER_DETAIL),
  'SELECT_ORDER',
  { prefix: 'ORDERS' },
);

const INITIAL_STATE = {
  list: {
    data: [],
  },
  detail: null,
};

const PENDING_STATUS = ['pending', 'received', 'confirmed'];
const COMPLETED_STATUS = ['completed', 'cancelled'];

export const getTotalPrice = (order: Order | null): number => (order ? order.cart_items?.reduce(
  (total, item) => total + item.total, 0,
) : 0);

export const getSelectedOrder = (state: BaseState): Order | null => state.orders.detail;

export const getOrders = (state: BaseState): Order[] => state.orders.list.data;

export const getCompletedOrders = (state: BaseState):
  Order[] => state.orders.list.data.filter((order) => order.state && COMPLETED_STATUS.includes(order.state));

export const getPendingOrders = (state: BaseState):
  Order[] => state.orders.list.data.filter((order) => order.state && PENDING_STATUS.includes(order.state));

export const getPendingOrdersQty = (state: BaseState): number => getPendingOrders(state).length;

export const getOrderById = (orderId: string | undefined) => (state: BaseState):
  Order | undefined => state.orders.list.data.find((order) => order.id === orderId);

export default handleActions<OrdersState, AbstractPayload>({
  [listOrdersSuccess.toString()]: produce((draft: OrdersState, { payload }) => {
    draft.list.data = payload;
  }),
  [getOrderDetailSuccess.toString()]: produce((draft: OrdersState, { payload }) => {
    draft.detail = payload;
  }),
  [getOrderDetailClean.toString()]: produce((draft: OrdersState) => {
    draft.detail = null;
  }),
  [selectOrder.toString()]: produce((draft: OrdersState, { payload }) => {
    draft.detail = payload;
  }),
}, INITIAL_STATE);
