import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getConfig, getMarketPlaceCode } from 'store/ducks/base';

import { getProductImageUrl } from 'utils/image';

import { Image } from './ProductImage.styled';

interface ProductImageProps {
  productId?: number | string;
}

export default function ProductImage({ productId }: ProductImageProps): React.ReactElement | null {
  const { storeSlug } = useParams();

  const marketplace = useSelector(getMarketPlaceCode);
  const { image_product_placeholder: placeholder } = useSelector(getConfig);

  const [source, setSource] = useState('');

  useEffect(() => {
    if (productId) {
      setSource(getProductImageUrl(storeSlug, productId, marketplace));
    }

    return () => setSource('');
  }, [storeSlug, productId, marketplace]);

  return (
    productId ? (
      <Image src={source} {...{ placeholder: placeholder ?? undefined, cleanCache: true }} />
    ) : null
  );
}
