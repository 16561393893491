import { ExternalLink } from '@styled-icons/feather';
import React from 'react';
import { useSelector } from 'react-redux';

import Img from 'assets/images/bell.png';

import { getLoginData } from 'store/ducks';

import Modal from 'ui/components/Modal';
import { PageHeader, Title } from 'ui/components/Page';

import { getProxySettingsLocation } from 'utils/url';

import { ModalContent, Button, OutlinedButton } from './ModalNotifications.styled';

export default function ModalNotifications(props: { onClose: () => void }): React.ReactElement {
  const { onClose } = props;

  const profile = useSelector(getLoginData);

  const handleRejectPush = (): void => {
    const timeout = 1000 * 60 * 60 * 24 * 7; // 7 days
    const reAskDate = new Date(Date.now() + timeout);
    localStorage.setItem('@budega-mix/rejected-notifications', reAskDate.toISOString());
    onClose();
  };

  const handleAcceptPush = (): void => {
    try {
      Notification.requestPermission();
      window.location.reload();
    } catch (err) {
      console.error('Notifications are not supported is this browser');
    }
  };

  return (
    <Modal close={onClose} modifiers={['ShowModal', 'ModalSmall']}>
      <ModalContent>
        <PageHeader>
          <Title>Volta aqui, fique por dentro das nossas novidades!</Title>
          <img height={150} src={Img} alt="Sino de notificações" />
          <p>
            <b>{profile?.profile.first_name}</b>
            , ative as notificações em seu navegador para receber as
            nossas novidades e descontos exclusivos para você.
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span>
              É rapidinho,
              {' '}
              <a href={getProxySettingsLocation()} target="_blank" rel=" noreferrer">
                veja aqui como ativar
                {' '}
                <ExternalLink size={16} />
              </a>
            </span>
          </div>
        </PageHeader>
        <OutlinedButton onClick={handleRejectPush}>Não, obrigado</OutlinedButton>
        <Button onClick={handleAcceptPush}>Ativei, concluir</Button>
      </ModalContent>
    </Modal>
  );
}
