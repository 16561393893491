import styled from 'styled-components';

export const Quantity = styled.div.attrs({
  className: 'item_remove',
})`
  float: none;
  margin: 0;
`;

export const QuantityInner = styled.div.attrs({
  className: 'quantity',
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonMinus = styled.a.attrs({
  className: 'minus',
  children: '-',
})`
  font-family: sans-serif;
`;

export const ButtonPlus = styled.a.attrs({
  className: 'plus',
})`
  font-family: sans-serif;
`;

export const QuantityNumber = styled.input.attrs({
  className: 'quantity',
})`
  border: 1px solid #DDD;
  display: block;
  font-family: sans-serif;
  text-align: center;
  width: 40px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
