export const cepMask = (cpfValue: string): string => cpfValue
  .replace(/\D/g, '')
  .replace(/(\d{5})(\d)/, '$1-$2')
  .substring(0, 9);

export const cpfMask = (cpfValue: string): string => cpfValue
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const cnpjMask = (cnpjValue: string): string => cnpjValue
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1/$2')
  .replace(/(\d{4})(\d)/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

export const sanitizeString = (str: string): string => str
  .replace(/[^a-zA-Z0-9]/g, '');

export const onlyNumber = (someValue: string): string => someValue.replace(/[^0-9]+/g, '');

export const phoneNumberMask = (cpfValue: string): string => cpfValue
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '($1) $2')
  .replace(/(\d{5})(\d)/, '$1-$2')
  .replace(/(-\d{4})\d+?$/, '$1');

export const dynamicUsernameMask = (username: string): string => {
  const sanitizedUsername = sanitizeString(username);
  if (sanitizedUsername.length > 11) {
    return cnpjMask(sanitizedUsername);
  }
  return cpfMask(sanitizedUsername);
};
