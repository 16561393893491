import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const RecommendStoreContainer = styled.section.attrs({
  className: 'section small_pt',
})`
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const Row = styled.div`
  flex-wrap: nowrap;
  overflow: auto;
  padding-left: 1rem;
`;

export const Col = styled.div`
  padding-left: 0;
  @media ${media.tabletMin} {
    padding-left: 15px;
  }
`;
