import React, { useEffect, useState } from 'react';

import { Store } from 'typing/models';

import { numberToMoney } from 'utils/monetary';

interface StoreLabelProps {
  store?: Store | null;
}

export default function StoreLabel({ store }: StoreLabelProps): React.ReactElement {
  const [addressLabelList, setAddressLabelList] = useState<string[]>([]);

  useEffect(() => {
    let labelList: string[] = [];

    if (store?.address?.district) {
      labelList.push(store.address?.district);
    }

    if (store?.distance) {
      labelList.push(`${(store.distance / 1000).toFixed(1)}km`);
    }

    const default_delivery_fee = store?.default_delivery_fee || 0;
    if (default_delivery_fee > 0) {
      labelList.push(numberToMoney(store?.default_delivery_fee || 0));
    }

    if (store?.config?.description_for_home) {
      labelList = [];
      labelList.push(store.config.description_for_home);
    }

    setAddressLabelList(labelList);
  }, [store]);

  return (
    <p className="m-0">
      {addressLabelList.join(' · ')}
    </p>
  );
}
