import { AxiosPromise } from 'axios';
import axiosRetry from 'axios-retry';

import {
  Campaign, Link, PaymentType, ShippingMethod, Store,
} from 'typing/models';

import http from './http';

interface LatLng {
  latitude: number;
  longitude: number;
}

export const listStores = (location?: LatLng | null): AxiosPromise<Store[]> => {
  const params: any = {};

  if (location) {
    params.latitude = location.latitude;
    params.longitude = location.longitude;
  }

  return http.get('/store/', { params });
};

export const getStore = (slug: string): AxiosPromise<Store> => http.get(`/store/${slug}`);
export const getCampaigns = (slug: string): AxiosPromise<Campaign[]> => http.get(`/store/${slug}/client/campaign/`);
export const getLinks = (slug: string): AxiosPromise<Link[]> => http.get(`/store/${slug}/client/selected-products/`);

export const getPaymentMethods = (slug: string, cartId: string | undefined):
  AxiosPromise<PaymentType[]> => http.get(`/store/${slug}/client/cart/${cartId}/payment-types/`);

export const getShippingMethods = (slug: string | undefined, cartId: string):
    AxiosPromise<ShippingMethod[]> => {
  axiosRetry(http, { retries: 1 });
  return http.get(`/store/${slug}/client/cart/${cartId}/shipping_methods/`);
};

export const getSelfShippingMethods = (slug: string | undefined, cartId: string):
    AxiosPromise<ShippingMethod[]> => {
  axiosRetry(http, { retries: 1 });
  return http.get(`/store/${slug}/client/cart/${cartId}/self_shipping_methods/`);
};
