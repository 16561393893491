import styled from 'styled-components';

export const TableResponsive = styled.table.attrs({
  className: 'table',
})`
`;

export const TableContainer = styled.div`
`;

export const Thead = styled.thead`
`;

export const Tbody = styled.tbody`
`;

export const Tfoot = styled.tfoot`
`;

export const Tr = styled.tr`
`;

export const Th = styled.th`
  .text-right {
    min-width: 70px;
  }
`;

export const Td = styled.td`
  vertical-align: middle !important;
  .text-right {
    min-width: 70px;
  }
`;
