import styled from 'styled-components';

export const LoadingContainer = styled.div.attrs({
  className: 'preloader',
})`
`;

export const Ellipsis = styled.div.attrs({
  className: 'lds-ellipsis',
})`
`;
