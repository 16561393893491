import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { getFeaturedCategories } from 'services/api/categories';

import {
  listSideMenuCategoriesRequest,
  listSideMenuCategoriesSuccess,
  listSideMenuCategoriesFailure,
} from 'store/ducks';

function* getSideMenuCategoriesAsync({ payload }: Action<string>): SagaIterator {
  try {
    const response = yield call(getFeaturedCategories, payload);
    yield put(listSideMenuCategoriesSuccess(response));
  } catch (e) {
    yield put(listSideMenuCategoriesFailure(e));
  }
}

export default function* watchSideMenuCategoriesActions(): SagaIterator {
  yield takeEvery(listSideMenuCategoriesRequest.toString(), getSideMenuCategoriesAsync);
}
