import {
  ShoppingBag,
  X as IconClose,
} from '@styled-icons/feather';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import Form from 'react-formal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import { getFiltersProduct, getSelectedStore, isLoading } from '../../../store/ducks';
import { Category, Filter } from '../../../typing/models';
import { FiltersProductState } from '../../../typing/store';
import { LIST_FILTERS_PRODUCT } from '../../../utils/fetchs';
import { FormFooter, FormGroup } from '../../screens/EditProfile/EditProfile.styled';
import FilterProducts from '../FilterProducts';
import Loading from '../Loading';
import { Container, PageBody, Title } from '../Page';
import { Col, Row } from '../ProductDetail/ProductDetail.styled';
import {
  ModalCategoriesContainer,
  CloseCart,
  Checkbox,
  CheckForm,
  FilterProductsPage,
  FilterProductsPageHeader,
} from './ModalCategories.styled';

export default function ModalCategories(): React.ReactElement {
  const { isVisibleModal, closeModal } = useContext(SettingsContext);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const store = useSelector(getSelectedStore);
  const storeSlug = useMemo(() => store?.slug || 'undefined', []);

  const modifiers = (isVisibleModal && isVisibleModal('MODAL_CATEGORIES')) ? ['show'] : [];

  const itemsFilters = useSelector(getFiltersProduct(storeSlug));
  const loadingFilters = useSelector(isLoading(LIST_FILTERS_PRODUCT));

  const closeCart = () => {};

  const selectCategory = (category) => {
    const index = selectedCategories.indexOf(category.code);
    if (index === -1) {
      setSelectedCategories([...selectedCategories, category.code]);
    } else {
      selectedCategories.splice(index, 1);
      setSelectedCategories([...selectedCategories]);
    }
  };

  const attr_categories = _.filter(itemsFilters, { code: 'category_ids[]' });
  let parents: Filter[] = [];
  let all_categories: Filter[] = [];

  if (attr_categories.length) {
    all_categories = attr_categories[0].values;
    parents = _.filter(all_categories, (value) => value.parent_code === null);
  }

  const getItemCheckCategory = (category, checked) => (
    <CheckForm>
      <Checkbox>
        <input
          id={category.code}
          name={category.code}
          className="form-check-input"
          type="checkbox"
          value={category.code}
          checked={checked}
          onChange={({ target }):
              void => { selectCategory(category); }}
        />
        <label className="form-check-label" htmlFor={category.code}>
          <span>{category.description}</span>
        </label>
      </Checkbox>
    </CheckForm>
  );

  return (
    <ModalCategoriesContainer modifiers={modifiers}>
      <FilterProductsPage>
        <Container modifiers="XSmall">
          <FilterProductsPageHeader>
            <Title>Categorias</Title>
            <CloseCart onClick={closeModal}><IconClose /></CloseCart>
          </FilterProductsPageHeader>
          <PageBody style={{
            height: '80vh',
            overflow: 'auto',
          }}
          >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              {
                loadingFilters && !parents.length && (
                  <Loading />
                )
              }

              <Form>
                <FormGroup>
                  <Form.Field
                    name="first_name"
                    className="form-control"
                    placeholder="Buscar categorias"
                  />
                  <Form.Message for="first_name" />
                </FormGroup>

                {
                  parents.map((category) => {
                    const checked = selectedCategories.indexOf(category.code) > -1;
                    const children = _.filter(all_categories, { parent_code: category.code });

                    return (
                      <Row className="row mb-2" key={category.code}>
                        <Col className="col-12">
                          { getItemCheckCategory(category, checked) }
                        </Col>

                        <Row className="row">
                          {
                            children.map((children_category) => {
                              const checked_children = selectedCategories.indexOf(children_category.code) > -1;
                              return (
                                <Col className="col-12 ml-5" key={children_category.code}>
                                  { getItemCheckCategory(children_category, checked_children) }
                                </Col>
                              );
                            })
                          }
                        </Row>
                      </Row>
                    );
                  })
                }
              </Form>
            </PerfectScrollbar>
          </PageBody>
          <FormFooter>
            <FormGroup>
              <Form.Submit
                type="button"
                className={classNames({
                  'btn btn-fill-out': true,
                })}
                style={{
                  left: 0,
                  width: '100%',
                  bottom: 10,
                  position: 'absolute',
                }}
              >
                SELECIONAR
              </Form.Submit>
            </FormGroup>
          </FormFooter>
        </Container>
      </FilterProductsPage>
    </ModalCategoriesContainer>
  );
}
