import { rem, size } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { flex } from 'utils/themeHelper';

const ItemModifiers = {
  Active: ({ theme }: any) => `
      a {
        color: ${theme.primary.main};
      }
      
      svg {
        stroke: ${theme.primary.main};
      }
    `,
};

const ButtonCartModifiers = {
  Show: () => `
    display: block;
  `,
};

const ButtonCartCountModifiers = {
  Alert: () => `
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: pulse 1s ease infinite;
    border-radius: 50%;
    border: 4px double white;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    60% {
      transform: scale(1.3);
      opacity: 0.4;
    }
    100% {
      transform: scale(1.4);
      opacity: 0;
    }
  }
  `,
};

export const AppNavTabsContainer = styled.header.attrs({
  className: 'header_wrap fixed-bottom header_with_topbar bottom__navigation',
})`
`;

export const NavInner = styled.div.attrs({
  className: 'bottom_header dark_skin main_menu_uppercase',
})`
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const NavBar = styled.nav.attrs({
  className: 'navbar navbar-expand-lg',
})`
`;

export const NavList = styled.ul.attrs({
  className: 'navigation__items row list-unstyled',
})`
`;

export const Item = styled.li.attrs({
  className: 'col',
})<utils.WithModifiers>`
  ${applyStyleModifiers(ItemModifiers)};
`;

export const ItemLink = styled(Link).attrs({
  className: '',
})`
  font-size: ${rem('12px')} !important;
  ${flex('column', 'center', 'center')};
  position: relative;

  svg {
    stroke-width: 2px;
    ${size('24px')};
  }

`;

export const ButtonCartContainer = styled.div.attrs({
  className: 'btn_cart_container',
})<utils.WithModifiers>`
  bottom: 65px;
  ${applyStyleModifiers(ButtonCartModifiers)};
`;

export const ButtonCart = styled.a.attrs({
  className: 'btn btn_cart_mobile',
})`
`;

export const ButtonCartLink = styled(Link).attrs({
  className: 'btn btn_cart_mobile',
})`
  background-color: #F09253 !important;
  &:after {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #F09253;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ButtonCartCount = styled.span.attrs({
  className: 'cart_count',
})<utils.WithModifiers>`
  background-color: white !important;
  color: ${(props) => props.theme.primary.main} !important;
  font-weight: 400;
  position: relative;
  ${applyStyleModifiers(ButtonCartCountModifiers)};
`;

export const ButtonLabel = styled.span.attrs({
  className: 'btn_label',
})`
`;

export const ButtonAmount = styled.span.attrs({
  className: 'amount',
})`
  font-weight: 400;
`;

export const Symbol = styled.span.attrs({
  className: 'currency_symbol',
})`
`;

export const CartCount = styled.span.attrs({
  className: 'cart_count',
})`
  left: auto;
  position: absolute;
  right: 16px;
  top: 5px;
`;
