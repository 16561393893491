import { rem } from 'polished';
import Img from 'react-cool-img';
import styled from 'styled-components';

import {
  Page,
  Container,
  Panel,
} from 'ui/components/Page';

import { media } from 'utils/themeHelper';

export const PageOrder = styled(Page)`
  background-color: #f7f7f7;
  min-height: 100vh;
  padding-top: 6.5rem;
  padding-bottom: 9rem;
  @media ${media.tabletMin} {
    background-color: white;
    padding-top: 6rem;
  }
`;

export const ProductVariants = styled.div`
  display: flex;
  flex-direction: row;
  
  column-gap: 1rem;
  padding: 1rem 0;
`;

export const VariantTag = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333333;
  padding: 8px 12px;

  @media ${media.tabletMin} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const VariantTagTotal = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 10px;
  color: #333333;
  padding: 8px 12px;

  @media ${media.tabletMin} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PageOrderContainer = styled(Container)`
  @media ${media.tabletMin} {
    background-color: #f7f7f7;
    border-radius: 30px;
    max-width: ${rem('740px')};
    padding: 3rem;
  }
`;

export const SmallProductImage = styled(Img).attrs(({ placeholder }) => ({
  className: '',
  debounce: 200,
  cache: true,
  error: placeholder,
  placeholder,
}))`
  height: auto;
  padding: ${rem('10px')};
  width: ${rem('100px')};
  border-radius: ${rem('20px')};
`;

export const OrderConfirmation = styled(Panel)`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${media.tabletMin} {
    margin-bottom: 2rem;
  }
`;

export const PanelOrder = styled(Panel)`
  background-color: white;
  @media ${media.tabletMin} {
    margin-bottom: 2rem;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    font-size: ${rem('18px')};
  }
  p {
    font-size: ${rem('16px')};
    margin-bottom: 0;
  }
  &:not(last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const ItemFooter = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PanelButton = styled.button.attrs({
  className: 'btn btn-border-fill btn-radius',
})`
`;

export const ProductQty = styled.span.attrs({
  className: 'product-quantity',
})`
  font-weight: bold;
`;
