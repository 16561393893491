import { rem } from 'polished';
import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  .heading_s2 {
    text-align: center;
    h2 {
      font-size: ${rem('24px')};
      margin: 1rem 0;
    }
  }

`;

export const Button = styled.button.attrs({
  className: 'btn btn-fill-out btn-block',
})`
  margin-top: auto;
`;
