import get from 'lodash/get';
import React, {
  useState, useRef, useImperativeHandle, forwardRef, useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import SettingsContext from 'context/SettingsContext';

import useSearchParams from 'hooks/useSearchParams';

import { searchProductsRequest } from 'store/ducks';
import { getConfig, isSingleStore } from 'store/ducks/base';

import { CATEGORY_SEARCH_BUTTON_LABEL, SEARCH_BUTTON_LABEL } from 'utils/contants';

import {
  StoreSearch,
  Form,
  SearchInputGroup,
  SearchInput,
  Button,
  IconSearch,
  BtnCancel,
} from './Search.styled';

interface SearchProps {
  modifiers?: string | string[];
  setTxtSearch?(param: any): void;
}

function Search({ modifiers, setTxtSearch }: SearchProps, ref: any): React.ReactElement {
  // const dispatch = useDispatch();
  const history = useHistory();

  const singleStore = useSelector(isSingleStore);
  const {
    search_button_label = SEARCH_BUTTON_LABEL,
    category_search_button_label = CATEGORY_SEARCH_BUTTON_LABEL,
  } = get(useSelector(getConfig), 'config.labels');

  const storeMatch = useRouteMatch(singleStore ? '/' : '/store/:storeSlug');
  const [categoryId] = useSearchParams(['categoryId']);

  const { closeModal } = useContext(SettingsContext);

  const [searchValue, setSearchValue] = useState('');

  const handleChange = (event: any): void => {
    const searchName = event.target.value;
    setSearchValue(searchName);
    setTxtSearch && setTxtSearch(searchName);
  };

  const search = (event: any): void => {
    event.preventDefault();

    // if (storeMatch?.isExact) {
    if (storeMatch?.url.endsWith('/')) {
      history.push(`${storeMatch.url}search?value=${searchValue}`);
    } else {
      history.push(`${storeMatch?.url}/search?value=${searchValue}`);
    }
    // } else {
    //   dispatch(searchProductsRequest({ searchName: searchValue }));
    // }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: (): void => {
      if (inputRef?.current?.focus) {
        inputRef.current.focus();
      }
    },
  }));

  return (
    <StoreSearch modifiers={modifiers}>
      <Form>
        <SearchInputGroup>
          <SearchInput
            ref={inputRef}
            placeholder={categoryId ? category_search_button_label : search_button_label}
            type="text"
            onChange={handleChange}
            value={searchValue}
          />
          <Button onClick={search}><IconSearch /></Button>
        </SearchInputGroup>
        <BtnCancel onClick={closeModal}>
          Cancelar
        </BtnCancel>
      </Form>
    </StoreSearch>
  );
}

export default forwardRef(Search);
