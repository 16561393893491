import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const ColProducts = styled.div.attrs({
  className: 'col-lg-9',
})`
  padding-left: 0;
  padding-right: 0;
`;

export const Sidebar = styled.aside`
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;

export const NoResult = styled.h2`
  text-align: center;
  @media ${media.tabletMax} {
    font-weight: bold;
    text-align: left;
  }
  @media ${media.desktopMin} {
    margin-top: 4rem;
  }
`;
