import moment from 'moment';
import React from 'react';

import { Order } from 'typing/models';

import {
  CardOrderContainer,
  CardHeader,
  Image,
  CardBody,
  CardFooter,
} from './CardOrder.styled';

interface CardOrderProps {
  order: Order;
}

export default function CardOrder({ order }: CardOrderProps): React.ReactElement {
  const items = order.cart_items ?? [];
  const [first, second] = items;

  const itemsQty = items.length;

  const getOrderState = (state?: string): string => {
    const states = {
      confirmed: 'Confirmado',
      completed: 'Pedido finalizado',
      pending: 'Aguardando confirmação',
      received: 'Recebido',
      cancelled: 'Cancelado',
    };
    if (state !== undefined && state in states) {
      return states[state];
    }
    return 'Em processamento';
  };

  return (
    <CardOrderContainer to={`/orders/${order.id}`}>
      <CardHeader>
        <Image src={order.store?.image_logo || ''} />
        <div className="caption">
          <h2>{order.store?.name}</h2>
          <ul>
            <li>{getOrderState(order.state)}</li>
            <li>•</li>
            <li>
              Pedido #
              {order.id?.substring(0, 8)}
            </li>
            <li>•</li>
            <li>{moment(order.purchase_date).format('DD/MM/YYYY')}</li>
          </ul>
        </div>
      </CardHeader>
      <CardBody>
        <ul>
          {
            first && (
              <li>
                {parseFloat(first.quantity)}
                x
                {' '}
                {first.product_name}
              </li>
            )
          }
          {
            second && (
              <li>
                {parseFloat(second.quantity)}
                x
                {' '}
                {second.product_name}
              </li>
            )
          }
        </ul>
      </CardBody>
      <CardFooter>
        {itemsQty > 2 ? (
          <p>
            Mais
            {' '}
            {itemsQty - 2}
            {' '}
            {itemsQty - 2 > 1 ? 'itens' : 'item'}
          </p>
        ) : null}
        <span>Ver detalhes</span>
      </CardFooter>
    </CardOrderContainer>
  );
}
