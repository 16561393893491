import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { getFeaturedCategories } from 'services/api/categories';

import {
  listFeaturedCategoriesRequest,
  listFeaturedCategoriesSuccess,
  listFeaturedCategoriesFailure,
} from 'store/ducks';

function* getFeaturedCategoriesAsync({ payload }: Action<string>): SagaIterator {
  try {
    const response = yield call(getFeaturedCategories, payload);
    yield put(listFeaturedCategoriesSuccess(response));
  } catch (e) {
    yield put(listFeaturedCategoriesFailure(e));
  }
}

export default function* watchFeaturedCategoriesActions(): SagaIterator {
  yield takeEvery(listFeaturedCategoriesRequest.toString(), getFeaturedCategoriesAsync);
}
