import { rem } from 'polished';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from 'utils/themeHelper';

export const Section = styled.section.attrs({
  className: 'section',
})`
  padding-bottom: 0;
  @media ${media.desktopMin} {
    padding-top: ${rem('60px')};
  }
`;

export const Container = styled.div`
  @media ${media.tabletMax} {
    margin: 0 auto;
    max-width: 960px;
    padding: 0 15px;
  }
  @media ${media.desktopMin} {
    max-width: 1140px;
  }
`;

export const SliderBannersContainer = styled.div.attrs({
  className: 'mb-4',
})`
  overflow: hidden;
  position: relative;
  @media ${media.tabletMin} {
    overflow: visible !important;
  }

  .slick-arrow {
    display: none !important;
    @media ${media.tabletMax} {
      background-color: white;
      align-items: center;
      border-radius: 100px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      display: flex !important;
      height: 40px !important;
      justify-content: center;
      opacity: 1;
      right: -25px;
      width: 40px;
    }
    @media ${media.desktopMin} {
      right: -60px;
    }
    &:before {
      color: black !important;
      font-family: 'Poppins';
      font-size: ${rem('40px')} !important;
      content: '›' !important;
      line-height: 0;
      position: relative;
      top: -2px;
    }
    &.slick-prev {
      left: -10px;
      @media ${media.desktopMin} {
        left: -60px;
      }
      &:before {
        content: '‹' !important;
      }
    }
  }
`;

export const SliderBanners = styled(Slider).attrs(() => ({
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 475,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
      },
    },
  ],
}))`
`;

export const Banner = styled(Link)`
  padding: .5rem;
`;

export const Image = styled.img`
  border-radius: 30px;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
`;
