import axios from 'axios';
import getDeviceDetails from 'device-details';
import Qs from 'qs';
import { v4 as uuid } from 'uuid';

import { Profile } from 'typing/models';
import { RegisterDevicePayload } from 'typing/store';

const http = axios.create({
  baseURL: process.env.REACT_APP_POLIMESSAGE_URL,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const getDeviceId = (): string => {
  const deviceId = localStorage.getItem('@bodega-mix/deviceId');
  if (deviceId) {
    return deviceId;
  }
  const newDeviceId = uuid();
  localStorage.setItem('@bodega-mix/deviceId', newDeviceId);
  return newDeviceId;
};

export const setFCMToken = (token: string): void => {
  localStorage.setItem('@bodega-mix/fcmToken', token);
};

export const registerDevice = async (token: string,
  data: Profile): Promise<void> => {
  const deviceId = getDeviceId();

  const payload: RegisterDevicePayload = {
    platform_key: 'b45b22a9-a830-4244-9454-897a3b6a8f4c',
    device_info: getDeviceDetails(),
    device_uuid: deviceId,
    device_token: token,
    company_code: 'COLM',
    person_kind: 'client',
    person_foreign_code: data.client.identifier_code,
    person_name: `${data.first_name} ${data.last_name}`,
    person_email: data.email,
    person_city: null,
    person_identity_number: null,
    person_phone: null,
    person_address: null,
    person_state: null,
  };
  setFCMToken(token);

  await http.post('register-device/', payload);
};
