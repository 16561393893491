import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getProductsFilters, getSelectedCategory, isLoading, listProductsRequest, setSelectedFiltersProduct,
} from 'store/ducks';

import { Category } from 'typing/models';

import { LIST_PRODUCTS } from 'utils/fetchs';

import NavCategories from '../NavCategories';
import ProductsSlider from '../ProductsSlider/ProductsSlider';
import SidebarCategories from '../SidebarCategories';
import {
  Col, Container, Row, Sidebar, ColProducts,
} from './CategoryResult.styled';

export default function CategoryResult(): React.ReactElement {
  const dispatch = useDispatch();

  const [categories, setCategories] = useState<Category[]>([]);
  const productsFilter = useSelector(getProductsFilters('search'));
  const selectedCategory = useSelector(getSelectedCategory);

  const loading = useSelector(isLoading(LIST_PRODUCTS, selectedCategory?.id));

  useEffect(() => {
    dispatch(setSelectedFiltersProduct({}));
  }, []);

  useEffect(() => {
    if (selectedCategory && !selectedCategory.categories_child_ids.length) {
      dispatch(listProductsRequest([selectedCategory.id], selectedCategory?.id));
    } else if (selectedCategory) {
      selectedCategory.categories_child_ids.map(
        (child) => dispatch(listProductsRequest(child.id, child.id)),
      );
    }
  }, [productsFilter?.searchName, selectedCategory?.id]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.categories_child_ids.length) {
      setCategories(selectedCategory.categories_child_ids);
    } else if (selectedCategory) {
      setCategories([selectedCategory]);
    }
  }, [selectedCategory, categories.length]);

  return (
    <Container>
      {
        loading ? (
          <p>Carregando...</p>
        ) : (
          <Row className="row">
            <NavCategories fullCategories={false} />
            <ColProducts>
              {
                categories.length
                  ? categories.filter((category) => ((category.count_products || 0) > 0))
                    .map((category) => (
                      <ProductsSlider
                        key={category.id}
                        categoryId={category.id}
                        categoryName={category.name}
                      />
                    )) : (<p>Sem resultados</p>)
              }
            </ColProducts>
            <Col
              className="col-lg-3 order-lg-first mt-lg-0 pt-lg-0 order-first"
              style={{
                position: 'sticky', top: '100px', alignSelf: 'flex-start', zIndex: 10,
              }}
            >
              <Sidebar>
                <SidebarCategories fullCategories={false} />
              </Sidebar>
            </Col>
          </Row>
        )
      }
    </Container>
  );
}
