import styled from 'styled-components';
import { rem } from 'polished';

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const ProductImageContainer = styled.div.attrs({
  className: 'product_img_box',
})`
  border: none;
  text-align: center;
  img {
    height: auto;
    max-width: 100%;
  }
`;

export const CartBtn = styled.div.attrs({
  className: 'cart_btn',
})`
  .btn {
    width: 100%;
  }
`;

export const ProdDescription = styled.div.attrs({
  className: 'pr_desc',
})`
  p {
    font-size: ${rem('14px')};
  }
`;
