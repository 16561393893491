import produce from 'immer';
import intersection from 'lodash/intersection';
import { createActions, handleActions } from 'redux-actions';

import { Category } from 'typing/models';
import {
  AbstractPayload, BaseState, CategoriesState, ProductsState,
} from 'typing/store';

import {
  LIST_CATEGORIES,
  LIST_FEATURED_CATEGORIES,
  LIST_SIDE_MENU_CATEGORIES,
  LIST_FRONT_VIEW_CATEGORIES,
} from 'utils/fetchs';

import { fetchStateGenerator } from './checkFetchReducerCreator';

export const {
  listCategoriesRequest,
  listCategoriesSuccess,
  listCategoriesFailure,
  listCategoriesClean,

  listFeaturedCategoriesRequest,
  listFeaturedCategoriesSuccess,
  listFeaturedCategoriesFailure,
  listFeaturedCategoriesClean,

  listSideMenuCategoriesRequest,
  listSideMenuCategoriesSuccess,
  listSideMenuCategoriesFailure,
  listSideMenuCategoriesClean,

  listFrontViewCategoriesRequest,
  listFrontViewCategoriesSuccess,
  listFrontViewCategoriesFailure,
  listFrontViewCategoriesClean,

  selectCategory,
  unselectCategory,
} = createActions(
  {},
  ...fetchStateGenerator(LIST_CATEGORIES),
  'SELECT_CATEGORY',
  'UNSELECT_CATEGORY',
  { prefix: 'CATEGORIES' },
);

const INITIAL_STATE = {
  list: {
    data: [],
  },
  detail: null,
};

export const getSelectedCategory = (state: BaseState): Category | null => state.categories.detail;

export const getCategories = (storeSlug?: string) => (state: BaseState):
  Category[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return state.categories.list.data;
  }

  return [];
};

export const listCategoriesByIds = (storeSlug: string, ids: string[]) => (state: BaseState):
  Category[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return state.categories.list.data.filter((category) => {
      const categoryIds = category.categories_child_ids.map((child) => child.id.toString());
      categoryIds.push(category.id.toString());

      return !!intersection(categoryIds, ids).length;
    });
  }

  return [];
};

export const getCategoryById = (id: number | string | null) => (state: BaseState):
  Category | undefined => state.categories.list.data.find(
  (category) => category.id.toString() === id?.toString(),
);

export default handleActions<CategoriesState, AbstractPayload>({
  [listCategoriesSuccess.toString()]: produce(
    (draft: CategoriesState, { payload }) => {
      draft.list.data = payload;
      draft.list.filter = undefined;
    },
  ),
  [selectCategory.toString()]: produce(
    (draft: CategoriesState, { payload }) => {
      draft.detail = payload;
    },
  ),
  [unselectCategory.toString()]: produce(
    (draft: CategoriesState) => {
      draft.detail = null;
    },
  ),
}, INITIAL_STATE);
