import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const StorePageHeaderContainer = styled.section.attrs({
  className: 'breadcrumb_section bg_gray page-title-mini',
})`
  background: white !important;
  margin-top: 6rem;
  overflow: hidden;
  position: relative;
  @media ${media.tabletMax} {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }

`;

export const Container = styled.div.attrs({
  className: 'container',
})`
  position: relative;
  z-index: 10;
  @media ${media.tabletMax} {
    order: 2;
    padding-top: 1rem;
    padding-bottom: 6px;
  }
`;

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const PageTitle = styled.div.attrs({
  className: 'page-title d-flex align-items-center',
})`
`;

export const PageTitleInner = styled.div`
  h1 {
    color: #292b2c;
  }
  p {
    color: #687188;
  }
`;

export const StoreImageContainer = styled.div.attrs({
  className: 'mb-0 mr-3',
})`
  background-color: white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem('100px')};
  max-width: ${rem('100px')};
  overflow: hidden;
  @media ${media.tabletMax} {
    box-shadow: 0 3px 6px rgba(0,0,0, 0.1);
    height: ${rem('120px')};
    margin-right: 2rem !important;
    max-width: ${rem('120px')};
    width: ${rem('120px')};
  }
`;

export const StoreImage = styled.img`
  height: auto;
  max-width: ${rem('80px')};
  @media ${media.tabletMax} {
    max-width: ${rem('120px')};
    width: ${rem('120px')};
  }
`;

export const ButtonCategoriesContainer = styled.div.attrs({
  className: 'mt-3 text-center d-lg-none',
})`
`;

export const ButtonCategories = styled.button.attrs({
  className: 'btn btn-radius',
})`
`;

export const StoreBannerHeader = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  @media ${media.tabletMax} {
    position: relative;
  }
  @media ${media.desktopMin} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

export const StoreBannerInner = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  @media ${media.desktopMin} {
    border-radius: 16px;
    overflow: hidden;
  }

  &:before {
    background-color: rgba(0,0,0, 0.5);
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    @media ${media.tabletMax} {
      opacity: 0;
    }
  }

  img {
    height: 100%;
    left: 50%;
    max-width: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    @media ${media.tabletMax} {
      height: auto;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }
`;
