import { AxiosPromise } from 'axios';

import { Order } from 'typing/models';

import http from './http';

export const listCompletedOrders = (): AxiosPromise<Order[]> => http.get('/store/client/sale/completed/');

export const listPendingOrders = (): AxiosPromise<Order[]> => http.get('/store/client/sale/on-going/');

export const getOrderDetail = (id: string): AxiosPromise<Order> => http.get(`/store/client/sale/${id}/`);
