import { rem } from 'polished';
import styled from 'styled-components';

import { flex, media } from 'utils/themeHelper';

export const IntroStoreBannerContainer = styled.div`
  display: none;
  margin-top: -1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: ${rem('1600px')};
  padding-left: 1rem;
  padding-right: 1rem;
  @media ${media.tabletMin} {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media ${media.desktopMin} {
    display: block;
    padding-left: ${rem('40px')};
    padding-right: ${rem('40px')};
  }
`;

export const IntroStoreBannerInner = styled.div`
  background-color: #EEE;
  border-radius: 60px;
  ${flex('row', 'center', 'center')};
  min-height: ${rem('385px')};
  @media ${media.tabletMin} {
    background-image: url(${(props: any): string => props.resource});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;
