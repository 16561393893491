export function isValidCPF(cpf: string): boolean {
  const cleanedCpf = cpf.replace(/[\s.-]*/igm, '');
  if (
    !cleanedCpf
    || cleanedCpf.length !== 11
    || cleanedCpf === '00000000000'
    || cleanedCpf === '11111111111'
    || cleanedCpf === '22222222222'
    || cleanedCpf === '33333333333'
    || cleanedCpf === '44444444444'
    || cleanedCpf === '55555555555'
    || cleanedCpf === '66666666666'
    || cleanedCpf === '77777777777'
    || cleanedCpf === '88888888888'
    || cleanedCpf === '99999999999'
  ) {
    return false;
  }
  let sum = 0;
  let rest;
  for (let i = 1; i <= 9; i += 1) sum += parseInt(cleanedCpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) rest = 0;
  if (rest !== parseInt(cleanedCpf.substring(9, 10))) return false;
  sum = 0;
  for (let i = 1; i <= 10; i += 1) sum += parseInt(cleanedCpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) rest = 0;
  return rest === parseInt(cleanedCpf.substring(10, 11));
}
