import React from 'react';
import { useSelector } from 'react-redux';

import Img from 'assets/images/bell.png';

import { getLoginData } from 'store/ducks';

import Modal from 'ui/components/Modal';
import { PageHeader, Title } from 'ui/components/Page';

import { ModalContent, Button, OutlinedButton } from './ModalFirstAskNotification.styled';

export default function ModalFirstAskNotification(props: {
  onAccept: () => void;
  onReject: () => void;
}): React.ReactElement {
  const { onAccept, onReject } = props;
  const profile = useSelector(getLoginData);

  const handleRejectPush = (): void => {
    localStorage.setItem('@budega-mix/notification-first-ask', 'rejected');
    onReject();
  };
  const handleAcceptPush = (): void => {
    localStorage.setItem('@budega-mix/notification-first-ask', 'accepted');
    onAccept();
  };

  return (
    <Modal close={handleRejectPush} modifiers={['ShowModal', 'ModalSmall']}>
      <ModalContent>
        <PageHeader>
          <Title>
            Ei,
            {' '}
            {profile?.profile.first_name}
          </Title>
          <img height={150} src={Img} alt="Sino de notificações" />
          <p>
            Quer ficar por dentro das novidades e promoções? Para isso clique em
            {' '}
            <b>Quero sim!</b>
            {' '}
            e em seguida em permitir notificações.

          </p>
          <div>
            <span>É rapidinho e você pode desativar a qualquer momento.</span>
          </div>
        </PageHeader>
        <OutlinedButton onClick={handleRejectPush}>Não quero</OutlinedButton>
        <Button onClick={handleAcceptPush}>Quero sim!</Button>
      </ModalContent>
    </Modal>
  );
}
