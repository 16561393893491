import React from 'react';

import { LoadingContainer, Ellipsis } from './Loading.styled';

export default function Loading(): React.ReactElement {
  return (
    <LoadingContainer>
      <Ellipsis>
        <span />
        <span />
        <span />
      </Ellipsis>
    </LoadingContainer>
  );
}
