import orderBy from 'lodash/orderBy';
import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { listCompletedOrders, listPendingOrders, getOrderDetail } from 'services/api/orders';

import {
  listOrdersFailure,
  listOrdersRequest,
  listOrdersSuccess,
  getOrderDetailRequest,
  getOrderDetailFailure,
  getOrderDetailSuccess,

} from 'store/ducks';

import { Order } from 'typing/models';

export function* getOrderDetailAsync({ payload }: Action<string>): SagaIterator {
  try {
    const order: Order = yield call(getOrderDetail, payload);
    yield put(getOrderDetailSuccess(order));
  } catch (e) {
    yield put(getOrderDetailFailure(e));
  }
}

export function* listOrdersAsync(): SagaIterator {
  try {
    const pendingOrders: Order[] = yield call(listPendingOrders);
    const completedOrders: Order[] = yield call(listCompletedOrders);

    yield put(listOrdersSuccess(orderBy([...pendingOrders, ...completedOrders], 'purchase_date', 'desc')));
  } catch (e) {
    yield put(listOrdersFailure(e));
  }
}

export default function* watchOrdersActions(): SagaIterator {
  yield takeEvery(listOrdersRequest.toString(), listOrdersAsync);
  yield takeEvery(getOrderDetailRequest.toString(), getOrderDetailAsync);
}
