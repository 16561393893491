import firebase from 'firebase/app';

import 'firebase/messaging';
import { registerDevice } from 'services/api/poli-message';

import { Profile } from 'typing/models';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBDfC-mzYbyrynKYM2eZ46gT7QjS55lwec',
  authDomain: 'poliequipes.firebaseapp.com',
  projectId: 'poliequipes',
  storageBucket: 'poliequipes.appspot.com',
  messagingSenderId: '10997492075',
  appId: '1:10997492075:web:ea7bfa955b429628e7442e',
  measurementId: 'G-BWHW40B4L4',
});

export const isSupported = (): boolean => firebase.messaging.isSupported();

export const initForegroundNotification = (): void => {
  try {
    if (!isSupported()) {
      throw new Error('Firebase messaging is not supported');
    }
    const messaging = firebaseApp.messaging();

    messaging.onMessage((payload) => {
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration) => {
        if (!registration || !payload.notification) {
          return;
        }
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
          body: payload.notification.body,
        };
        registration.showNotification(notificationTitle, notificationOptions);
      });
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchToken = async (profile: Profile): Promise<boolean> => {
  try {
    if (!isSupported()) {
      throw new Error('Firebase messaging is not supported');
    }
    const messaging = firebaseApp.messaging();
    const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration('');

    const token = await messaging.getToken({
      serviceWorkerRegistration,
    });

    if (!token) {
      throw new Error('No registration token available. Request permission to generate one.');
    }
    registerDevice(token, profile);

    return true;
  } catch (error) {
    if ((error as any)?.code === 'messaging/permission-blocked') {
      console.warn('PoliMessage: It looks like there permissions are not granted', error);
      return false;
    }
    console.error(error);

    return true;
  }
};

initForegroundNotification();
