import styled from 'styled-components';
import { media } from 'utils/themeHelper';

export const LoginWrapper = styled.div.attrs({
  className: 'login_wrap',
})`
`;

export const LoginInner = styled.div`
  padding: 2rem;
  @media ${media.tabletMin} {
    padding: 2.5rem;
  }
`;

export const Heading = styled.div.attrs({
  className: 'heading_s1',
})`
  text-align: center;
`;

export const FormGroup = styled.div.attrs({
  className: 'form-group',
})`
`;

export const FormNote = styled.div.attrs({
  className: 'form-note',
})`
  text-align: center;
`;

export const LoginFooter = styled.div.attrs({
  className: 'login_footer',
})`
  @media ${media.tabletMin} {
    margin-bottom: 2.5rem;
  }
`;

export const CheckForm = styled.div.attrs({
  className: 'chek-form',
})`
`;

export const Checkbox = styled.div.attrs({
  className: 'custome-checkbox',
})`
`;

export const ForgotPassword = styled.div`
  text-align: center;
`;
