import styled from 'styled-components';
import { rem } from 'polished';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

const ModalModifiers = {
  Active: () => `
    svg {
      stroke: #06C167;
    }
    span {
      color: black;
    }
  `,
  Remove: () => `
    &:after {
      background-color: rgba(255, 50, 77, .35);
      border-radius: 8px;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  `,
};

export const CardAddressContainer = styled.div`
  background-color: white;
  border: 1px solid #EEE;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-height: ${rem('80px')};
  position: relative;
  padding: 1rem;
  

  svg {
    height: ${rem('24px')};
    margin-right: 1rem;
    stroke: #EEE;
    stroke-width: 3px;
    width: ${rem('24px')};
  }

  span {
    font-size: ${rem('16px')};
    max-width: 75%;
    text-align: left;
  }
`;

export const CardSelect = styled.div.attrs({
})<utils.WithModifiers>`
  display: flex;
  align-items: center;
  &:after {
    background-color: white;
    content: '';
    transition: background-color 0.3s ease;
  }
  ${applyStyleModifiers(ModalModifiers)};
`;

export const DropdownButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  svg {
    stroke: black;
    margin: 0;
  }
`;

export const DropdownContent = styled.ul`
  background: white;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0,0,0, .15);
  list-style: none;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: -1rem;
  text-align: left;
  width: 160px;

  li {
    border-bottom: 1px solid #EEE;
  }

  a {
    display: inline-block;
    padding: .15rem 0 .5rem;
    &:hover {
      color: ${(props) => props.theme.primary.main};
    }
  }

`;

export const DropdownAction = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
  ${DropdownContent} {
    display: none;
  }
  &:hover {
    ${DropdownContent} {
      display: block;
    }
  }
`;

export const DropdownInner = styled.div`
  position: relative;
`;
