import classNames from 'classnames';
import get from 'lodash/get';
import keys from 'lodash/keys';
import React, { useEffect, useState } from 'react';
import Form from 'react-formal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useSearchParams from 'hooks/useSearchParams';

import { isSuccess, registerClean } from 'store/ducks';
import { getConfig } from 'store/ducks/base';
import { getRegisterErrors, registerRequest } from 'store/ducks/user';

import { Page, Container } from 'ui/components/Page';

import { REGISTER_FORM_LABEL } from 'utils/contants';
import { REGISTER } from 'utils/fetchs';
import { cpfMask, cnpjMask, phoneNumberMask } from 'utils/masks';
import { registerSchema, RegisterSchemaType } from 'utils/schemas';

import { REGISTER as REGISTER_NAV } from '../../../context/SettingsContext';
import useNavControl from '../../../hooks/useNavControl';
import {
  LoginWrapper,
  LoginInner,
  Heading,
  FormGroup,
  LoginFooter,
  CheckForm,
  Checkbox,
} from '../Login/Login.styled';

export type PeopleType = 'pj' | 'pf';

export default function UserRegistration(): React.ReactElement {
  useNavControl(REGISTER_NAV);

  const dispatch = useDispatch();

  const success = useSelector(isSuccess(REGISTER));
  const errors = useSelector(getRegisterErrors);
  const { register_form_label = REGISTER_FORM_LABEL } = get(useSelector(getConfig), 'config.labels');

  const [formErrors, setFormErrors] = useState();

  const initialState = {
    first_name: '',
    last_name: '',
    identifier_code: '',
    email: '',
    phone_number: '',
    password: '',
    password2: '',
    people_type: '',
  };

  const labels: any = {
    identifier_code: 'CPF',
    email: 'E-mail',
  };

  const [registerData, setRegisterData] = useState<RegisterSchemaType | undefined>(initialState);

  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [peopleType, setPeopleType] = useState<PeopleType | undefined>();

  const register = (formValue: RegisterSchemaType): void => {
    dispatch((registerRequest(formValue)));
  };

  const [next]: string[] = useSearchParams(['next']);

  useEffect(() => (): void => {
    dispatch(registerClean());
  }, []);

  useEffect(() => {
    if (success) {
      setRegisterData(initialState);
    }
  }, [success]);

  useEffect(() => {
    if (typeof errors === 'object') {
      setFormErrors(keys(errors).reduce((errorsResult: any, key: string) => {
        errorsResult[key] = errors[key].map((message: string) => {
          if (message === 'already exists') {
            return { message: `${labels[key]} já cadastrado` };
          }

          return { message };
        });
        return errorsResult;
      }, {}));
    }
  }, [errors]);

  return (
    <Page>
      <Container modifiers="Small">
        <LoginWrapper>
          <LoginInner>
            <Heading>
              <h2>{register_form_label}</h2>
              <p>
                Já tem cadastro?
                {' '}
                <Link to={next ? `/profile/login/${next}` : '/profile/login/'}>Faça seu login.</Link>
              </p>
            </Heading>
            <Form
              autoComplete="off"
              errors={formErrors}
              onError={(fErrors): void => setFormErrors(fErrors)}
              schema={registerSchema}
              value={registerData}
              defaultValue={registerSchema.default()}
              onChange={(model: any): void => {
                setPeopleType(model.people_type);
                model.identifier_code = peopleType === 'pf' ? cpfMask(model.identifier_code) : cnpjMask(model.identifier_code);
                model.phone_number = phoneNumberMask(model.phone_number);
                setRegisterData(model);
              }}
              onSubmit={register}
            >
              <fieldset>
                <FormGroup>
                  <legend>Pessoa física ou jurídica?</legend>
                  <label>
                    <Form.Field type="radio" name="people_type" value="pf" />
                    <label style={{ marginLeft: 3 }}>Física</label>
                  </label>
                  <label>
                    <Form.Field type="radio" name="people_type" value="pj" style={{ marginLeft: 10 }} />
                    <label style={{ marginLeft: 3 }}>Jurídica</label>
                  </label>

                  <Form.Message for="people_type" />
                </FormGroup>
                {peopleType !== undefined && (
                  <>
                    {peopleType === 'pf' && (
                    <>
                      <FormGroup>
                        <Form.Field name="first_name" placeholder="Nome" className="form-control" />
                        <Form.Message for="first_name" />
                      </FormGroup>
                      <FormGroup>
                        <Form.Field name="last_name" placeholder="Sobrenome" className="form-control" />
                        <Form.Message for="last_name" />
                      </FormGroup>
                      <FormGroup>
                        <Form.Field name="identifier_code" placeholder="CPF" className="form-control" />
                        <Form.Message for="identifier_code" />
                      </FormGroup>
                    </>
                    )}
                    {peopleType === 'pj' && (
                    <>
                      <FormGroup>
                        <Form.Field name="first_name" placeholder="Razão social" className="form-control" />
                        <Form.Message for="first_name" />
                      </FormGroup>
                      <FormGroup>
                        <Form.Field name="identifier_code" placeholder="CNPJ" className="form-control" />
                        <Form.Message for="identifier_code" />
                      </FormGroup>
                    </>
                    )}
                    <hr />
                  </>
                )}

                <FormGroup>
                  <Form.Field name="email" placeholder="E-mail" className="form-control" type="email" />
                  <Form.Message for="email" />
                </FormGroup>
                <FormGroup>
                  <Form.Field name="phone_number" placeholder="Telefone" className="form-control" />
                  <Form.Message for="phone_number" />
                </FormGroup>
                <FormGroup>
                  <Form.Field name="password" placeholder="Senha" className="form-control" type="password" />
                  <Form.Message for="password" />
                </FormGroup>
                <FormGroup>
                  <Form.Field name="password2" placeholder="Confirmar Senha" className="form-control" type="password" />
                  <Form.Message for="password2" />
                </FormGroup>
              </fieldset>
              <LoginFooter>
                <CheckForm>
                  <Checkbox>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="privacyCheckbox"
                      checked={privacyChecked}
                      onChange={({ target }): void => setPrivacyChecked(target.checked)}
                      id="privacyCheckbox"
                    />
                    <label className="form-check-label" htmlFor="privacyCheckbox">
                      <span>Estou de acordo com a política de privacidade</span>
                    </label>
                  </Checkbox>
                </CheckForm>
              </LoginFooter>
              <FormGroup>
                <Form.Submit
                  type="submit"
                  className={classNames({
                    'btn btn-fill-out btn-block': true,
                    disabled: !privacyChecked || !registerSchema.isValid(registerData),
                  })}
                >
                  Criar conta
                </Form.Submit>
              </FormGroup>
            </Form>
          </LoginInner>
        </LoginWrapper>
      </Container>
    </Page>
  );
}
