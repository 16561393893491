import produce from 'immer';
import { createActions, handleActions } from 'redux-actions';

import {
  FilterProduct,
} from 'typing/models';
import {
  AbstractPayload,
  BaseState,
  FiltersProductState,
} from 'typing/store';

import { LIST_FILTERS_PRODUCT } from 'utils/fetchs';

import { fetchStateWithMetaGenerator } from './checkFetchReducerCreator';

export const {
  listFiltersProductRequest,
  listFiltersProductSuccess,
  listFiltersProductFailure,
  listFiltersProductClean,
} = createActions(
  fetchStateWithMetaGenerator(LIST_FILTERS_PRODUCT),
);

const INITIAL_STATE = {
  list: {
    data: [],
  },
  detail: null,
};

export const getFiltersProduct = (storeSlug?: string) => (state: BaseState):
  FilterProduct[] => {
  if (state.stores.detail?.slug === storeSlug) {
    return state.filters_product.list.data;
  }

  return [];
};

export default handleActions<FiltersProductState, AbstractPayload>({
  [listFiltersProductSuccess.toString()]: produce(
    (draft: FiltersProductState, { payload }) => {
      draft.list.data = payload;
      draft.list.filter = undefined;
    },
  ),
}, INITIAL_STATE);
