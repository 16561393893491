import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const NavCategoriesContainer = styled.section`
  background-color: white;
  margin: -1rem 0 1rem;
  overflow: auto;
  padding: 1rem 0;
  position: sticky;
  top: 90px;
  width: 100%;
  z-index: 10;
  @media ${media.tabletMin} {
    margin-top: -3rem;
  }
  @media ${media.tabletMax} {
    display: none;
  }
`;

export const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0 1rem;
`;

export const ItemList = styled.li`
  margin-right: .5rem;
  a {
    background-color: #f7f7f7;
    display: block;
    padding: .5rem .875rem;
    white-space: nowrap;
  }
`;

export const Space = styled.span`
  display: block;
  min-width: 1rem;
`;
