import produce from 'immer';
import { createActions, handleActions } from 'redux-actions';

import {
  AbstractPayload,
  BaseState,
  SelectedFiltersProductState,
} from 'typing/store';

export const {
  setSelectedFiltersProduct,
} = createActions(
  'SET_SELECTED_FILTERS_PRODUCT',
);

const INITIAL_STATE = {
  values: {},
};

export const getSelectedFiltersProduct = (state: BaseState):
  any | null | undefined => state.selected_filters_product.values;

export default handleActions<SelectedFiltersProductState, AbstractPayload>({
  [setSelectedFiltersProduct.toString()]: produce((draft: SelectedFiltersProductState, { payload }) => {
    draft.values = payload;
  }),
}, INITIAL_STATE);
