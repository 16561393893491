import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { rem } from 'polished';

import { Panel } from 'ui/components/Page';

export const PanelPedidos = styled(Panel)`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  svg {
    height: ${rem('100px')};
    width: auto;
  }
  
  h2 {
    color: black;
    font-weight: bold;
    margin: 1rem 0;
  }

`;

export const Button = styled(Link).attrs({
  className: 'btn btn-border-fill btn-radius',
})`
  width: 100%;
`;

export const Date = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1rem;
`;
