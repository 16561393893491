import flatten from 'lodash/flatten';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getSelectedStore, isLoading, listCategoriesByIds } from 'store/ducks';
import { getCampaignProducts, listCampaignProductsRequest } from 'store/ducks/products';

import { LIST_CAMPAIGN_PRODUCTS } from 'utils/fetchs';

import NavCategories from '../NavCategories';
import ProductsSlider from '../ProductsSlider/ProductsSlider';
import SidebarCategories from '../SidebarCategories';
import {
  Col, Container, Row, Sidebar, NoResult, ColProducts,
} from './CampaignResult.styled';

export default function CampaignResult(): React.ReactElement {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  const store = useSelector(getSelectedStore);
  const products = useSelector(getCampaignProducts(store?.slug || 'undefined'));

  const product_ids = flatten(
    products.map((p) => p.category_ids.map((c) => c.id.toString())),
  );
  const categories = useSelector(listCategoriesByIds(store?.slug || 'undefined', product_ids));

  const loading = useSelector(isLoading(LIST_CAMPAIGN_PRODUCTS));
  let alreadyLoad = false;

  useEffect(() => {
    alreadyLoad = true;
    dispatch(listCampaignProductsRequest(campaignId));
  }, []);

  return (
    <Container>
      <Row className="row">
        <NavCategories fullCategories={false} />
        <ColProducts>
          {
            categories.length
              ? categories.map((category) => (
                <ProductsSlider
                  key={category.id}
                  categoryId={category.id}
                  categoryName={category.name}
                />
              )) : (
                <NoResult>
                  {
                        loading ? 'Carregando...' : 'Nenhum resultado encontrado'
                      }
                </NoResult>
              )
          }
        </ColProducts>
        <Col
          className="col-lg-3 order-lg-first mt-lg-0 pt-lg-0 order-first"
          style={{
            position: 'sticky', top: '100px', alignSelf: 'flex-start', zIndex: 10,
          }}
        >
          <Sidebar>
            <SidebarCategories fullCategories={false} />
          </Sidebar>
        </Col>
      </Row>
    </Container>
  );
}
