import React from 'react';

import {
  Page, Container, PageHeader, Title, Text,
} from 'ui/components/Page';

import {
  StepItem, StepTag, Info, Button,
} from './EnableCookies.styled';

export default function EnableCookies(): React.ReactElement {
  return (
    <Page>
      <Container modifiers={['TextCenter', 'Small']}>
        <StepItem>
          <PageHeader>
            <Title>Permita os Cookies</Title>
          </PageHeader>
          <Text>
            Para que você possa acessar nosso site normalmente, é necessário que você habilite os cookies em
            seu navegador. É muito simples, basta seguir os passos abaixo:
          </Text>
          <StepTag>Passo 1</StepTag>
          <Info>
            Vá até o menu de configurações do seu navegador e clique em &quot;Privacidade e Segurança&quot;.
          </Info>
          <StepTag>Passo 2</StepTag>
          <Info> Em &quot;Cookies e dados de site&quot;, clique em &quot;Gerenciar dados de site&quot;.</Info>

          <StepTag>Passo 3</StepTag>
          <Info>
            {' '}
            Ai é só clicar em &quot;Permitir todos os cookies&quot; ou remover este site da lista de sites
            bloqueados. :)
          </Info>
          <Info>Seguiu todos os passos?</Info>
          <Button
            onClick={(): void => {
              window.location.reload();
            }}
          >
            Recarregar a página
          </Button>
        </StepItem>
      </Container>
    </Page>
  );
}
