export function isValidCNPJ(cnpj: string): boolean {
  const cleanedCnpj = cnpj.replace(/[/\s.-]*/igm, '');

  if (
    !cleanedCnpj
    || cleanedCnpj.length !== 14
    || cleanedCnpj === '00000000000000'
    || cleanedCnpj === '11111111111111'
    || cleanedCnpj === '22222222222222'
    || cleanedCnpj === '33333333333333'
    || cleanedCnpj === '44444444444444'
    || cleanedCnpj === '55555555555555'
    || cleanedCnpj === '66666666666666'
    || cleanedCnpj === '77777777777777'
    || cleanedCnpj === '88888888888888'
    || cleanedCnpj === '99999999999999'
  ) {
    return false;
  }

  let sum = 0;
  let sum2 = 0;

  for (let i = 6; i >= 2; i -= 1) {
    if (i <= 5) {
      sum += parseInt(cleanedCnpj.charAt(5 - i)) * i;
    }
    sum2 += parseInt(cleanedCnpj.charAt(6 - i)) * i;
  }

  for (let i = 9; i >= 2; i -= 1) {
    sum += parseInt(cleanedCnpj.charAt(9 - i + 4)) * i;
    sum2 += parseInt(cleanedCnpj.charAt(9 - i + 5)) * i;
  }
  function calcDigit(value: number): number {
    return value % 11 < 2 ? 0 : 11 - (value % 11);
  }

  return calcDigit(sum) === parseInt(cleanedCnpj.charAt(12))
   && calcDigit(sum2) === parseInt(cleanedCnpj.charAt(13));
}
