import get from 'lodash/get';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import { useCheckFetch } from 'hooks';
import useNavControl from 'hooks/useNavControl';

import { getStores } from 'store/ducks';
import { getConfig } from 'store/ducks/base';
import { getPendingOrdersQty } from 'store/ducks/orders';
import { getDefaultAddress } from 'store/ducks/user';

import { Store } from 'typing/models';

import BannersHome from 'ui/components/BannersHome';
import IntroStoreBanner from 'ui/components/IntroStoreBanner';
import IntroStoreSearch from 'ui/components/IntroStoreSearch';
import {
  Title,
} from 'ui/components/Page';
import {
  RecommendStoreContainer,
  Container,
  Row,
  Col,
} from 'ui/components/RecommendStore/RecommendStore.styled';
import SectionHeader from 'ui/components/SectionHeader';
import StoreCard from 'ui/components/StoreCard';

import { NEAREST_STORES_EMPTY_LIST_NOTICE, NEAREST_STORES_LIST_LABEL } from 'utils/contants';
import { LIST_STORES } from 'utils/fetchs';

import {
  HomeContainer, Main, ActiveOrders, Button,
} from './Home.styled';

export default function Home(): React.ReactElement {
  useNavControl();
  const stores = useSelector(getStores);
  const address = useSelector(getDefaultAddress);
  const ordersQty = useSelector(getPendingOrdersQty);
  const {
    nearest_stores_list_label = NEAREST_STORES_LIST_LABEL,
    nearest_stores_empty_list_notice = NEAREST_STORES_EMPTY_LIST_NOTICE,
  } = get(useSelector(getConfig), 'config.labels');

  const { openModal } = useContext(SettingsContext);

  const [loading] = useCheckFetch(LIST_STORES);

  return (
    <HomeContainer>

      {address ? (
        <IntroStoreBanner />
      ) : (
        <IntroStoreSearch />
      )}

      <Main>
        <RecommendStoreContainer>
          <Container>
            { ordersQty > 0 && (
              <ActiveOrders to="/orders">
                <span className="cart_count">
                  {ordersQty}
                </span>
                {' '}
                Pedido
                {ordersQty > 1 ? 's' : ''}
                {' '}
                ativo
                {ordersQty > 1 ? 's' : ''}
              </ActiveOrders>
            ) }
            <Row className="row">
              <Col className="col-md-12">
                <SectionHeader title={address ? nearest_stores_list_label : 'Recomendados'} />
                <Row className="row">
                  {
                    loading
                      && (
                        <Col className="col-12">
                          <p>Carregando...</p>
                        </Col>
                      )
                  }

                  {
                    (!loading && stores && stores.length) ? stores.map((store: Store, index) => (
                      <Col key={`${store.identifier_number}-${index}`} className="item col-7 col-sm-4 col-md-5 col-lg-3">
                        <StoreCard key={store.slug} store={store} />
                      </Col>
                    )) : (
                      <Col className="item col-12">
                        <Title as="h3">{nearest_stores_empty_list_notice}</Title>
                        <Button onClick={(): void => openModal && openModal('ADDRESS')}>Alterar CEP</Button>
                      </Col>
                    )
                  }
                </Row>
              </Col>
            </Row>
          </Container>
        </RecommendStoreContainer>
      </Main>
      <BannersHome />
    </HomeContainer>
  );
}
