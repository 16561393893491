import styled from 'styled-components';
import { rem } from 'polished';

import { media } from 'utils/themeHelper';

export const FooterContainer = styled.footer`
  display: none;
  text-align: center; 
  @media ${media.tabletMin} {
    display: block;
  }
  p {
    font-size: ${rem('12px')};
  }
`;
