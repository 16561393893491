import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${rem('500px')};
  
  .heading_s2 {
    text-align: center;
    h2 {
      font-size: ${rem('24px')};
    }
  }

`;

export const SearchAddress = styled.div`
`;

export const FormAddress = styled.div`
`;

export const UserAddresses = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${rem('500px')};
`;

export const FormGroup = styled.div.attrs({
  className: 'form-group',
})`
`;

export const Label = styled.label`
`;

export const Input = styled.input.attrs({
  className: 'form-control',
})`
`;

export const Divider = styled.p`
  color: black;
  display: block;
  font-weight: bold;
  text-align: center;
`;

export const ButtonLocation = styled.button`
  background-color: #f7f7f7;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${rem('200px')};
  padding: 1rem 2rem;
  width: 100%;
  span {
    font-weight: bold;
    display: block;
  }
`;

export const ButtonLocationInner = styled.span`
  min-height: ${rem('21px')};
  @media ${media.tabletMin} {
    min-height: ${rem('24px')};
  }
`;

export const ImgLocation = styled.img`
  height: ${rem('100px')};
  margin: 0 auto 1.5rem;
`;

export const Button = styled.button.attrs({
  className: 'btn btn-fill-out btn-block',
})`
  margin-top: auto;
`;

export const ButtonNewAddress = styled.button.attrs({
  className: 'btn btn-border-fill btn-radius',
})`
  margin-top: 1rem;
  width: 100%;
`;

export const BoxMessage = styled.div`
  margin-top: 1rem;
`;

export const FormControl = styled.div`
  position: relative;
  .feedback-message {
    position: absolute;
    top: 14px;
    right: 16px;
  }
`;

export const AddressList = styled.div`
  max-height: 50vh;
  overflow: auto;
  padding-bottom: 1rem;
`;

export const NewAddress = styled.button`
  background: none;
  border: none;
  display: block;
  margin: 0 auto 2rem;
  font-weight: bold;
  text-align: center;
`;
