import * as colors from './colors';
import * as metrics from './metrics';
import * as settings from './settings';
import spacing from './spacing';
import * as typography from './typography';

const main = {
  colors: { ...colors.main },
  settings: { ...settings.main },
  metrics: { ...metrics },
  spacing: { ...spacing },
  typography: { ...typography },

  primary: {
    main: '#546E7A',
  },

};

export { main };
