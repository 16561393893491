import React from 'react';
import { useDispatch } from 'react-redux';

import Img from 'assets/images/invalid-cart.svg';

import { clearCart, syncCartRequest } from 'store/ducks';

import Modal from 'ui/components/Modal';
import { PageHeader, Title } from 'ui/components/Page';

import { ModalContent, Button } from './ModalInvalidCart.styled';

interface ModalInvalidCartProps {
  onClose(): void;
}

export default function ModalInvalidCart({ onClose }: ModalInvalidCartProps): React.ReactElement {
  const dispatch = useDispatch();

  const close = (): void => {
    dispatch(clearCart());
    dispatch(syncCartRequest());
    onClose();
  };

  return (
    <Modal close={close} modifiers={['ShowModal', 'ModalSmall']}>
      <ModalContent>
        <PageHeader>
          <img src={Img} />
          <Title>Ops! Sacola indisponível</Title>
          <p>
            A sacola que você acessou não existe ou está indisponível.
            {' '}
            Por favor, inicie uma nova compra!
          </p>
        </PageHeader>
        <Button onClick={close}>Entendi</Button>
      </ModalContent>
    </Modal>
  );
}
