import { AxiosPromise } from 'axios';

import { Product } from 'typing/models';

import http from './http';

export const getCategories = (storeSlug: string):
  AxiosPromise<Product> => http.get(`/store/${storeSlug}/client/category/`);

export const getFeaturedCategories = (storeSlug: string):
    AxiosPromise<Product> => http.get(`/store/${storeSlug}/client/featured_categories/`);

export const getSideMenuCategories = (storeSlug: string):
    AxiosPromise<Product> => http.get(`/store/${storeSlug}/client/side_menu_categories/`);

export const getFrontViewCategories = (storeSlug: string):
    AxiosPromise<Product> => http.get(`/store/${storeSlug}/client/front_view_categories/`);
