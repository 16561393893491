import classNames from 'classnames';
import flatten from 'lodash/flatten';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import useSearchParams from 'hooks/useSearchParams';
import useUrls from 'hooks/useUrls';

import {
  getSideMenuCategories, getProducts, getSelectedCategory, getSelectedStore,
} from 'store/ducks';

import { Category } from 'typing/models';

import {
  SidebarCategoriesContainer,
  SidebarCategoriesInner,
  Heading,
  Title,
  List,
  ItemList,
  DropdownMenu,
  ItemListChild,
} from './SidebarCategories.styled';

interface SidebarCategoriesProps {
  fullCategories?: boolean;
  categoryTextSearch?: string;
}

export default function SidebarCategories({ fullCategories = true, categoryTextSearch = '' }: SidebarCategoriesProps):
  React.ReactElement {
  const { storeSlug } = useParams();
  const { getSearchUrl } = useUrls();
  const [searchValue] = useSearchParams(['value']);
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);

  const store = useSelector(getSelectedStore);

  const categories: Category[] = useSelector(getSideMenuCategories(store?.slug || storeSlug));
  const selected: Category | null = useSelector(getSelectedCategory);
  const products = useSelector(getProducts(storeSlug, selected?.id || -1));

  useEffect(() => {
    const productsCategories = flatten(
      products.map((product) => product.category_ids.map((ctg) => ctg.id)),
    );

    if (fullCategories) {
      setCategoriesList(categories);
    } else if (selected && !searchValue) {
      setCategoriesList([selected]);
    } else {
      setCategoriesList(categories.filter((ctg) => productsCategories.includes(ctg.id)));
    }
  }, [selected, categories?.length, products?.length]);

  const resultList = useMemo(() => {
    const normalize = (word: string):
      string => word.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    return categoriesList.filter((category) => {
      const categoryNameToken = normalize(category.name);
      const childrenNamesToken = normalize(category.categories_child_ids.map((child) => child.name).join(' '));

      return `${categoryNameToken} ${childrenNamesToken}`.includes(normalize(categoryTextSearch));
    });
  }, [categoriesList, categoryTextSearch]);

  return (
    <SidebarCategoriesContainer>
      <SidebarCategoriesInner>
        <Heading>
          <Title>Categorias.</Title>
        </Heading>
        <List>
          {
            store && resultList?.map((category) => (
              <ItemList
                key={category.id}
                className={classNames({
                  dropdown: !!category.categories_child_ids.length,
                  'dropdown-relative': !fullCategories,
                })}
              >
                <Link
                  className={`dropdown-item nav-link nav_item ${selected?.id === category.id ? 'active' : ''}`}
                  to={getSearchUrl(category)}
                >
                  <span>{category.name}</span>
                </Link>
                {
                  !!category.categories_child_ids.length && (
                    <DropdownMenu>
                      <List className="mega-menu d-lg-flex">
                        {
                          category.categories_child_ids.map((child) => (
                            <ItemListChild key={child.id}>
                              <Link to={getSearchUrl(category, child)}>
                                {child.name}
                              </Link>
                            </ItemListChild>
                          ))
                        }
                      </List>
                    </DropdownMenu>
                  )
                 }
              </ItemList>
            ))
          }
        </List>
      </SidebarCategoriesInner>
    </SidebarCategoriesContainer>
  );
}
