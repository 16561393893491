import get from 'lodash/get';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useSearchParams from 'hooks/useSearchParams';

import { getProductsFilters, getSelectedStore, isLoading } from 'store/ducks';
import { isSingleStore, getConfig } from 'store/ducks/base';
import {
  getLinkedProducts,
  getProducts,
  getSearchedProducts,
  listProductsRequest,
  selectProduct,
  getCampaignProducts,
} from 'store/ducks/products';

import { Product } from 'typing/models';

import { SEARCH_PRODUCTS_EMPTY_LIST_NOTICE } from 'utils/contants';
import { LIST_PRODUCTS } from 'utils/fetchs';

import ProductCard from '../ProductCard';
import ProductDetail from '../ProductDetail';
import SectionHeader from '../SectionHeader';
import SliderContainer from '../SliderContainer';
import {
  ProductSlider,
  ViewMoreContainer,
  Button,
  ButtonDisabled,
  NoResult,
} from './ProductsSlider.styled';

interface ProductsSliderProps {
  categoryId: number;
  categoryName: string;
}

export default function ProductsSlider({ categoryId, categoryName }: ProductsSliderProps):
  React.ReactElement {
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const elemRef = useRef<any>(null);
  const { campaignId, linkId } = useParams();
  const [childId, categoryIdParam] = useSearchParams(['childId', 'categoryId']);

  const productsFilter = useSelector(getProductsFilters('search'));
  const store = useSelector(getSelectedStore);
  const singleStore = useSelector(isSingleStore);
  const {
    search_products_empty_list_notice = SEARCH_PRODUCTS_EMPTY_LIST_NOTICE,
  } = get(useSelector(getConfig), 'config.labels');

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const fetchId = useMemo(
    () => (!page ? categoryId : `${categoryId}_${page}`), [categoryId, page],
  );

  useEffect(() => {
    if (page) {
      dispatch(listProductsRequest(categoryId, fetchId, page));
    }
  }, [page]);

  const storeSlug = useMemo(() => store?.slug || 'undefined', []);

  const loading = useSelector(isLoading(LIST_PRODUCTS, fetchId));

  const listProducts = () => {
    if (categoryIdParam) {
      return getProducts(storeSlug, categoryId);
    }

    if (linkId) {
      return getLinkedProducts(storeSlug, categoryId);
    }

    if (campaignId) {
      return getCampaignProducts(storeSlug, categoryId);
    }
    return getSearchedProducts(storeSlug, categoryId);
  };

  const products = useSelector(listProducts());

  useEffect(() => {
    if (childId && childId.toString() === categoryId) {
      setTimeout(() => {
        elemRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [elemRef.current, childId]);

  const nextPage = useMemo(() => {
    if ((page + 1) * 20 > products.length || !!productsFilter?.searchName) {
      return null;
    }
    return page + 1;
  }, [page, products]);

  const selectCurrentProduct = (product: Product): void => {
    if (product.has_variants) {
      dispatch(selectProduct(product));
      if (linkId) {
        if (singleStore) {
          history.push(`/link/${linkId}/product/${product.id}/`);
        } else {
          history.push(`/store/${storeSlug}/link/${linkId}product/${product.id}/`);
        }
      } else if (singleStore) {
        history.push(`/product/${product.id}/`);
      } else {
        history.push(`/store/${storeSlug}/product/${product.id}/`);
      }
    } else {
      setSelectedProduct(product);
    }
  };

  return (
    <ProductSlider ref={elemRef}>
      <SectionHeader title={categoryName} modifiers="SliderProducts" />
      <SliderContainer>
        <div style={{ display: 'flex', overflow: 'auto' }}>
          {
            (products.length || loading)
              ? products.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  selectProduct={(): void => selectCurrentProduct(product)}
                  modifiers
                />
              )) : (
                <NoResult>{search_products_empty_list_notice}</NoResult>
              )
          }
          {
            loading ? (
              <ViewMoreContainer>
                <ButtonDisabled>Carregando...</ButtonDisabled>
              </ViewMoreContainer>
            ) : (
              nextPage && (
                <ViewMoreContainer>
                  <Button onClick={(): void => { setPage(page + 1); }}>Ver mais</Button>
                </ViewMoreContainer>
              )
            )
          }
        </div>
      </SliderContainer>

      <ProductDetail
        product={selectedProduct}
        unselectProduct={(): void => setSelectedProduct(null)}
      />
    </ProductSlider>
  );
}
