import { ShoppingBag } from '@styled-icons/feather';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useUrls from 'hooks/useUrls';

import {
  isLogged, listOrdersRequest, getCompletedOrders, getPendingOrders, isLoading,
} from 'store/ducks';

import { Order } from 'typing/models';

import CardOrder from 'ui/components/CardOrder';
import {
  Page,
  PageHeader,
  Container,
  Title,
  PageBody,
  ButtonBack,
  TabHeader,
  NavTab,
  TabBody,
} from 'ui/components/Page';

import { LIST_ORDERS } from 'utils/fetchs';

import { PanelPedidos, Button, Date } from './Orders.styled';

export default function Orders(): React.ReactElement {
  const authorized = useSelector(isLogged);

  const loading = useSelector(isLoading(LIST_ORDERS));

  const [activeTab, setActiveTab] = useState('processing');

  const dispatch = useDispatch();
  const completedOrders: Order[] = useSelector(getCompletedOrders);
  const pendingOrders: Order[] = useSelector(getPendingOrders);

  useEffect(() => {
    if (authorized && (!completedOrders.length || !pendingOrders.length)) {
      dispatch(listOrdersRequest());
    }
  }, [authorized]);

  const emptyList = useMemo(
    ():
    boolean => !loading && ![...completedOrders, ...pendingOrders].length,
    [loading, completedOrders, pendingOrders],
  );

  return (
    <Page>
      <Container modifiers="Small">
        {
          authorized ? (
            <>
              <PageHeader>
                <ButtonBack to="/">Voltar</ButtonBack>
                { !emptyList && <Title>Meus pedidos</Title> }
              </PageHeader>
              {
                loading ? (
                  <p>Carregando...</p>
                ) : (
                  <PageBody>
                    <TabHeader>
                      <NavTab
                        onClick={(): void => setActiveTab('processing')}
                        modifiers={activeTab === 'processing' ? 'Active' : []}
                      >
                        Em andamento
                      </NavTab>
                      <NavTab
                        onClick={(): void => setActiveTab('done')}
                        modifiers={activeTab === 'done' ? 'Active' : []}
                      >
                        Finalizados
                      </NavTab>
                    </TabHeader>
                    <TabBody modifiers={activeTab === 'processing' ? 'Active' : []}>
                      {
                        !!pendingOrders.length && (
                          <>
                            {
                              pendingOrders.map((order) => (
                                <CardOrder key={order.id} order={order} />
                              ))
                            }
                          </>
                        )
                      }
                    </TabBody>
                    <TabBody modifiers={activeTab === 'done' ? 'Active' : []}>
                      {
                        !!completedOrders.length && (
                          <>
                            {
                              completedOrders.map((order) => (
                                <CardOrder key={order.id} order={order} />
                              ))
                            }
                          </>
                        )
                      }
                    </TabBody>
                    {
                      emptyList && (
                        <PanelPedidos>
                          <ShoppingBag />
                          <Title>Você ainda não realizou nenhum pedido</Title>
                          <Button to="/">Comece agora mesmo</Button>
                        </PanelPedidos>
                      )
                    }
                  </PageBody>
                )
              }
            </>
          ) : (
            <PanelPedidos>
              <ShoppingBag />
              <Title>Entre para acompanhar seus pedidos</Title>
              <Button to="/profile/login?next=/orders/">Entrar</Button>
            </PanelPedidos>
          )
        }
      </Container>
    </Page>
  );
}
