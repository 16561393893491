import { Filter } from '@styled-icons/feather';
import get from 'lodash/get';
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getProductsFilters, getSelectedFiltersProduct, getSelectedStore, isLoading,
} from 'store/ducks';
import { getConfig, isSingleStore } from 'store/ducks/base';
import {
  getAllProducts,
  listProductsRequest,
  selectProduct,
} from 'store/ducks/products';

import { Product } from 'typing/models';

import { SEARCH_PRODUCTS_EMPTY_LIST_NOTICE } from 'utils/contants';
import { LIST_PRODUCTS } from 'utils/fetchs';

import SettingsContext from '../../../context/SettingsContext';
import ProductCard from '../ProductCard';
import { Col, Row } from '../ProductDetail/ProductDetail.styled';
import {
  Heading, SectionHeaderContainer, Title, ViewAll,
} from '../SectionHeader/SectionHeader.styled';
import {
  Button,
  ButtonDisabled,
  ButtonFilter,
  NoResult,
  ProductSlider,
  ViewMoreContainer,
} from './ProductsHome.styled';

export default function ProductsHome(): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const { openModal } = useContext(SettingsContext);
  const elemRef = useRef<any>(null);
  const productsFilter = useSelector(getProductsFilters('home'));
  const selectedFilters = useSelector(getSelectedFiltersProduct);
  const store = useSelector(getSelectedStore);
  const singleStore = useSelector(isSingleStore);

  const { search_products_empty_list_notice = SEARCH_PRODUCTS_EMPTY_LIST_NOTICE } = get(
    useSelector(getConfig),
    'config.labels',
  );

  const [page, setPage] = useState(0);
  const [productsContainerTitle, setProductsContainerTitle] = useState('Últimos Lançamentos');

  const fetchId = useMemo(() => `home_${page}_filters`, [page]);

  const storeSlug = useMemo(() => store?.slug || 'undefined', []);

  const loading = useSelector(isLoading(LIST_PRODUCTS, fetchId));

  const listProducts = () => getAllProducts(storeSlug);

  const products = useSelector(listProducts());

  useEffect(() => {
    setPage(0);
    const timeout = setTimeout(() => {
      dispatch(listProductsRequest(null, fetchId, page));
    },
    300);
    return (): void => clearTimeout(timeout);
  }, [selectedFilters]);

  useEffect(() => {
    if (selectedFilters && selectedFilters.order) {
      const titleByOrder = {
        '-release_date': 'Mais recentes',
        release_date: 'Mais antigos',
        '-price': 'Maior preço',
        price: 'Menor preço',
        '-stock': 'Maior estoque',
        stock: 'Menor estoque',
      };
      setProductsContainerTitle(titleByOrder[selectedFilters.order] || selectedFilters.order);
    }
  }, [selectedFilters, page, fetchId]);

  useEffect(() => {
    const hypotheticPage = Math.floor(products.length / 20) - 1 || 0;
    if (page === hypotheticPage || (products.length > 0 && page === 0)) {
      setPage(hypotheticPage);
      return;
    }

    dispatch(listProductsRequest(null, fetchId, page));
  }, [page]);

  const nextPage = useMemo(() => {
    if ((page + 1) * 20 > products.length || !!productsFilter?.searchName) {
      return null;
    }
    return page + 1;
  }, [page, products]);

  const selectCurrentProduct = (product: Product): void => {
    dispatch(selectProduct(product));
    if (singleStore) {
      history.push(`/product/${product.id}/`);
    } else {
      history.push(`/store/${storeSlug}/product/${product.id}/`);
    }
  };

  return (
    <ProductSlider ref={elemRef}>
      <SectionHeaderContainer>
        <Heading>
          <Title>{productsContainerTitle}</Title>
        </Heading>
        <ViewAll>
          <ButtonFilter onClick={(): void => openModal && openModal('MODAL_FILTERS_PRODUCT')}>
            <Filter width={20} height={20} />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              Filtros
            </span>
          </ButtonFilter>
        </ViewAll>
      </SectionHeaderContainer>

      <Row className="row">
        {products.map((product) => (
          <Col
            className="col-6 col-sm-6 col-lg-3 col-md-6 mb-4 mb-md-0"
            key={product.id}
            style={{
              display: 'flex',
            }}
          >
            <ProductCard
              key={product.id}
              product={product}
              selectProduct={(): void => selectCurrentProduct(product)}
              modifiers
            />
          </Col>
        ))}

        {!loading && !products.length && <NoResult>{search_products_empty_list_notice}</NoResult> }
      </Row>

      {loading ? (
        <ViewMoreContainer>
          <ButtonDisabled>Carregando...</ButtonDisabled>
        </ViewMoreContainer>
      ) : (
        Boolean(nextPage) && (
        <ViewMoreContainer>
          <Button
            onClick={(): void => {
              setPage(page + 1);
            }}
          >
            Ver mais
          </Button>
        </ViewMoreContainer>
        )
      )}
    </ProductSlider>
  );
}
