import React from 'react';

import { Store } from 'typing/models';

import {
  StoreCardContainer,
  Thumbnail,
  ThumbnailInner,
  Image,
  Info,
  Title,
  Text,
} from './StoreCard.styled';

export interface StoreCardProps {
  store: Store
}

export default function StoreCard(
  { store }: StoreCardProps,
  { modifiers }: any,
): React.ReactElement {
  const labelList: string[] = [];

  if (store?.address?.district) {
    labelList.push(store.address?.district);
  }

  if (store?.distance) {
    labelList.push(`${(store.distance / 1000).toFixed(1)}km`);
  }

  return (
    <StoreCardContainer to={`/store/${store.slug}`} modifiers={modifiers}>
      <Thumbnail>
        <ThumbnailInner>
          <Image src={store.image_logo || ''} alt={`${store.name}`} />
        </ThumbnailInner>
      </Thumbnail>
      <Info>
        <Title>{store.name}</Title>
        <Text>{labelList.join(' · ')}</Text>
      </Info>
    </StoreCardContainer>
  );
}
