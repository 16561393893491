import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getBanners } from 'store/ducks/base';

import {
  BannersHomeContainer,
  Container,
  Row,
  Item,
  Banner,
  Image,
} from './BannersHome.styled';

export default function BannersHome(): React.ReactElement {
  const banners = useSelector(getBanners('info-banners'));

  const orderedBanners = useMemo(() => orderBy(banners, 'order'), [banners]);

  return (
    <BannersHomeContainer>
      <Container>
        <Row>
          {
            orderedBanners.map(({
              banner_image: image,
            }, index) => (
              <Item key={index}>
                <Banner>
                  <Image src={image || ''} />
                </Banner>
              </Item>
            ))
        }
        </Row>
      </Container>
    </BannersHomeContainer>
  );
}
