import React from 'react';
import { useSelector } from 'react-redux';

import { getCart, getCartSubTotal } from 'store/ducks';
import { getItemUnitValue } from 'store/ducks/products';

import Table from 'ui/components/Table';

import { numberToMoney } from 'utils/monetary';

import {
  CartReviewContainer,
  ProductQty,
} from './CartReview.styled';

export default function CartReview(): React.ReactElement {
  const {
    items, service_fee, delivery_fee, shipping_method,
  } = useSelector(getCart);
  const subTotal = useSelector(getCartSubTotal);

  return (
    <CartReviewContainer>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Heading>Produto</Table.Heading>
            <Table.Heading>
              <div className="text-right">
                Total
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            items.map((item) => (
              <Table.Row key={item.product.id || item.product.product_id}>
                <Table.Item>
                  <ProductQty>
                    {item.quantity}
                    x
                  </ProductQty>
                  {' '}
                  {item.product.name}
                  {
                    item.product.has_variants && (
                      <div>
                        {
                          item.product.product_child.child_attribute_values
                            .filter((attrValue) => attrValue.attribute_code !== 'PERSONA' && attrValue.attribute_code !== 'COLECAO')
                            .map((attrValue) => attrValue.value)
                            .join(' - ')
                        }
                      </div>
                    )
                  }

                </Table.Item>
                <Table.Item>
                  <div className="text-right">{numberToMoney(getItemUnitValue(item.product) * item.quantity)}</div>
                </Table.Item>
              </Table.Row>
            ))
          }
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Heading>Sub Total</Table.Heading>
            <Table.Item>
              <div className="text-right">
                {numberToMoney(subTotal)}
              </div>
            </Table.Item>
          </Table.Row>
          <Table.Row>
            <Table.Heading>
              Taxa de entrega (
              {shipping_method?.description}
              )
            </Table.Heading>
            <Table.Item>
              <div className="text-right">
                {delivery_fee ? numberToMoney(delivery_fee) : 'GRATUITO'}
              </div>
            </Table.Item>
          </Table.Row>
          {
            !!service_fee && (
              <Table.Row>
                <Table.Heading>Taxa de serviço</Table.Heading>
                <Table.Item>
                  <div className="text-right">
                    {numberToMoney(service_fee)}
                  </div>
                </Table.Item>
              </Table.Row>
            )
          }
          <Table.Row>
            <Table.Heading>Total</Table.Heading>
            <Table.Item className="product-subtotal">
              <div className="text-right">
                {numberToMoney(subTotal + delivery_fee + service_fee)}
              </div>
            </Table.Item>
          </Table.Row>
        </Table.Footer>
      </Table>
    </CartReviewContainer>
  );
}
