import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import { CHECKOUT } from 'context/SettingsContext';

import useNavControl from 'hooks/useNavControl';

import {
  getCartState,
  hasError,
  isLoading,
  buyCartRequest,
  isSuccess,
} from 'store/ducks';

import LoaderBubble from 'ui/components/LoaderBubble';

import { BUY_CART } from 'utils/fetchs';

import { Page } from './CartProcessing.styled';

export default function CartProcessing(): React.ReactElement {
  useNavControl(CHECKOUT);

  const history = useHistory();

  const dispatch = useDispatch();

  const error = useSelector(hasError(BUY_CART));
  const loading = useSelector(isLoading(BUY_CART));
  const success = useSelector(isSuccess(BUY_CART));

  const { data, verifiedCart } = useSelector(getCartState);

  const orderId = _.get(history.location.state, 'orderId', '');

  useEffect(() => {
    // Verifica se o pedido é o mesmo que está no carrinho para executar a compra

    if (data.id === orderId.toString()) {
      if (data.id !== orderId.toString()) {
        history.push('/orders');
      }

      const cards = _.get(history.location.state, 'cards', []);
      const captchaToken = _.get(history.location.state, 'captchaToken', '');

      if (!captchaToken) {
        history.push('/checkout', {
          cards, screen: 'CART',
        });
      }
      if (data.id && verifiedCart) {
        dispatch(buyCartRequest({
          cards,
          captchaToken,
        }));
      } else if ((data.response_credit_cards !== null && cards.length > 0)
        || data.response_message) {
        setTimeout(() => {
          history.push('/checkout', {
            cards, screen: 'CART',
          });
        }, 2000);
      } else if (data.id) {
        history.push('/checkout/fix');
      }
    }
  }, [verifiedCart, history, data.id]);

  useEffect(() => {
    if ((success && data) && !loading) {
      history.push(`/orders/${orderId}`);
    }
  }, [history, success, loading, orderId]);

  if (!orderId) {
    return <Redirect to="/orders" />;
  }

  const getTitle = () => {
    if (loading) {
      return 'Aguarde enquanto processamos seu pedido...';
    } if (error) {
      return 'Ops! Algo deu errado...';
    } if (success) {
      return `Pronto! Pedido #${orderId} aceito!`;
    }
  };

  const getError = () => {
    if (error) {
      return _.get(data.response_credit_cards, 'payment', []).map((error: any) => (
        error.message
      )).join(', ');
    }
    return 'Isso pode ter ocorrido por um erro de conexão ou por um problema com o seu método de pagamento.';
  };
  return (
    <Page>
      <h1>{getTitle()}</h1>

      {loading && (
      <>
        <p>Quase lá, estamos processando seu pedido.</p>
        <LoaderBubble />
      </>
      )}
      {error && (
        <span className="alert alert-danger" role="alert">
          Ocorreu um erro ao processar o pagamento do seu pedido.
          <br />
          {getError()}
          <br />
          Em 3 segundos você será redirecionado para a tela de pagamento para mais detalhes.
        </span>
      )}
    </Page>
  );
}
