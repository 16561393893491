import styled from 'styled-components';

export const Row = styled.div`
`;

export const Col = styled.div`
`;

export const ColProducts = styled.div.attrs({
  className: 'col-lg-9',
})`
  padding-left: 0;
  padding-right: 0;
`;

export const Sidebar = styled.aside`
`;

export const Container = styled.div.attrs({
  className: 'container',
})`
`;
