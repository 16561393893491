import { AxiosPromise } from 'axios';

import { Address } from 'typing/models';
import { AddressLocationResponseBody, AddressResponseBody } from 'typing/request';

import http from './http';

export const getAddressByCEP = (cepCode: string):
  AxiosPromise<AddressResponseBody> => http.get('/client/cep-to-adddress/', { params: { postal_code: cepCode } });

export const getAddressByLocation = (latitude: number, longitude: number):
  AxiosPromise<AddressLocationResponseBody> => http.get(
  '/client/utils/reverse-geocode/',
  { params: { latitude, longitude } },
);

export const getLocationByAddress = (address: string):
  AxiosPromise<AddressLocationResponseBody> => http.get(
  '/client/utils/geocode/',
  { params: { address_name: address } },
);

export const createAddress = (address: Address): AxiosPromise<any> => http.post('/client/address/', address);
export const updateAddress = (address: Address): AxiosPromise<any> => http.put(`/client/address/${address.id}/`, address);
export const deleteAddress = (address: Address): AxiosPromise<any> => http.delete(`/client/address/${address.id}/`);
