import produce from 'immer';
import { ActionMap, ActionMeta } from 'redux-actions';

import { BaseState, FetchCheckState } from 'typing/store';

export enum fetchState {
    REQUEST = 'REQUEST',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    CLEAN = 'CLEAN',
}

export interface FetchMeta {
  id?: string | number
}

const checkFetchReducerCreator = (stateKey: fetchState) => (
  state: FetchCheckState = {}, action: ActionMeta<any, FetchMeta>,
): FetchCheckState => {
  const { type, meta } = action;

  const matches = /(.*)\/(.*)_(REQUEST|SUCCESS|FAILURE|CLEAN)/.exec(type);

  if (!matches) return state;

  const [,,, requestState] = matches;
  let [,, requestName] = matches;

  if (meta && meta.id) {
    requestName = `${requestName}_${meta.id}`;
  }

  return produce(state, (draft) => {
    if (requestState === 'CLEAN') {
      delete draft[requestName];
    } else {
      draft[requestName] = requestState === stateKey;
    }
  });
};

export const verifyFetchSituation = (situation: 'loading' | 'error' | 'success') => (key: symbol, fetchId?: number | string) => (
  state: BaseState,
): boolean | undefined => {
  const fetchActionName = fetchId ? `${Symbol.keyFor(key) || '-'}_${fetchId}` : Symbol.keyFor(key) || '-';
  return state[situation][fetchActionName] || false;
};

export const fetchStateGenerator = (fetchName: symbol): string[] => ['REQUEST', 'SUCCESS', 'FAILURE', 'CLEAN']
  .map<string>((state: string) => `${Symbol.keyFor(fetchName)}_${state}`);

export const fetchStateWithMetaGenerator = (fetchName: symbol):
  ActionMap<unknown, number | string | undefined> => fetchStateGenerator(fetchName)
  .reduce<ActionMap<unknown, number | string>>((mapper, actionName: string) => {
    mapper[actionName] = [
      (payload: unknown): unknown => payload,
      (payload: any, id: number | string | undefined, page: number | undefined):
        any => ({ id, page }),
    ];
    return mapper;
  }, {});

export default checkFetchReducerCreator;
