import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const ProfileMenuContainer = styled.nav`
`;

export const ListMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  font-size: ${rem('18px')};
  padding: .75rem 0;
  position: relative;
  &:not(last-child) {
    border-bottom: 1px solid #EEE;
    @media ${media.tabletMax} {
      border: none;
    }
  }
  &:after {
    content: '>';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  @media ${media.tabletMin} {
    font-size: ${rem('20px')};
    padding: 1rem 0;
  }
  @media ${media.tabletMax} {
    font-size: ${rem('16px')};
    padding: .5rem 0;
  }
  a {
    display: block;
    color: #292b2c;
    &:hover, &:active {
      color: ${(props) => props.theme.primary.main};
    }
  }
`;
