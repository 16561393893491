import get from 'lodash/get';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useUrls from 'hooks/useUrls';

import { getFeaturedCategories, getSelectedStore, isLogged } from 'store/ducks';
import { getConfig, getMarketPlaceCode } from 'store/ducks/base';

import { Category } from 'typing/models';

import SliderContainer from 'ui/components/SliderContainer';

import { getCategoryImageUrl } from 'utils/image';

import {
  CarouselCategoriesContainer,
  Container,
  Item,
  ItemInner,
  ItemLink,
  ItemThumbnail,
  ItemImage,
  CarouselSlider,
} from './CarouselCategories.styled';

export default function CarouselCategories(): React.ReactElement {
  const { getSearchUrl, getLinksUrl } = useUrls();
  const selectedStore = useSelector(getSelectedStore);

  const storeSlug = useMemo(() => selectedStore?.slug || 'undefined', [selectedStore]);

  const categories: Category[] = useSelector(getFeaturedCategories(storeSlug));
  const { image_category_placeholder: placeholder } = useSelector(getConfig);
  const marketplace = useSelector(getMarketPlaceCode);
  const authenticated = useSelector(isLogged);

  const selectedProductsCategory = authenticated && get(selectedStore, 'config.selected_products');

  return (
    <CarouselCategoriesContainer>
      <Container>
        <SliderContainer>
          <CarouselSlider>
            {
              (selectedProductsCategory && selectedProductsCategory.state === 'active') && (
                <Item key="first">
                  <ItemInner>
                    <ItemLink to={getLinksUrl()}>
                      <ItemThumbnail>
                        <img src={selectedProductsCategory.category_image} />
                      </ItemThumbnail>
                      <span>Selecionados para você</span>
                    </ItemLink>
                  </ItemInner>
                </Item>
              )
            }
            {
              categories.map((category, index) => (
                <Item key={category.id}>
                  <ItemInner>
                    <ItemLink to={getSearchUrl(category)}>
                      <ItemThumbnail>
                        <ItemImage
                          src={category.image || getCategoryImageUrl(storeSlug, category.id, marketplace) || ''}
                          {...{ placeholder: placeholder ?? undefined }}
                        />
                      </ItemThumbnail>
                      <span>{category.name}</span>
                    </ItemLink>
                  </ItemInner>
                </Item>
              ))
            }
          </CarouselSlider>
        </SliderContainer>
      </Container>
    </CarouselCategoriesContainer>
  );
}
