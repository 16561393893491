import classNames from 'classnames';
import get from 'lodash/get';
import React, { useCallback, useState, useMemo } from 'react';
import Form from 'react-formal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { REGULAR } from 'context/SettingsContext';

import { useCheckFetch } from 'hooks';
import useNavControl from 'hooks/useNavControl';

import { recoverPasswordRequest, getTemporarySuccess } from 'store/ducks/user';

import { Page, Container } from 'ui/components/Page';

import { RECOVER_PASSWORD } from 'utils/fetchs';
import { cpfMask } from 'utils/masks';
import { recoverPasswordSchema, RecoverPasswordSchemaType } from 'utils/schemas';

import {
  LoginWrapper,
  LoginInner,
  Heading,
  FormGroup,
  FormNote,
} from '../Login/Login.styled';

export default function ResetPassword(): React.ReactElement {
  useNavControl(REGULAR);

  const dispatch = useDispatch();

  const [data, setData] = useState<RecoverPasswordSchemaType | undefined>({
    email: '', type: 'email', identifier_code: '',
  });

  const [loading, success, error] = useCheckFetch(RECOVER_PASSWORD);
  const temporarySuccess = useSelector(getTemporarySuccess);

  const recoverPassword = useCallback(() => {
    const type = get(data, 'type');
    const identifier_code = get(data, 'identifier_code');
    const email = get(data, 'email');

    if (type === 'email') {
      dispatch(recoverPasswordRequest({ email }));
    } else {
      dispatch(recoverPasswordRequest({ identifier_code }));
    }
  }, [data]);

  const maskedEmail = useMemo(() => {
    if (temporarySuccess && temporarySuccess.masked_email) {
      return temporarySuccess.masked_email;
    }
    return undefined;
  }, [temporarySuccess]);

  return (
    <Page>
      <Container modifiers="Small">
        <LoginWrapper>
          <LoginInner>
            {
              (success || error) ? (
                <Heading>
                  <h2>Obrigado!</h2>
                  <p>
                    Por favor, verifique seu e-mail.
                    {' '}
                    {maskedEmail ? (
                      <>
                        Enviaremos para
                        {' '}
                        {maskedEmail}
                        {' '}
                        uma mensagem de recuperação.
                      </>
                    ) : <> Enviaremos uma mensagem de recuperação para você.</>}

                  </p>
                </Heading>
              ) : (
                <>
                  <Heading>
                    <h2>Recuperar senha</h2>
                    <p>
                      Digite seu e-mail de cadastro.
                      Enviaremos um e-mail de recuperação.
                    </p>
                  </Heading>
                  <Form
                    autoComplete="off"
                    schema={recoverPasswordSchema}
                    value={data}
                    onSubmit={recoverPassword}
                    onChange={(model: any): void => {
                      model.identifier_code = cpfMask(model.identifier_code);
                      setData(model);
                    }}
                  >
                    <fieldset>
                      <FormGroup>
                        <Form.Field
                          name="type"
                          placeholder="Tipo"
                          type="radio"
                          disabled={loading}
                          value="email"
                        />
                        {' '}
                        E-mail
                        <Form.Field
                          name="type"
                          style={{ marginLeft: '10px' }}
                          placeholder="Tipo"
                          type="radio"
                          disabled={loading}
                          value="cpf"
                        />
                        {' '}
                        CPF
                      </FormGroup>
                      {
                        get(data, 'type') === 'email' ? (
                          <FormGroup>
                            <Form.Field
                              name="email"
                              placeholder="E-mail"
                              className="form-control"
                              type="email"
                              disabled={loading}
                            />
                            <Form.Message for="email" />
                          </FormGroup>
                        ) : (
                          <FormGroup>
                            <Form.Field
                              name="identifier_code"
                              placeholder="CPF"
                              className="form-control"
                              disabled={loading}
                            />
                            <Form.Message for="identifier_code" />
                          </FormGroup>
                        )
                      }
                    </fieldset>
                    <FormGroup>
                      <Form.Submit
                        type="submit"
                        className={classNames({
                          'btn btn-fill-out btn-block': true,
                          disabled: loading || !recoverPasswordSchema.isValidSync(data),
                        })}
                      >
                        Enviar
                      </Form.Submit>
                    </FormGroup>
                  </Form>
                </>
              )
            }

            {/* Nova senha */}
            {/* <Heading>
              <h2>Nova senha</h2>
            </Heading>
            <form>
              <fieldset>
                <FormGroup>
                  <Form.Field
                  name="password"
                   placeholder="Senha" className="form-control" type="password" />
                </FormGroup>
                <FormGroup>
                  <Form.Field
                  name="repeat_password"
                  placeholder="Confirmar senha"
                  className="form-control" type="password" />
                </FormGroup>
              </fieldset>
              <FormGroup>
                <Form.Submit
                  type="submit"
                  className={classNames({
                    'btn btn-fill-out btn-block': true,
                  })}
                >
                  Cadastrar nova senha
                </Form.Submit>
              </FormGroup>
            </form> */}

            {/* Nova senha cadastrada */}
            {/* <Heading>
              <h2>Nova senha cadastrada com sucesso!</h2>
              <br />
              <p>
                <Link className="btn btn-border-fill btn-radius" to="/">Clique para continuar</Link>
              </p>
            </Heading> */}

          </LoginInner>
        </LoginWrapper>
        <FormNote>
          Já possui conta?
          {' '}
          <Link to="/profile/login">Entrar</Link>
        </FormNote>
      </Container>
    </Page>
  );
}
