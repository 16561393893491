import { rem } from 'polished';
import styled from 'styled-components';

export const Captcha = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepCheckout = styled.div`
`;

export const StepItem = styled.div`
`;

export const StepCompleted = styled.a`
  background-color: #f7f7f7;
  border-radius: 16px;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-height: ${rem('60px')};
  padding: 1rem;
  position: relative;
  
  svg {
    height: ${rem('24px')};
    margin-right: .5rem;
    stroke: #06C167;
    stroke-width: 3px;
    width: ${rem('24px')};
  }

  h2 {
    font-size: ${rem('20px')};
    font-weight: bold;
    margin: 0;
  }

  span {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translateY(-50%);
  }

`;

export const StepContainer = styled.div`
  margin-bottom: 1rem;
  .order_review {
    margin: 0;
    padding: 0;
  }
`;

export const StepButton = styled.button.attrs({
  className: 'btn btn-fill-out btn-block',
})`
`;

export const Button = styled.a.attrs({
  className: 'btn btn-fill-out btn-block',
})`
`;

export const ButtonDisabled = styled.a.attrs({
  className: 'btn btn-fill-out btn-block disabled',
})`
`;
