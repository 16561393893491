import React from 'react';

import useLoadStore from '../../../hooks/useLoadStore';
import CartPayment from './CartPayment';
import CartPaymentDeliveryMyAddress from './CartPaymentDeliveryMyAddress';

export default function CartPaymentContainer(): React.ReactElement {
  const { store } = useLoadStore();
  const use_template_delivery_to_my_address = store?.config?.use_template_delivery_to_my_address;

  return (
    <>
      {
        use_template_delivery_to_my_address ? <CartPaymentDeliveryMyAddress /> : <CartPayment />
      }
    </>
  );
}
