import { rem } from 'polished';
import styled from 'styled-components';

import { media } from 'utils/themeHelper';

export const BannersHomeContainer = styled.section.attrs({
  className: 'section pb_20 small_pt',
})`
  margin: 0 0 2rem 1rem;
  padding-top: 0 !important;
  @media ${media.tabletMin} {
    margin: 0 0 1rem 2rem;
  }
  @media ${media.tabletMax} {
    margin: 0;
  }
`;

export const Container = styled.div`
  @media ${media.tabletMax} {
    margin: 0 auto;
    max-width: 960px;
  }
  @media ${media.desktopMin} {
    padding: 0 1rem;
    max-width: 1140px;
  }
`;

export const Row = styled.div`
  @media ${media.tabletMin} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Item = styled.div`
  margin-bottom: 1rem;
  margin-right: 1rem;
  min-width: ${rem('335px')};
  @media ${media.tabletMin} {
    min-width: calc(100% / 3 - 1rem);
  }
  @media ${media.tabletMax} {
    margin-right: 1.5rem;
    min-width: calc(100% / 3 - 1rem);
  }
`;

export const Banner = styled.div`
`;

export const Image = styled.img.attrs({
  className: '',
})`
  height: auto;
  width: 100%;
`;
