import styled from 'styled-components';

export const PriceContainer = styled.div.attrs({
  className: 'product_price',
})`
  display: flex;
  flex-direction: column-reverse;
  del {
    margin: 0;
  }
`;

export const Price = styled.span.attrs({
  className: 'price',
})`
`;
