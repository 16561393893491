import React from 'react';

import {
  TableContainer, TableResponsive, Tbody, Tfoot, Thead, Td, Th, Tr,
} from './Table.styled';

export default function Table({ children }: any): React.ReactElement {
  return (
    <TableContainer>
      <TableResponsive>
        {children}
      </TableResponsive>
    </TableContainer>
  );
}

Table.Header = Thead;
Table.Body = Tbody;
Table.Footer = Tfoot;
Table.Row = Tr;
Table.Heading = Th;
Table.Item = Td;
