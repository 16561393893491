import React, { useContext } from 'react';

import {
  SectionHeaderContainer,
  Heading,
  Title,
  ViewAll,
  HeaderLink,
} from './SectionHeader.styled';

export default function SectionHeader({ title, link, modifiers }: any): React.ReactElement {
  return (
    <SectionHeaderContainer modifiers={modifiers}>
      <Heading>
        <Title>{title}</Title>
      </Heading>
      {
        link && (
          <ViewAll>
            <HeaderLink to={link}>
              <span>Ver mais</span>
            </HeaderLink>
          </ViewAll>
        )
      }
    </SectionHeaderContainer>
  );
}
