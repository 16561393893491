import React from 'react';

import { Product } from 'typing/models';

import { numberToMoney } from 'utils/monetary';

import { Price, PriceContainer } from './ProductPrice.styled';

interface ProductPriceProps {
  product: Product
}

export default function ProductPrice({ product }: ProductPriceProps) {
  return (
    <PriceContainer>
      {
    product.promo_price ? (
      <>
        <Price>{numberToMoney(product.promo_price)}</Price>
        <del>
          {numberToMoney(product.base_price)}
        </del>
      </>
    ) : (
      <Price>{numberToMoney(product.base_price)}</Price>
    )
  }
    </PriceContainer>
  );
}
