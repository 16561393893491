import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import { getFeaturedCategories } from 'services/api/categories';

import {
  listFrontViewCategoriesRequest,
  listFrontViewCategoriesSuccess,
  listFrontViewCategoriesFailure,
} from 'store/ducks';

function* getFrontViewCategoriesAsync({ payload }: Action<string>): SagaIterator {
  try {
    const response = yield call(getFeaturedCategories, payload);
    yield put(listFrontViewCategoriesSuccess(response));
  } catch (e) {
    yield put(listFrontViewCategoriesFailure(e));
  }
}

export default function* watchFrontViewCategoriesActions(): SagaIterator {
  yield takeEvery(listFrontViewCategoriesRequest.toString(), getFrontViewCategoriesAsync);
}
