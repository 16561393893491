import { Check } from '@styled-icons/feather';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { numberToMoney } from 'utils/monetary';

import {
  getCurrentShippingMethod,
  setShippingMethod,
} from '../../../store/ducks/cart';
import SpinnerLoading from '../SpinnerLoading';
import { CardShippingMethodContainer } from './ShippingMethod.styled';
import { ShippingMethod } from '../../../typing/models';

interface ShippingMethodProps {
  clicked?: boolean;
  loading: boolean;
  listOptions: ShippingMethod[];
}

export default function ListShippingMethod({
  clicked, loading, listOptions,
}: ShippingMethodProps): React.ReactElement {
  const currentShipping = useSelector(getCurrentShippingMethod);
  const dispatch = useDispatch();
  const hasInListOptions = _.find(listOptions, { identifier: currentShipping?.identifier });

  return (
    <>
      {
        loading && (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            <SpinnerLoading />
          </div>
        )
      }

      {
        !loading && listOptions.map((method) => (
          <CardShippingMethodContainer
            key={method.identifier}
            onClick={(): void => {
              const options = _.get(method, 'options', []);
              if (options.length > 0) {
                const selected_options = {};
                options.forEach((option) => {
                  const items = _.get(option, 'items', []);
                  const identifier = _.get(items[0], 'identifier', '');

                  selected_options[option.identifier] = identifier;
                });

                dispatch(setShippingMethod({
                  ...method,
                  selected_options: {
                    ...selected_options,
                  },
                }));
              } else {
                dispatch(setShippingMethod(method));
              }
            }}
            modifiers={method.identifier === currentShipping?.identifier ? 'Active' : []}
          >
            <>
              <Check />
              {
                method.kind === 'delivery_my_address'
                  ? (
                    <span>
                      {' '}
                      {method.description}
                      {' '}
                    </span>
                  )
                  : (
                    <span>
                      {`${method.amount ? numberToMoney(method.amount) : 'GRATUITO'} - ${method.description} - ${method.delivery_time}`}
                    </span>
                  )
              }
            </>
          </CardShippingMethodContainer>
        ))
      }

      {
        hasInListOptions && !loading && currentShipping?.kind === 'composite' && (
          (currentShipping.options || []).map((option) => {
            const selected = _.get(currentShipping, `selected_options.${option.identifier}`, undefined);
            const optionsSelect = option.items.map((item) => ({
              value: item.identifier,
              label: item.description,
            }));
            const optionSelected = _.find(optionsSelect, { value: selected });

            return (
              <div className="form-row ml-3 mr-3" key={option.identifier}>
                <div className="form-group col-md-12">
                  <label htmlFor={option.identifier}>{option.title}</label>
                  <Select
                    placeholder="Selecione uma opção..."
                    noOptionsMessage={(v) => `Nenhum resultado para "${v.inputValue}"...`}
                    defaultValue={optionSelected}
                    menuPlacement="auto"
                    className="basic-select-shipping"
                    classNamePrefix="select-shipping"
                    isSearchable
                    options={optionsSelect}
                    onChange={(item: any, actionMeta: any): void => {
                      const { value } = item;
                      const selected_options = {
                        ..._.get(currentShipping, 'selected_options', {}),
                        [option.identifier]: value,
                      };

                      if (value === '0') {
                        delete selected_options[option.identifier];
                      }

                      dispatch(setShippingMethod({
                        ...currentShipping,
                        selected_options: {
                          ...selected_options,
                        },
                      }));
                    }}
                  />
                  {
                    clicked && !selected && (
                      <span>
                        O campo
                        {' '}
                        { option.title }
                        {' '}
                        é obrigatório
                      </span>
                    )
                  }
                </div>
              </div>
            );
          })
        )
      }
    </>
  );
}
