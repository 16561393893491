import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { rem } from 'polished';

import { media } from 'utils/themeHelper';
import { utils } from 'typing';

const SectionHeaderModifiers = {
  SliderProducts: () => `
    &.heading_tab_header {
      .heading_s2 {
        width: calc(100% - 4rem);
        @media ${media.mobileMax} {
          width: auto;
        }
        h2 {
          font-size: ${rem('18px')};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-transform: uppercase;
          @media ${media.mobileMax} {
            font-size: ${rem('22px')};
          }
        }
      }
    }
  `,
};

export const SectionHeaderContainer = styled.section.attrs({
  className: 'heading_tab_header',
})<utils.WithModifiers>`
${applyStyleModifiers(SectionHeaderModifiers)};

`;

export const Heading = styled.div.attrs({
  className: 'heading_s2',
})`
`;

export const Title = styled.h2.attrs({
  className: '',
})`
`;

export const ViewAll = styled.div.attrs({
  className: 'view_all',
})`
`;

export const HeaderLink = styled(Link).attrs({
  className: 'text_default',
})`
`;
