import { rem } from 'polished';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { utils } from 'typing';

import { media } from 'utils/themeHelper';

const SearchModalModifiers = {
  ShowSearchModal: () => `
    display: block;
  `,
};

export const SearchModalContainer = styled.div.attrs({
})<utils.WithModifiers>`
  background-color: white;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  width: 100%;
  z-index: 99999;

  .search_input {
    display: block;
    margin-bottom: 2rem;
  }

  .widget-categories {
    display: block;
  }

  @media ${media.tabletMax} {
    display: none;
  }

  ${applyStyleModifiers(SearchModalModifiers)};

`;

export const SearchModalInner = styled.div`
  padding: ${rem('15px')};
`;
