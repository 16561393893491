const baseUrl = process.env.REACT_APP_API_URL;

export const getProductImageUrl = (
  placeSlug: string, productId: number | string, marketplace: string | null,
):
  string => `${baseUrl}/store/${placeSlug}/client/product/${productId}/default-image/?Marketplace-Code=${marketplace}`;

export const getCategoryImageUrl = (
  placeSlug: string, categoryId: number, marketplace: string | null,
):
  string => `${baseUrl}/store/${placeSlug}/client/category/${categoryId}/image/?Marketplace-Code=${marketplace}`;
