import React from 'react';
import { Link } from 'react-router-dom';

import { CHECKOUT } from 'context/SettingsContext';

import useNavControl from 'hooks/useNavControl';

export default function CartConfirmation(): React.ReactElement {
  useNavControl(CHECKOUT);

  return (
    <div style={{ marginTop: 100 }}>
      <p>Finalizar Pedido</p>
      <Link to="/orders/123">Finalizar Pedido</Link>
    </div>
  );
}
