import React, { useContext } from 'react';

import SettingsContext from 'context/SettingsContext';

import InvalidStore from '../InvalidStore';
import Modal from '../Modal';

const children = {
  INVALID_STORE: InvalidStore,
};

export default function Warning(): React.ReactElement {
  const {
    warning: {
      active, inactiveWarning, type, args,
    },
  } = useContext(SettingsContext);

  const Child = type && children[type];

  return (
    <>
      {active && inactiveWarning && (
        <Modal close={inactiveWarning} modifiers={[active ? 'ShowModal' : '']}>
          { Child ? <Child {...args} /> : '' }
        </Modal>
      )}
    </>
  );
}
