import produce from 'immer';
import { createActions, handleActions } from 'redux-actions';

import { Campaign, Link, Store } from 'typing/models';
import { AbstractPayload, BaseState, StoresState } from 'typing/store';

import {
  LIST_STORES, GET_STORE, LIST_CAMPAIGNS, LIST_LINKS,
} from 'utils/fetchs';

import { isSingleStore } from './base';
import { fetchStateGenerator } from './checkFetchReducerCreator';

export const {
  listStoresRequest,
  listStoresSuccess,
  listStoresFailure,
  listStoresClean,
  getStoreRequest,
  getStoreSuccess,
  getStoreFailure,
  getStoreClean,
  listCampaignsRequest,
  listCampaignsSuccess,
  listCampaignsFailure,
  listCampaignsClean,
  listLinksRequest,
  listLinksSuccess,
  listLinksFailure,
  listLinksClean,
  selectStore,
  unselectStore,
} = createActions(
  {},
  ...fetchStateGenerator(LIST_STORES),
  ...fetchStateGenerator(GET_STORE),
  ...fetchStateGenerator(LIST_CAMPAIGNS),
  ...fetchStateGenerator(LIST_LINKS),
  'SELECT_STORE',
  'UNSELECT_STORE',
  { prefix: 'STORE' },
);

const INITIAL_STATE = {
  list: {
    data: [],
  },
  detail: null,
  extra: {},
};

export const getSelectedStore = (state: BaseState): Store | null => state.stores.detail;

export const getStores = (state: BaseState): Store[] => state.stores.list.data;
export const getCampaigns = (state: BaseState): Campaign[] => state.stores.extra.campaigns || [];
export const getLinks = (state: BaseState): Link[] => state.stores.extra.links || [];

export const getStoreBySlug = (storeSlug: string) => (state: BaseState):
  Store | null | undefined => {
  if (isSingleStore(state)) {
    return state.stores.detail;
  }

  return [...state.stores.list.data, state.stores.detail].find(
    (store) => store && store.slug === storeSlug,
  );
};

const resolveSelectStore = produce((draft: StoresState, { payload }) => {
  draft.detail = payload;
});

export default handleActions<StoresState, AbstractPayload>({
  [listStoresSuccess.toString()]: produce(
    (draft: StoresState, { payload }) => {
      draft.list.data = payload;
    },
  ),
  [listCampaignsSuccess.toString()]: produce(
    (draft: StoresState, { payload }) => {
      draft.extra.campaigns = payload;
    },
  ),
  [listLinksSuccess.toString()]: produce(
    (draft: StoresState, { payload }) => {
      draft.extra.links = payload;
    },
  ),
  [getStoreSuccess.toString()]: resolveSelectStore,
  [selectStore.toString()]: resolveSelectStore,
  [unselectStore.toString()]: produce((draft: StoresState) => {
    draft.detail = null;
    draft.extra = {};
  }),
}, INITIAL_STATE);
