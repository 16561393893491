import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingsContext from 'context/SettingsContext';

import { getAddressByCepSuccess, isLogged } from 'store/ducks';
import { getAddresses, updateAddressRequest } from 'store/ducks/user';

import Modal from 'ui/components/Modal';

import AddressForm from '../AddressForm';
import CardAddress from '../CardAddress';
import { PageHeader, Title } from '../Page';
import {
  Button,
  AddressList,
  ModalContent,
  NewAddress,
  SearchAddress,
} from './ModalAddress.styled';

export default function ModalAddress(): React.ReactElement {
  const { closeModal } = useContext(SettingsContext);
  const dispatch = useDispatch();

  const close = (): void => {
    // dispatch(getAddressByCepSuccess());
    closeModal && closeModal();
  };

  const [newAddress, setNewAddress] = useState(false);
  const authenticated = useSelector(isLogged);
  const addresses = useSelector(getAddresses);
  const [selected, setSelected] = useState(addresses?.find((address) => address.default));

  return (
    <Modal close={close} modifiers={['ShowModal', 'ModalSmall']}>
      <ModalContent>
        {
          (!authenticated || !addresses || !addresses.length || newAddress) ? (
            <AddressForm />
          ) : (
            <SearchAddress>
              <PageHeader>
                <Title>Meus endereços</Title>
              </PageHeader>
              <AddressList>
                {
                  addresses?.map(
                    (address) => (
                      <CardAddress
                        key={address.id}
                        address={address}
                        selectAddress={(): void => setSelected(address)}
                        modifiers={selected?.id === address.id ? 'Active' : ''}
                      />
                    )
                  )
                }
              </AddressList>
              <NewAddress
                onClick={(): void => setNewAddress(true)}
              >
                Adicionar novo endereço
              </NewAddress>
              <Button onClick={
                (): void => {
                  dispatch(updateAddressRequest({ ...selected, default: true }));
                  closeModal && closeModal();
                }
              }
              >
                Salvar
              </Button>
            </SearchAddress>
          )
        }
      </ModalContent>
    </Modal>
  );
}
