import { useHistory } from 'react-router-dom';

type UseSearchParamsProps = string[];

export default function useSearchParams([...paramsNames]: UseSearchParamsProps): any {
  const history = useHistory();

  const urlParams = new URLSearchParams(history.location.search);

  return paramsNames.map((paramName) => urlParams.get(paramName));
}
