import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { SettingsProvider } from 'context/SettingsContext';

import {
  getCart, hasError, isLoading, isLogged, isSuccess,
} from 'store/ducks';
import { authenticationRequired } from 'store/ducks/base';

import AppNavHeader from 'ui/components/AppNavHeader';
import AppNavTabs from 'ui/components/AppNavTabs';
import FilterProducts from 'ui/components/FilterProducts';
import Footer from 'ui/components/Footer';
import Loading from 'ui/components/Loading';
import ModalCategories from 'ui/components/ModalCategories';
import ShoppingCart from 'ui/components/ShoppingCart';
import Warning from 'ui/components/Warning';

import {
  LOAD_AUTH_DATA, REFRESH_TOKEN, REGISTER, VERIFY_CART,
} from 'utils/fetchs';

import ModalInvalidCart from '../components/ModalInvalidCart';
import ModalWelcome from '../components/ModalWelcome';
import HomeRouter from './HomeRouter';
import OrdersRouter from './OrdersRouter';
import ProfileRouter from './ProfileRouter';
import ShoppingCartRouter from './ShoppingCartRouter';

export default function MainRouter(): React.ReactElement {
  const loadAuthDataLoading = useSelector(isLoading(LOAD_AUTH_DATA));
  const verifyCartLoading = useSelector(isLoading(VERIFY_CART));
  const refreshTokenError = useSelector(hasError(REFRESH_TOKEN));
  const registerSuccess = useSelector(isSuccess(REGISTER));
  const cart = useSelector(getCart);
  const forceLogin = useSelector(authenticationRequired);
  const authenticated = useSelector(isLogged);

  const [invalidCart, setInvalidCart] = useState(cart.state === 'completed');

  useEffect(() => {
    if (!loadAuthDataLoading && !verifyCartLoading) {
      window.scrollTo(0, 0);
    }
  }, [loadAuthDataLoading, verifyCartLoading]);

  return (
    <SettingsProvider>
      <Router>
        <LastLocationProvider>
          { (!refreshTokenError && (loadAuthDataLoading || verifyCartLoading)) && <Loading /> }
          { registerSuccess && <ModalWelcome /> }
          {

            invalidCart && (
              <ModalInvalidCart onClose={(): void => setInvalidCart(false)} />
            )
          }
          <AppNavHeader />
          <ShoppingCart />
          <FilterProducts />
          <ModalCategories />
          <Warning />
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/profile">
                <ProfileRouter />
              </Route>
              {
                (forceLogin === 'no' || authenticated) ? (
                  <Switch>
                    <Route path="/checkout">
                      <ShoppingCartRouter />
                    </Route>
                    <Route path="/orders">
                      <OrdersRouter />
                    </Route>
                    <Route path="/">
                      <HomeRouter />
                    </Route>
                  </Switch>
                ) : (
                  <Redirect to={`/profile/login?next=${window.location.pathname}`} />
                )
              }
            </Switch>
          </Suspense>
          <AppNavTabs />
          <Footer />
        </LastLocationProvider>
      </Router>
    </SettingsProvider>
  );
}
