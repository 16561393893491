import React from 'react';

import { Container } from 'ui/components/Page';

import { FooterContainer } from './Footer.styled';

export default function BannersHome(): React.ReactElement {
  return (
    <FooterContainer>
      <Container>
        <p>Preços e condições de pagamento exclusivos para compras online, com possibilidade de variações nas lojas físicas. Caso haja divergências de valores nos produtos no site, o preço válido é o do carrinho de compras. Vendas sujeitas a confirmação de estoque e análise e validação dos dados.</p>
      </Container>
    </FooterContainer>
  );
}
