import { useContext, useEffect } from 'react';

import SettingsContext from 'context/SettingsContext';

import { NavType } from 'typing/context';

export default function useNavControl(kind: NavType = 'REGULAR'): void {
  const { setNavType } = useContext(SettingsContext);

  useEffect(() => {
    setNavType && setNavType(kind);
  }, [kind]);
}
