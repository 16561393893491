import React, { useEffect, useRef, useState } from 'react';

import Search from 'ui/components/Search';
import SidebarCategories from 'ui/components/SidebarCategories';

import {
  SearchModalContainer,
  SearchModalInner,
} from './SearchModal.styled';

export default function SearchModal({ modifiers }: any): React.ReactElement {
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (modifiers?.length && searchRef?.current) {
      searchRef.current.focus();
    }
  }, []);

  const [txtSearch, setTxtSearch] = useState<string>('');

  return (
    <SearchModalContainer modifiers={modifiers}>
      <SearchModalInner>
        <Search ref={searchRef} setTxtSearch={setTxtSearch} modifiers="ShowCancel" />
        <SidebarCategories categoryTextSearch={txtSearch} />
      </SearchModalInner>
    </SearchModalContainer>
  );
}
