import firebase from 'firebase/app';
import flatten from 'lodash/flatten';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getSelectedStore, isLoading, listCategoriesByIds, hasError,
  logout,
} from 'store/ducks';
import { listLinkedProductsRequest, getLinkedProducts, getFieldForExtraData } from 'store/ducks/products';

import { LIST_LINKED_PRODUCTS } from 'utils/fetchs';

import NavCategories from '../NavCategories';
import ProductsFull from '../ProductsFull';
import SidebarCategories from '../SidebarCategories';
import {
  Col, Container, Row, Sidebar, NoResult, ColProducts, ShareMessage,
} from './LinkResult.styled';

export default function LinkResult(): React.ReactElement {
  const dispatch = useDispatch();
  const { linkId } = useParams();

  const linkedTitle = useSelector(getFieldForExtraData('link'));
  const store = useSelector(getSelectedStore);

  const products = useSelector(getLinkedProducts(store?.slug || 'undefined'));

  const categories = useSelector(listCategoriesByIds(store?.slug || 'undefined', flatten(
    (products || []).map((p) => (p.category_ids || []).map((c) => c.id.toString())),
  )));

  const loading = useSelector(isLoading(LIST_LINKED_PRODUCTS));

  const errors = useSelector(hasError(LIST_LINKED_PRODUCTS));
  const firstName = useSelector(getFieldForExtraData('link', 'store_operator.user.first_name'));
  const lastName = useSelector(getFieldForExtraData('link', 'store_operator.user.last_name'));

  useEffect(() => {
    dispatch(listLinkedProductsRequest([linkId, 1]));
  }, [linkId]);

  const handleLogout = async () => {
    dispatch(logout({
      error: 'unapproved_client',
    }));
    try {
      if (localStorage.getItem('@bodega-mix/fcmToken')) {
        const messaging = firebase.messaging();
        const token = localStorage.getItem('@bodega-mix/fcmToken') as string;
        messaging.deleteToken(token);
        localStorage.removeItem('@bodega-mix/fcmToken');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (errors) {
      handleLogout();
    }
  }, [errors]);

  const message = useMemo(() => {
    const messagesArr = [];

    if (firstName && lastName) {
      // messagesArr.push(firstName);
      // messagesArr.push(lastName);
      // messagesArr.push('da');
    }

    if (store?.name) {
      // messagesArr.push(store?.name);
    }

    // messagesArr.push('selecionou esses produtos para você.');

    return messagesArr.join(' ');
  }, [firstName, lastName, store]);

  return (
    <Container>
      {
        loading ? (
          <p>Carregando...</p>
        ) : (
          <Row className="row">
            <NavCategories fullCategories={false} />
            <Container>
              <ShareMessage>{message}</ShareMessage>
              <ProductsFull
                key={1}
                categoryId={0}
                categoryName={linkedTitle!}
                showFilter={false}
              />
            </Container>
            <Col
              className="col-lg-3 order-lg-first mt-lg-0 pt-lg-0 order-first"
              style={{
                position: 'sticky', top: '100px', alignSelf: 'flex-start', zIndex: 10,
              }}
            >
              <Sidebar>
                <SidebarCategories fullCategories={false} />
              </Sidebar>
            </Col>
          </Row>
        )
      }
    </Container>
  );
}
